import Toast from "../../Components/Toast";
import React, {useState, useEffect} from "react";
import {connect, useSelector, useDispatch} from "react-redux";
import {Modal} from "react-bootstrap";
import "./reply-message-popup.css";
import "../../Pages/AdminDashboard/forms.css";
import validator from "validator";
import {getAdminUserData, getMessage, getUserforms, sendIntakeForm} from "../../Actions/authActions";
import {CrossIcon} from "../Icons";
import {withButtonLoader} from "../../helpers";
import classNames from "classnames";
import {executePost} from "../../Api";
import {SEND_INTAKE_ERROR, SEND_INTAKE_SUCCESS} from "../../constants";
import {truncateString} from "../../utils";
import {AppropriateFormTypeIcon, formInstructionsPerType} from "../AppropriateFormType";


const SendIntakeForm = (props) => {
        const {
            openModal,
            onCloseModal,
            subdomain,
            setIntakeFormToSend,
            intakeFormToSend,
            adminUser,
            selectedPayer,
        } = props;
        const {intakeforms} = adminUser;

        const [payerCell, setPayerCell] = useState("");
        const [sendBy, setSendBy] = useState("email");
        const [errorMsg, setErrorMsg] = useState("");
        const [sendIntakeEmail, setSendIntakeEmail] = useState(false);
        const [sendIntakeError, setSendIntakeError] = useState(false);
        const [whichFormsToSend, setWhichFormsToSend] = useState({});

        const sendIntakeLoading = useSelector(
            (state) => state.auth.isSendIntakeLoading
        );

        useEffect(() => {
            if (intakeforms.length > 0) {
                let update = {};
                intakeforms.forEach(intakeform => {
                    update[intakeform._id] = false;
                });
                setWhichFormsToSend(update);
            }
        }, [intakeforms]);

        const updateWhichFormsToSend = (e, _id) => {
            setWhichFormsToSend({
                ...whichFormsToSend,
                [_id]: true,
            });
        };

        const onClose = () => {
            setErrorMsg("");
            onCloseModal(false);
            setSendIntakeError(false);
            setSendIntakeEmail(false);
            setIntakeFormToSend({});
        };
        const handleOnChangeNumber = (e) => {
            const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
            if (rx_live.test(e.target.value)) {
                const data = (e.target && e.target.value) || "";
                if (data) {
                    if (!data.match("^\\w{10}$")) {
                        setErrorMsg("Phone must be of 10 digit");
                    } else {
                        setPayerCell(e.target.value);
                        setSendBy("phone");
                        setErrorMsg("");
                    }
                } else {
                    setErrorMsg("Enter valid email or phone");
                }
            } else {
                if (validator.isEmail(e.target.value)) {
                    setErrorMsg("");
                    setSendBy("email");
                } else {
                    setErrorMsg("Enter valid email or phone ");
                }
                setPayerEmail(e.target.value.toLowerCase());
            }
        };
        const handleKeyPress = (e) => {
            if (e.key === "Enter") {
                const test = (e.target && e.target.value) || "";
                if (test) {
                    onClickSend();
                }
            }
        };

        const onClickSend = ({sendBy}) => {
            setSendIntakeError(false);
            setSendIntakeEmail(false);
            if (!Object.values(whichFormsToSend).some((b) => b === true)) {
                return setErrorMsg("No forms were selected");
            }
            if (["phone", "both"].includes("sendBy") && (selectedPayer.payerCell === "" || selectedPayer.payerCell.startsWith("*fake*"))) {
                return setErrorMsg("Payer does not have a cell phone in the portal");
            } else if (["email", "both"].includes("sendBy") && (selectedPayer.payerEmail === "" || selectedPayer.payerEmail.startsWith("*fake*"))) {
                return setErrorMsg("Payer does not have an email address in the portal");
            } else {
                if (!errorMsg.length > 0) {
                    const data = {
                        payerId: selectedPayer._id,
                        sendBy,
                        whichFormsToSend: Object.entries(whichFormsToSend)
                            .filter(([key, value]) => value === true)
                            .map(([k, v]) => k),
                        subdomain,
                    };

                    executePost(`SosCustomers/intakeSend`, data)
                        .then((res) => {
                            setSendIntakeEmail(true);
                        })
                        .catch((err) => {
                            console.log("err qqpwojro", err);
                            setSendIntakeError(true);
                        });
                } else {
                    setErrorMsg("Error");
                }
            }
        };



        return (
            <Modal
                onHide={() => onClose()}
                show={openModal}
                size="md"
                centered
                className="reply-msg-content"
            >
                <div onClick={() => onClose()} className="close-modal-btn">
                    <CrossIcon/>
                </div>

                <Modal.Body>
                    <div className="">
                        <div className="">
                            <div className="card-head-large-text card-hoiz-text ">
                                <div className="col-sm-12 reply-header">
                                    Send document / form request
                                </div>
                            </div>
                            <div className="row">
                                <p className="send-intake-msg mb-0">
                                    Please select the documents you would
                                    like <b>{`${selectedPayer.payerFirst} ${selectedPayer.payerLast}`}</b> to read or
                                    fill
                                    out.
                                </p>
                                <div className="send-intake-msg">

                                    {intakeforms && intakeforms.length && intakeforms
                                        .sort((a, b) => {
                                            if (a.formType > b.formType) return 1;
                                            if (a.formType < b.formType) return -1;
                                            return 0;
                                        })
                                        .map((intakeform, index, intakeforms) => {
                                            console.dir("intakeform.formType #qwer3234")
                                            console.dir(intakeform.formType)
                                            const isFormTypeTransition = index === 0 || (index < intakeforms.length && intakeform?.formType !== intakeforms[index - 1]?.formType);

                                            const formDisplayName = truncateString(intakeform?.originalname || "", 36);

                                            return (
                                                <div className="form-group px-4" key={intakeform?._id}>
                                                    {isFormTypeTransition && <div
                                                        className='mt-4 mb-2'
                                                    >{index !== 0 && <hr/>}
                                                        <div
                                                        ><AppropriateFormTypeIcon
                                                            formType={intakeform.formType}/></div>
                                                        <div
                                                        >{formInstructionsPerType.find(o => o.formType === intakeform.formType).patientInstructions}</div>
                                                    </div>
                                                    }
                                                    <input
                                                        type="checkbox"
                                                        id={intakeform?._id}
                                                        value={whichFormsToSend[intakeform?._id]}
                                                        onChange={(e) => updateWhichFormsToSend(e, intakeform?._id)}
                                                    ></input>
                                                    <label
                                                        className="px-2"
                                                        htmlFor={intakeform?._id}>{`${formDisplayName || ""}`}</label>
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                            {/*<div className="row px-3 mt-2">*/}
                            {/*    <div className="col-md-5">*/}
                            {/*        <label className="intake_label_m ">Email or Phone </label>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-md-7">*/}
                            {/*        <input*/}
                            {/*            type="text"*/}
                            {/*            placeholder="Email or phone"*/}
                            {/*            name="payerEmail"*/}
                            {/*            className="office-label custom-input-border-css msg-input"*/}
                            {/*            onChange={(e) => handleOnChangeNumber(e)}*/}
                            {/*            onKeyPress={(e) => handleKeyPress(e)}*/}
                            {/*            autoComplete="off"*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}


                            {/*<div className="row px-2">*/}
                            {/*    <div className="col-md-5"></div>*/}
                            {/*    <div className="col-md-7 error-intake-email">{errorMsg}</div>*/}
                            {/*</div>*/}

                            {/*<div className="document-img">*/}
                            {/*    <img src="../images/email-icon.png" alt="documentIcon"/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    {(sendIntakeEmail || sendIntakeError || errorMsg) ? (
                        // david
                        <div className="toast-intake mt-4">
                            <Toast
                                closeCallbackFn={() => {
                                    setErrorMsg("");
                                    setSendIntakeError(false);
                                    setSendIntakeEmail(false);
                                }}
                                isOpen={sendIntakeEmail || sendIntakeError || errorMsg}
                                showClose={false}
                                msg={
                                    sendIntakeEmail ? "Document/Form request sent successfully" : (errorMsg || "User not found")
                                }
                                type={sendIntakeEmail ? "success" : "danger"}
                                dur={3000}
                            />
                        </div>
                    ) : (
                        ""
                    )}"
                    <div className="text-center mt-4">
                        <div className='flex space-around'>
                            {["Email", "Text", "Both"].map((sendBy, i) => {
                                return (
                                    <button
                                        key={i}
                                        type="submit"
                                        name="send"
                                        className={classNames("sos-custom-btn ml-4 sos-custom-btn-css")}
                                        onClick={() => onClickSend({sendBy: sendBy.toLowerCase()})}
                                    >
                                        {withButtonLoader(`Send ${sendBy}`, sendIntakeLoading, "#6e86c4")}
                                    </button>
                                )
                            })}

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
;

const mapDispatchToProps = {
    sendIntakeForm,
};
export default connect(null, mapDispatchToProps)(SendIntakeForm);
