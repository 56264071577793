import React, {useEffect, useRef, useState} from "react";
import moment from "moment";
import {useSelector, useDispatch} from "react-redux";
import {
    deleteUploadedDoc,
    getAdminUserData,
    getHelloSignTemplate, getMessage, getPayerUserForAdminLookup, getTransaction,
    getTransactionData, getUserforms,
    saveAfterCreateTemplate, sendMessageSuccessNotify,
    setTempIntakeformId
} from "../../Actions/authActions";
import {amountFormatter, amountFormatterWithZero, subDomain} from "../../utils";
import {useParams} from 'react-router-dom';
import map from "lodash/map";
import CustomToolTip from "../../Components/CustomToolTip";
import {AppropriateFormTypeIcon, formTypeIconMessages} from "../../Components/AppropriateFormType";
import {
    CrossIcon,
    DownArrow,
    EditIcon,
    ShuffleEditIcon,
    PrinterIcon,
    ReadOnlyIcon,
    SendIcon,
    DigitalPen,
    DownArrowIcon, TrashCanIcon
} from "../../Components/Icons";
import CustomePopUp from "../../Components/customePopUp";
import {withButtonLoader} from "../../helpers";
import {Modal} from "react-bootstrap";
import get from "lodash/get";
import HelloSignAdmin from "../../Components/HelloSignAdmin";
import EditIntakeFormPopup from "../../Components/EditIntakeFormPopup";
import SendIntakeMultipleForm from "../../Components/SendIntakeMultipleFormModal";
import CustomPopoverDelete from "../../Components/CustomPopoverDelete";
import Table from "./ReactTable/ReactTableMain";
import SendIntakeForm from "../../Components/SendIntakeFormModal";
import "./forms.css"
import CustomPopover from "../../Components/CustomPopover";
import store from "../../Redux/store";
import {CreateSignDocument} from "../../Refactor/SignDocument/Create/create-sign-document";
import useForceUpdate from "use-force-update";

const Forms = ({subdomain, themeColor1, userAdmin, uploadMultipleFiles, homePage}) => {


    const dispatch = useDispatch();
    const storeInstance = store.getInstance();
    const documentLoading = useSelector((state) => state.auth.documentLoading);
    const intakeLoading = useSelector((state) => state.auth.intakeLoading);
    const inputFileIntake = useRef(null);
    const [dragFile, setDragFile] = useState(false);
    const [editHelloSignUrls, setEditHelloSignUrls] = useState("");
    const [showHelloSign, setShowHelloSign] = useState(false);
    const [isOpenInTakeSuccessful, setIsOpenInTakeSuccessful] = useState(false);
    // const [isOpenSendIntake, setIsOpenSendIntake] = useState(false);
    // const [intakeFormToSend, setIntakeFormToSend] = useState({});
    const [fileToEdit, setFileToEdit] = useState(null);
    const [fileUploaded, setFileUploaded] = useState(null);
    const [whatToEdit, setWhatToEdit] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [intakePopUp, setIntakePopUp] = useState(false);
    const [helloSignLoader, setHelloSignLoader] = useState(false);
    const [getStartedDoc, setGetStartedDoc] = useState(false);
    const [getStartedIntake, setGetStartedIntake] = useState(false);
    const [isOpenEditIcon, setIsOpenEditIcon] = useState(false);
    const [dragDoc, setDragDoc] = useState(false);
    const [popUpMessage, setPopUpMessage] = useState("");
    const [target, setTarget] = useState(null);
    const [docId, setDocId] = useState("");
    const [showPopUp, setShowPopUp] = useState(false);
    const [popoverTitle, setPopoverTitle] = useState("Are you sure you want to remove this form?");
    const [formIndex, setFormIndex] = useState("");
    const forceUpdate = useForceUpdate();

    const updateIntakeFormTypeSuccess = useSelector(state => state.auth.updateIntakeFormTypeSuccess);

    useEffect(() => {
        if (updateIntakeFormTypeSuccess) {
            dispatch(getAdminUserData(subdomain));
        }
    }, [updateIntakeFormTypeSuccess]);

    useEffect(() => {
        if (!Object.keys(userAdmin).length && homePage === "forms") {
            dispatch(getAdminUserData(subdomain));
            dispatch(getUserforms(subdomain));
        }
    }, [!userAdmin]);


    const onCloseModal = () => {
        setIsLoad(false);
    };

    const handleGetStarted = (i) => {
        if (i === "getStartedDoc") {
            setGetStartedDoc(!getStartedDoc);
        } else {
            setGetStartedIntake(!getStartedIntake);
        }
    };

    const validateFile = (file) => {
        const fileName = get(file, "name");
        const re = /(\.pdf|\.doc|\.docx)$/i;
        if (re.exec(fileName)) {
            return true;
        }
        return false;
    };

    const initEdit = (event, fileToEdit, whatToEdit) => {
        setWhatToEdit(whatToEdit);
        if (isOpenInTakeSuccessful) {
            setIsOpenInTakeSuccessful(false);
        }
        event.preventDefault();
        // setFormIndex(willUpdateFormIndex);
        setFileToEdit(fileToEdit);
        setIsOpenEditIcon(true);
    };

    const onshowHelloSign = async () => {
        // const intakeformId = fileToEdit._id.toString();
        // dispatch(setTempIntakeformId(intakeformId));
        setIsLoad(true);

        // const url = await storeInstance.dispatch(getHelloSignTemplate);
        const newFileToEdit = userAdmin.intakeforms.find((f) => f._id.toString() === fileToEdit._id.toString());
        setFileToEdit(newFileToEdit);
        // const edit_url = newFileToEdit.template && newFileToEdit.template.edit_url;
        // dispatch(setTempIntakeformId(null));
        onCloseModal();
        // setEditHelloSignUrls(edit_url);
        setIsOpenEditIcon(false);
        setShowHelloSign(true);
        // setHelloSignLoader(true);
    };

    const intakePopUpClose = () => {
        onCloseModalUpdateSuccess();
        setIntakePopUp(false);
    };

    const onCloseModalUpdateSuccess = () => {
        setIntakePopUp(false);
        setIsOpenEditIcon(false);
        forceUpdate();
        setTimeout(() => {
            dispatch(getAdminUserData(subdomain));
        }, 300);
        setWhatToEdit("");
    };

    const onCreateHelloSignTemplate = (subdomain, sosCustomerId, intakeformId, template_id) => {
        dispatch(saveAfterCreateTemplate(subdomain, sosCustomerId, intakeformId, template_id));
        setEditHelloSignUrls("");
    };

    const onUploadFile = (event, isDocument) => {
        const file = event.target.files[0];
        uploadMultipleFiles(file, userAdmin && userAdmin._id, isDocument, subdomain)
            .then((result) => {
                setDragDoc(false);
                setDragFile(false);
                if (isDocument === "intake") {
                    setFileUploaded(result);
                    setIsOpenInTakeSuccessful(true);
                }
            });
    };

    const onButtonClickIntake = (e) => {
        e.preventDefault();
        // `current` points to the mounted file input element
        inputFileIntake.current.click();
    };

    const handleDeleteForm = (event, i) => {
        event.preventDefault();
        setShowPopUp(!showPopUp);
        setTarget(event.target);
        setDocId(i._id);
        setPopoverTitle("Are you sure you want to remove this form?");
    };

    const updateAdminUser = () => {
        dispatch(getAdminUserData(subdomain));
    };

    const onDeleteForm = () => {
        setShowPopUp(!showPopUp);
        const data = {
            subdomain, _id: docId, type: "intakeform",
        };
        dispatch(deleteUploadedDoc(data));
    };

    const handleClosePopUp = () => {
        setShowPopUp(false);
        setDocId("");
        setTarget(null);
    };

    return (
        <>
            <div className="container">
                <div className="full-width justify-content-between row" data-cy="doc-upload">

                    <div className="bg-white position-relative home-card m-b-20 center-content account-card-shadow"
                         data-cy="intake-forms">
                        <div className="card-head-large-text card-hoiz-text d-flex justify-content-between">
                            <div className="card-head-font mb-1">Documents and Forms</div>
                            <div className="card-head-right-font"></div>
                        </div>
                        {userAdmin.intakeforms ? (<>
                            <div className="inner-card-form h-600 upload-doc-scroll m-l-10">


                                <div>
                                    <div>
                                        <div className="flex space-between">

                                            <div
                                                className="no-grow-no-shrink basis-max-35"
                                            >
                                                <div
                                                    className="card-inner-text-font align-icons font-size-18">
                                                    Form Name
                                                </div>
                                            </div>
                                            <div
                                                className="no-grow-no-shrink basis-max-25"
                                            >
                                                <div
                                                    className="card-inner-text-font align-icons flex-center font-size-18">
                                                    <div

                                                    >
                                                        Form Type/Patient Instructions
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className='flex no-grow-no-shrink basis-max-40 flex-center font-size-18'>
                                                <div>

                                                </div>

                                            </div>
                                        </div>
                                        <div className="divider"/>
                                    </div>
                                </div>


                                {map(userAdmin && userAdmin.intakeforms, (i, index) => {

                                    return (


                                        <div key={index}>
                                            <div>
                                                <div className="flex space-between">
                                                    {/*<div className="d-flex justify-content-between">*/}
                                                    <div
                                                        className="no-grow-no-shrink basis-max-35"
                                                    >
                                                        <h5
                                                            className="card-inner-text-font align-icons">
                                                            {i && i.originalname}
                                                        </h5>
                                                    </div>
                                                    <div
                                                        className="no-grow-no-shrink basis-max-20"
                                                    >
                                                        <div className="card-inner-text-font align-icons">
                                                            <CustomToolTip
                                                                message={formTypeIconMessages[i.formType]}
                                                                placement={"top"}
                                                                children={<div><AppropriateFormTypeIcon
                                                                    formType={i.formType}/>
                                                                </div>}
                                                                flag={true}
                                                            />

                                                        </div>
                                                    </div>

                                                    <div
                                                        className='flex flex-gap-1em no-grow-no-shrink basis-max-45 flex-end'>
                                                        {i.formType === 'eSign' &&
                                                            <div className=''>
                                                                {(validateFile({name: i?.originalname})) && (<div
                                                                    className="align-content-start">
                                                                    <CustomToolTip
                                                                        message={"Add/edit e-signature form fields"}
                                                                        flag={true}
                                                                        placement={"top"}
                                                                        children={<div
                                                                            className={"text-center cursor-pointer"}
                                                                            style={{
                                                                                backgroundColor: "transparent",
                                                                                backgroundRepeat: "no-repeat",
                                                                                border: "none",
                                                                                cursor: "pointer",
                                                                                overflow: "hidden",
                                                                                outline: "none",
                                                                            }}
                                                                            onClick={(event) => initEdit(event, i, "editFormFields")}
                                                                        >
                                                                            <EditIcon
                                                                                width={'1.4em'}
                                                                                height={'1.4em'}
                                                                            />
                                                                            <div className={'link'}>Edit digital form
                                                                                fields
                                                                            </div>
                                                                            {/*<CustomToolTip*/}
                                                                            {/*    message={"Add/edit e-signature form fields"}*/}
                                                                            {/*    flag={true}*/}
                                                                            {/*    placement={"top"}*/}
                                                                            {/*    children={<div className={'link'}>Edit digital*/}
                                                                            {/*        form fields</div>}*/}
                                                                            {/*/>*/}
                                                                        </div>}/>
                                                                </div>)}
                                                            </div>}
                                                        <div
                                                            className=''
                                                        >
                                                            {/*{(validateFile({name: i?.originalname})) && (*/}
                                                            {(
                                                                <div
                                                                    className={"text-center cursor-pointer"}
                                                                    onClick={(event) => initEdit(event, i, "editFormType")}>
                                                                    <ShuffleEditIcon
                                                                        height={'1.4em'}
                                                                        width={'1.4em'}

                                                                    />
                                                                    <div
                                                                        style={{
                                                                            backgroundColor: "transparent",
                                                                            backgroundRepeat: "no-repeat",
                                                                            border: "none",
                                                                            cursor: "pointer",
                                                                            overflow: "hidden",
                                                                            outline: "none",
                                                                        }}

                                                                    >
                                                                        <div className={'link'}>Change form type</div>
                                                                    </div>
                                                                </div>)}
                                                        </div>
                                                        {/*<div className="">*/}
                                                        {/*    <a onClick={() => {*/}
                                                        {/*        setIntakeFormToSend(i);*/}
                                                        {/*        return setIsOpenSendIntake(true);*/}
                                                        {/*    }}>*/}
                                                        {/*        <CustomToolTip*/}
                                                        {/*            flag={true}*/}
                                                        {/*            message={"Invite a specific person to fill out this form"}*/}
                                                        {/*            placement={"top"}*/}
                                                        {/*            children={<div className={'link'}>Send form to payer</div>}*/}
                                                        {/*        />*/}
                                                        {/*    </a>*/}
                                                        {/*</div>*/}
                                                        <div className=''>
                                                            <a href={i && i.url} download>
                                                                <CustomToolTip
                                                                    message={"Download blank form"}
                                                                    placement={"top"}
                                                                    children={<div
                                                                        className="text-center cursor-pointer"

                                                                    >
                                                                        <DownArrow color={themeColor1} height="1.4vw"/>
                                                                        <div className={'link'}>Download</div>
                                                                    </div>}
                                                                />
                                                            </a>
                                                        </div>

                                                        <div className=''>
                                                            <div
                                                                className="text-center cursor-pointer"
                                                                onClick={(event) => handleDeleteForm(event, i)}
                                                            >
                                                                <TrashCanIcon color={themeColor1} height="1.4vw"/>
                                                                <div className={'link'}>Delete</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="divider"/>
                                            </div>
                                        </div>


                                    )
                                })}

                                <CustomePopUp
                                    setShowHelloSign={setShowHelloSign}
                                    openModal={intakePopUp}
                                    onCloseModal={intakePopUpClose}
                                    message={popUpMessage}
                                />
                            </div>
                        </>) : (<>
                            {!getStartedIntake && (<div className="get-started">
                                <div>
                                    <div className="get-started-btn">
                          <span className="text-center if-you-would-like-y">
                            You can upload intake forms into
                            this area, for patients to have access to.
                          </span>
                                    </div>
                                    <div className="get-started-btn">
                                        <button

                                            className="sos-browse-btn sos-custom-btn-css p-2"
                                            onClick={() => handleGetStarted("getStartedIntake")}
                                        >
                                            Get Started
                                        </button>
                                    </div>
                                </div>
                            </div>)}
                        </>)}
                        {(userAdmin.intakeforms || getStartedIntake) && (<div
                            className={`${getStartedIntake ? "documents-drag-when-no-data" : "documents-drag-when-data"} drag-drop-intake-position`}
                        >
                            <div className="card-head-font">Add a Document or Form</div>
                            <div
                                className="card-inner-drag-drop mb-5"
                                onDragOver={(e) => dragOver(e)}
                                onDragLeave={(e) => dragLeave(e)}
                                onDrop={(a) => onFileDrop(a, true, "intake")}
                            >
                                <div className="dropzone dropzone-loader">
                                    <div>
                                        {dragFile || intakeLoading ? (withButtonLoader("", dragFile || intakeLoading, "#6e86c4")) : (
                                            <div>
                                                <p className="card-drag-drop-p mb-0"
                                                   data-cy="card-drag-drop-p-intake">
                                                    Upload files here
                                                </p>
                                                <div className=" justify-content-center">
                                                    <input
                                                        type="file"
                                                        id="fileintake"
                                                        onChange={(e) => onUploadFile(e, "intake")}
                                                        ref={inputFileIntake}
                                                        style={{display: "none"}}
                                                    />
                                                    <button
                                                        type="button"
                                                        data-cy="browse-files-intake"
                                                        className="sos-browse-btn p-2 sos-custom-btn-css"
                                                        onClick={(e) => onButtonClickIntake(e, false, "intake")}
                                                    >
                                                        Click to upload your file
                                                    </button>
                                                </div>
                                            </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>)}

                        {/*<div className="document-img">*/}
                        {/*    <IntakeFormIcon color={themeColor3}/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <Modal show={isOpenInTakeSuccessful} size="md" centered className="update-successfully">
                <div data-cy="modal-pop-up" onClick={() => setIsOpenInTakeSuccessful(false)}
                     className="close-modal-btn">
                    <CrossIcon/>
                </div>
                <Modal.Body>
                    <div className="modal-headr-text">
                        <p>Form uploaded successfully.</p>
                        <p>It is now available for patients to download,
                            print, {userAdmin.intakeforms && userAdmin.intakeforms.length > 0 && userAdmin.intakeforms[userAdmin.intakeforms.length - 1] && validateFile({name: userAdmin.intakeforms[userAdmin.intakeforms.length - 1].originalname}) && (<>sign, </>)}and
                            bring to the clinic or upload
                            into the patient portal. </p>
                        <p>Digital signatures are only supported for PDF and Word Documents.</p>
                        {userAdmin.intakeforms && userAdmin.intakeforms.length > 0 && userAdmin.intakeforms[userAdmin.intakeforms.length - 1] && validateFile({name: userAdmin.intakeforms[userAdmin.intakeforms.length - 1].originalname}) && (
                            <p>The form is not yet enabled for electronic signatures.
                                If you want patients to be able to sign this form electronically, then now or later
                                please select the
                                edit icon <button
                                    style={{
                                        backgroundColor: "transparent",
                                        backgroundRepeat: "no-repeat",
                                        border: "none",
                                        cursor: "pointer",
                                        overflow: "hidden",
                                        outline: "none",
                                        display: 'inline'
                                    }}
                                    onClick={(event) => initEdit(event, fileUploaded)}
                                >
                                    <CustomToolTip
                                        message={"Add/edit e-signature form fields"}
                                        flag={true}
                                        placement={"top"}
                                        children={<EditIcon color={themeColor1}/>}
                                    />
                                </button> and follow the prompts if you wish to
                                enable this form for electronic signatures.
                            </p>)}

                        <div>
                            {userAdmin.intakeforms && userAdmin.intakeforms.length > 0 && userAdmin.intakeforms[userAdmin.intakeforms.length - 1] && validateFile({name: userAdmin.intakeforms[userAdmin.intakeforms.length - 1].originalname}) && (
                                <button
                                    style={{
                                        cursor: "pointer", overflow: "hidden", outline: "none"
                                    }}
                                    className="sos-mack-btn sos-custom-btn-css"
                                    onClick={(event) => initEdit(event, fileUploaded)}
                                >
                                    Set up e-Signatures
                                </button>)}
                            <button
                                style={{
                                    cursor: "pointer", overflow: "hidden", outline: "none"
                                }}
                                className="sos-mack-btn sos-custom-btn-css m-l-10"
                                onClick={() => setIsOpenInTakeSuccessful(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {helloSignLoader ? (<div className="loader-helloSign">
                {withButtonLoader("", true, "#6e86c4")}
            </div>) : (<>
                {showHelloSign && fileToEdit && (<>
                    {/*<HelloSignAdmin*/}
                    {/*    setIntakePopUp={setIntakePopUp}*/}
                    {/*    editHelloSignUrl={editHelloSignUrls}*/}
                    {/*    onCreateHelloSignTemplate={onCreateHelloSignTemplate}*/}
                    {/*    setShowHelloSign={setShowHelloSign}*/}
                    {/*    showHelloSign={showHelloSign}*/}
                    {/*    updateAdminUser={updateAdminUser}*/}
                    {/*    userAdmin={userAdmin}*/}
                    {/*    // formIndex={formIndex}*/}
                    {/*    fileToEdit={fileToEdit}*/}
                    {/*    setIsOpenEditIcon={setIsOpenEditIcon}*/}
                    {/*    setPopUpMessage={setPopUpMessage}*/}
                    {/*    setHelloSignLoader={setHelloSignLoader}*/}
                    {/*/>*/}
                    <CreateSignDocument file={fileToEdit} close={() => {
                        onCloseModalUpdateSuccess();
                        setShowHelloSign(false);
                    }}/>
                </>)}
            </>)}
            <CustomPopover
                title={popoverTitle}
                show={showPopUp}
                target={target}
                onRemove={onDeleteForm}
                close={handleClosePopUp}
                setShowPopUp={setShowPopUp}
            />
            <EditIntakeFormPopup
                whatToEdit={whatToEdit}
                setWhatToEdit={setWhatToEdit}
                fileToEdit={fileToEdit}
                openModal={isOpenEditIcon}
                onCloseModal={setIsOpenEditIcon}
                onshowHelloSign={onshowHelloSign}
                userAdmin_id={userAdmin?._id?.toString()}
                setShowHelloSign={setShowHelloSign}
                isLoad={isLoad}
                setIsLoad={setIsLoad}
            />
            {/*<SendIntakeMultipleForm*/}
            {/*    intakeFormToSend={intakeFormToSend}*/}
            {/*    intakeforms={userAdmin?.intakeforms}*/}
            {/*    openModal={isOpenSendIntake}*/}
            {/*    onCloseModal={setIsOpenSendIntake}*/}
            {/*    setIntakeFormToSend={setIntakeFormToSend}*/}
            {/*    subdomain={subdomain}*/}
            {/*/>*/}
        </>
    );
};

export default Forms;
