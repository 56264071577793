import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {onLoginWithToken} from "../../Actions/authActions";
import {withButtonLoader} from "../../helpers";
import {subDomain} from "../../utils";

const LoginWithToken = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    // const loginPay = useSelector((state) => state.auth.loginPay);

    // const getParameterByName = (name, url) => {
    //   if (!url) url = window.location.href;
    //   name = name.replace(/[\[\]]/g, '\\$&');
    //   var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    //   results = regex.exec(url);
    //   if (!results) return null;
    //   if (!results[2]) return '';
    //   return decodeURIComponent(results[2].replace(/\+/g, ' '));
    // }

    useEffect(() => {

        const subdomain = params.subdomain || subDomain();
        if (params && params.userToken) {
            const {userToken} = params || "";
            dispatch(onLoginWithToken(userToken, subdomain, navigate));
        } else {
            window.location.href = `/login`;
        }
    }, []);

    return (
        <div className="pt-5 text-center">
            {withButtonLoader("Loading.....", true, "#000")}
        </div>
    );
};

export default LoginWithToken;
