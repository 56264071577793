import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch, connect } from "react-redux";
import {
  sentAccessCode,
  sendCodeByTextMsg,
  registerAccessCode,
  getLogo,
  getLoginAuthenticateCode,
} from "../../Actions/authActions";
import { withButtonLoader, getParameterByName } from "../../helpers";
import { subDomain, isNumberKey } from "../../utils";
import Toast from "../../Components/Toast";
import "./registration.css";
import { get, map } from "lodash";
import { Helmet } from "react-helmet";

const Registration = (props) => {
  const { match } = props || {};
  // const { params } = match || {};

  const params = useParams();
  const subdomain = params.subdomain || subDomain();
  const userToken = params.userToken || "";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const cname = getParameterByName("cname") || "";
  const cemail = getParameterByName("cemail");
  const sendCodeLoading = useSelector((state) => state.auth.isSendCodeLoading);
  const LoginLoad = useSelector((state) => state.auth.isLoginLoad);
  const registerEmailCheck = useSelector((state) => state.auth.isSendCodeError);
  const isSendCodeSuccess = useSelector(
    (state) => state.auth.isSendCodeSuccess
  );
  const isLoginCodeSuccess = useSelector(
    (state) => state.auth.isLoginCodeSuccess
  );
  const theme = useSelector((state) => state.auth.theme);
  const themeColor1 = get(theme, "themeColor1");
  const themeColor2 = get(theme, "themeColor2");
  let globalCustomCSSWithTheme;
  globalCustomCSSWithTheme = `.sos-custom-btn:hover .css-15llytj span {background: ${themeColor1}} .sos-custom-btn-css {background:${themeColor1};  border: 1px solid  ${themeColor1} } .sos-custom-btn-css:hover {color: ${themeColor1}; border: 1px solid ${themeColor1}}
  .text-cerulean {color:${themeColor1}} .text-cerulean:hover {color:${themeColor1}} .sos-content .form-control {border: 1px solid ${themeColor2}} .sos-content .form-control:focus {box-shadow: 0 0 0 0.15rem ${themeColor2}; border: ${themeColor2} }  `;
  const { willCreateUser, tokenHash, isAdmin } =
      localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')) : false;
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [isPhoneError, setIsPhoneError] = useState(false);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [emailAndPhoneMessage, setEmailAndPhoneMessage] = useState("phone");
  const [emailData, setEmailData] = useState("");
  const [step, setStep] = useState(0);
  const [isSmsSent, setIsSmsSent] = useState(false);
  const [error, setError] = useState({
    toastOpenClose: false,
    errorMsg: "",
  });
  const logoUrl = useSelector((state) => state.auth.logoUrl);

  const [editableData, setEditableData] = useState({
    first: cname.split(" ")[0],
    last: cname.split(" ")[1],
    payerEmail: cemail,
  });
  const agreedToS = {
    agreedToS: false,
    err: false,
    errText: "Please accept Terms of Service and Privacy Policy to continue.",
  };
  const [fieldsList, setFieldsList] = useState([
    {
      key: "payerEmail",
      type: "email",
      title: "Enter email (must be the same email that received the invitation to the portal)",
      placeholder: "Enter email",
      err: false,
      errText: "",
      className: "col-12",
      value: "",
    },
    {
      key: "password",
      type: "password",
      placeholder: "Password",
      title: `${"Create password"}`,
      err: false,
      errText: "",
      className: "col-md-6 col-12",
      value: "",
    },
    {
      key: "rePass",
      type: "password",
      placeholder: "Re-enter password",
      title: "",
      err: false,
      errText: "",
      className: "col-md-6 col-12",
      value: "",
    },
  ]);

  useEffect(() => {
    if (!logoUrl) {
      dispatch(getLogo(subdomain));
    }
  }, [!logoUrl]);

  useEffect(() => {
    if (isSendCodeSuccess) {
      setStep(2);
    }
  }, [isSendCodeSuccess]);

  const handleFields = (e, i, key) => {
    const obj = { ...editableData };
    const arr = [...fieldsList];
    const { value } = e.target;
    const len = value.length;
    if (len < 31) {
      if (key === "payerEmail") {
        obj[key] = value.toLowerCase();
      } else {
        obj[key] = value;
      }
    }
    if (arr[i].err) {
      arr[i].err = false;
      arr[i].errText = "";
    }
    if (key === "payerEmail") {
      arr[i].value = value.toLowerCase();
    } else {
      arr[i].value = value;
    }
    setEditableData(obj);
    setFieldsList(arr);
  };

  const isValid = () => {
    const arr = [...fieldsList];
    const { payerEmail, password, rePass } = editableData || {};

    const handleErr = (i, msg) => {
      arr[i].err = true;
      arr[i].errText = msg;
    };
    if (!willCreateUser) {
      if (!payerEmail) {
        handleErr(0, "Email is required.");
      }
    }
    if (!password) {
      handleErr(1, "Password is required.");
    } else if (password.length < 8) {
      handleErr(1, "Password must be at least 8 characters.");
    } else if (!rePass) {
      handleErr(2, "Re-enter password.");
    } else if (rePass !== password) {
      handleErr(2, "Passwords do not match.");
    }
    setFieldsList(arr);
    if (
      (!willCreateUser && !payerEmail) ||
      !password ||
      !rePass ||
      rePass !== password ||
      password.length < 8
    ) {
      return false;
    }
    return true;
  };

  const handleRegister = (e) => {
    e.preventDefault();
    if (isValid()) {
      const { first, last, payerEmail, password } = editableData || {};
      const { agreedToSValue } = agreedToS || false;
      const data = {
        payerFirst: first,
        payerLast: last || "",
        street: "",
        city: "",
        state: "",
        zip: "",
        payerCell: "",
        payerEmail: payerEmail,
        password: password,
        agreeToS: agreedToSValue || "unknown",
        tokenHash: tokenHash || "",
        createPass: true,
        isAdmin: isAdmin || false,
        subdomain,
        sendBy: "phone",
      };
      if (willCreateUser) {
        dispatch(sentAccessCode(data, navigate, isAdmin));
      } else {
        setStep(1);
      }
    }
  };

  const handleKeyPress = (e, key) => {
    if (e.key === "Enter") {
      if (key === 0) {
        onSendCode(e);
      }
      if (key === 1) {
        handleRegister(e);
      }
      if (key === 2) {
        onVerifyCode(e);
      }
    }
  };

  const onChangeHandler = (e) => {
    const { value } = e.target;
    setPhone(value);
    setIsPhoneError(false);
    setPhoneErrorMsg("");
  };

  const onSendCode = () => {
    const { first, last, payerEmail, password } = editableData || {};
    const { agreedToSValue } = agreedToS || false;
    const data = {
      payerFirst: first || "",
      payerLast: last || "",
      street: "",
      city: "",
      state: "",
      zip: "",
      payerCell: phone,
      payerEmail: payerEmail,
      password: password,
      agreeToS: agreedToSValue || "unknown",
      tokenHash: "",
      createPass: true,
      subdomain,
    };
    if (phone === "") {
      setIsPhoneError(true);
      setPhoneErrorMsg("Cell number is required.");
    } else if (phone.length < 10) {
      setIsPhoneError(true);
      setPhoneErrorMsg("Cell number must be at least 10 digits.");
    } else {
      setIsPhoneError(false);
      setPhoneErrorMsg("");
      props.sendCodeByTextMsg(data).then((resp) => {
        setEmailData(resp?.data?.id);
      });
    }
  };

  const onVerifyCode = () => {
    const { first, last, payerEmail, password } = editableData || {};
    const { agreedToSValue } = agreedToS || false;
    const data = {
      payerFirst: first || "",
      payerLast: last || "",
      street: "",
      city: "",
      state: "",
      zip: "",
      payerCell: phone,
      payerEmail: payerEmail,
      password: password,
      agreeToS: agreedToSValue || "unknown",
      tokenHash: "",
      createPass: true,
      subdomain,
      accessCode: code,
    };

    if (code) {
      // dispatch(registerAccessCode(data, isAdmin, navigate, true));
      dispatch(registerAccessCode(data, navigate, true));
    }
  };
  const handleEmailText = (i) => {
    const { payerEmail } = editableData || {};
    let data = {};
    if (i === "email") {
      setEmailAndPhoneMessage("email");
      data = {
        payerEmail: payerEmail,
        id: emailData,
        sendBy: i,
      };
    }
    if (i === "phone") {
      if (isSmsSent) {
        setError({
          toastOpenClose: true,
          errorMsg: "Please wait 10 sec before attempting again.",
          type: "danger",
        });
        return;
      }
      setIsSmsSent(true);
      setTimeout(() => {
        setIsSmsSent(false);
      }, 10000);
      setEmailAndPhoneMessage("phone");
      data = {
        payerEmail: payerEmail,
        id: emailData,
        sendBy: i,
      };
    }
    dispatch(getLoginAuthenticateCode(data));
  };
  return (
    <>
      <div className="wrapper">
        <Helmet style={[{ cssText: `${globalCustomCSSWithTheme}` }]} />
        <div className="sos-card-login sos-card">
          <div className="logo w-100 d-flex justify-content-center">
            <img
              src={logoUrl ? logoUrl : `/images/logo.png`}
              className="img-fluid"
              alt="Logo"
            />
          </div>
          {userToken && (
            <div className="text-cerulean">
              Welcome! Please create your password for your account
            </div>
          )}
          {step === 0 && (
            <div className="sos-content">
              <>
                <form>
                  {map(fieldsList, (i, index) => {
                    return (
                      <div className="form-group mb-2" key={index}>
                        {i && i.title && (
                          <label
                            htmlFor={`label_${i.title}`}
                            className="form-label"
                          >
                            {i.title}
                          </label>
                        )}
                        <input
                          type={i.type}
                          className="form-control"
                          id={`label_${i.title}`}
                          aria-describedby="emailHelp"
                          placeholder={i.placeholder}
                          value={i.value}
                          onChange={(e) => handleFields(e, index, i.key)}
                          onKeyPress={(e) => handleKeyPress(e, 1)}
                        />
                        {i && i.err && (
                          <Toast
                            isOpen={i.err}
                            showClose={false}
                            msg={i.errText}
                            type={"danger"}
                          />
                        )}
                      </div>
                    );
                  })}
                </form>
                {error.toastOpenClose && (
                    <div data-cy="general-code-error">
                      <Toast
                          isOpen={error.toastOpenClose}
                          showClose={false}
                          msg={error.errorMsg}
                          type={error.type}
                      />
                    </div>
                )}
                {registerEmailCheck && (
                  <Toast
                    isOpen={registerEmailCheck}
                    showClose={false}
                    msg={"User not found"}
                    type={"danger"}
                  />
                )}
                <div className="d-flex justify-content-end save-btn mt-4">
                  <button
                    type="button"
                    onClick={(e) => handleRegister(e)}
                    className="sos-custom-btn sos-custom-btn-css"
                    disabled={sendCodeLoading}
                  >
                    {withButtonLoader("Save", sendCodeLoading)}
                  </button>
                </div>
              </>
            </div>
          )}
          {step === 1 && (
            <div className="sos-content">
              <span className="text-cerulean d-block mb-4 text-cerulean-position">
                Your account was set up with the office. Enter your phone number
                to receive a text message with a code.
              </span>
              <>
                <div className="form-group">
                  <label htmlFor="exampleInputText" className="form-label">
                    Get a code texted to you to log in
                  </label>
                  <input
                    type="text"
                    name="phone"
                    className="form-control"
                    placeholder="Enter Your Cell Number"
                    onChange={(e) => onChangeHandler(e)}
                    maxLength={10}
                    onKeyPress={
                      ((e) => isNumberKey(e), (e) => handleKeyPress(e, 0))
                    }
                  />
                  <Toast
                    isOpen={isPhoneError}
                    showClose={false}
                    msg={phoneErrorMsg}
                    type={"danger"}
                    dur={3000}
                  />
                </div>
                <div>
                  <span className="text-cerulean d-block mb-2 Standard-text text-cerulean-position">
                    Standard data fees and text messaging fees may apply.
                  </span>
                </div>
                <div className="d-flex justify-content-end getcode-btn">
                  <button
                    type="button"
                    onClick={() => onSendCode()}
                    className="sos-custom-btn sos-custom-btn-css"
                    disabled={sendCodeLoading}
                  >
                    {withButtonLoader("Get Code", sendCodeLoading)}
                  </button>
                </div>
              </>
            </div>
          )}
          {step === 2 && (
            <div className="sos-content">
              <div>
                <a
                  href="#"
                  className="text-cerulean d-block mb-4 text-cerulean-position"
                >
                  You should have received a code texted to your phone. Please
                  enter that code into the field below.
                </a>
                <>
                  <div className="form-group">
                    <label htmlFor="exampleInputText" className="form-label">
                      Enter the code you received
                    </label>
                    <input
                      type="text"
                      name="code"
                      className="form-control"
                      placeholder="Enter Your Code"
                      onChange={(e) => setCode(e.target.value)}
                      onKeyPress={(e) => handleKeyPress(e, 2)}
                    />
                  </div>
                </>
                <div className="d-flex mt-3">
                  <Link
                    to={`#`}
                    className="text-cerulean d-block mb-4 text-cerulean-position text-decoration-none"
                    onClick={() => handleEmailText("email")}
                  >
                    Send by email
                  </Link>

                  <Link
                    to={`#`}
                    className="text-cerulean d-block mb-4 text-cerulean-position text-decoration-none"
                    onClick={() => handleEmailText("phone")}
                  >
                    Send by text
                  </Link>
                </div>
              </div>

              {isLoginCodeSuccess && (
                <Toast
                  isOpen={true}
                  showClose={false}
                  msg={`Code sent. Please check your ${emailAndPhoneMessage}.`}
                  type={"success"}
                  dur={5000}
                />
              )}
              <div className="d-flex justify-content-end submit-btn">
                <button
                  type="button"
                  className="sos-custom-btn sos-custom-btn-css"
                  onClick={() => onVerifyCode()}
                  disabled={LoginLoad}
                >
                  {withButtonLoader("Submit", LoginLoad)}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
const mapDispatchToProps = {
  sendCodeByTextMsg,
};
export default connect(null, mapDispatchToProps)(Registration);
