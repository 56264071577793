import React, {useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import {Modal} from "react-bootstrap";
import moment from "moment";
import map from "lodash/map";
import "./reply-message-popup.css";
import Toast from "../../Components/Toast";
import {withButtonLoader} from "../../helpers";
import {CrossIcon, HorizontalArrow} from "../Icons";
import FindPayer from "../FindPayer/findPayer";

const ReplyMessagePopup = (props) => {
    const {
        titleText,
        openModal,
        onCloseModal,
        message,
        onSendSuccess,
        setSendMsg,
        sendMsg,
        sendLoader,
        payerId,
        chosenPayer,
        setChosenPayer,
        count,
        errorReplyToAdmin,
        errorMessage,
        setErrorMessage,
        setErrorReplyToAdmin,
        isReply,
        setIsReply,
        onChangeHandler,
        handleClick,
        handleKeyPress,
        submitFunction,
        errorMsgEmail,
    } = props;

    let messagesEndRef = useRef(null);
    const convo = message[count]?.messages || message.messages || [];
    const handleSubmitKeyPress = (e) => {
        if (e.key === "Enter") {
            onSendSuccess();
        }
    };
    const scrollToBottom = () => {
        messagesEndRef?.current?.scrollIntoView({
            block: "end", inline: "nearest",
        });
    };
    useEffect(() => {
        scrollToBottom();
    }, [openModal]);
    const toastCallback = () => {
        setErrorMessage(false);
        scrollToBottom();
        setErrorReplyToAdmin("");
    };


    return (<Modal
        show={openModal}
        onHide={onCloseModal}
        size="md"
        centered
        className="reply-msg-content"
    >
        <div onClick={onCloseModal} className="close-modal-btn">
            <CrossIcon/>
        </div>
        <Modal.Body>
            <div>

                <div className="">
                    <div className="card-head-large-text card-hoiz-text ">
                        <div className="col-sm-12 reply-header">{titleText}</div>
                    </div>

                    {isReply && <div className="upload-doc-scroll received-msg"
                        // inner-card-form
                    >
                        {map(convo, (msgObj, index) => {
                            const payerIdMessage = message?.name || "";
                            return (<div key={index}>
                                <div className="d-flex  justify-content-between">
                                    <div className=" col-11 ">
                                        <p className="mb-0 admin-received-msg">
                                            {msgObj.sendBy === "admin" ? (<b className="office-text">Office</b>) : (<b>
                                                {payerIdMessage}
                                            </b>)}
                                            <HorizontalArrow/>
                                            {msgObj.sendBy === "admin" ? (<b>
                                                {payerIdMessage}
                                            </b>) : (<b className="office-text">Office</b>)}
                                        </p>
                                        <Link to="#" className="message-received-text">
                                            {msgObj.msg}
                                        </Link>
                                        <div>
                                                  <span className="message-received-date">
                                                    {moment(msgObj.createdAt).format("MM/DD/YYYY")}
                                                  </span>
                                            |
                                            <span className="message-received-time">
                                                        {moment(msgObj.createdAt).format("hh:mm A")}
                                                    </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider"/>
                            </div>);
                        })}
                        <div ref={messagesEndRef}/>
                    </div>}

                    {!isReply && !chosenPayer && <div className={"mb-4"}>

                        <FindPayer
                            isParentFullContainer={false}
                            submitBtnText={"Find Payer"}
                            onChangeHandler={onChangeHandler}
                            handleClick={handleClick}
                            handleKeyPress={handleKeyPress}
                            submitFunction={submitFunction}
                            errorMsgEmail={errorMsgEmail}
                        />

                    </div>}

                    {!isReply && chosenPayer && <div className={"mb-4 flex flex-space-between"}>

                        <div>{chosenPayer.payerFirst || ""} {chosenPayer.payerLast || ""}</div>
                        <div className={'clickable'} onClick={() => setChosenPayer(null)}>Clear</div>
                    </div>}

                    <div className="justify-center-text">
                        <Toast
                            isOpen={errorMessage}
                            showClose={false}
                            msg={errorReplyToAdmin?.length ? errorReplyToAdmin : "Sent Successfully"}
                            type={errorReplyToAdmin?.length ? "danger" : "success"}
                            dur={2000}
                            closeCallbackFn={toastCallback}
                            position="static"
                        />
                    </div>

                    {isReply && <div className="d-flex">
                        <input
                            type="text"
                            placeholder="Message"
                            name="message"
                            className="office-label custom-input-border-css msg-input"
                            onChange={(e) => setSendMsg(e.target.value)}
                            value={sendMsg}
                            onKeyPress={(e) => handleSubmitKeyPress(e)}
                        />
                        <button
                            name="send"
                            className="sos-custom-btn send-btn sos-custom-btn-css"
                            onClick={() => onSendSuccess()}
                            disabled={sendLoader}
                        >
                            {withButtonLoader("Send", sendLoader)}
                        </button>
                    </div>}
                    {!isReply && chosenPayer &&

                        <div className={"ml-5"}>
                            <textarea
                                placeholder="Message"
                                name="message"
                                className="col-12 office-label custom-input-border-css msg-input"
                                style={{
                                    height: "6em",
                                    lineHeight: "1em",
                                }}
                                onChange={(e) => setSendMsg(e.target.value)}
                                value={sendMsg}
                                onKeyPress={(e) => handleSubmitKeyPress(e)}
                            />
                            <button
                                name="send"
                                className=" mt-3 mb-3 sos-custom-btn send-btn sos-custom-btn-css"
                                onClick={() => {
                                    return onSendSuccess();
                                }}
                                disabled={sendLoader}
                            >
                                {withButtonLoader("Send", sendLoader)}
                            </button>
                        </div>}
                </div>


            </div>
        </Modal.Body>
    </Modal>);
};

export default ReplyMessagePopup;
