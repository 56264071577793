import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {subDomain} from "../../../utils";
import {getLogo} from "../../../Actions/authActions";

export const Logo = () => {
    const logoUrl = useSelector((state) => state.auth.logoUrl);
    const params = useParams();
    const subdomain = params.subdomain || subDomain();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!logoUrl) {
            dispatch(getLogo(subdomain));
        }
    }, [!logoUrl]);

    return <img
        src={logoUrl ? logoUrl : `/images/logo.png`}
        className="img-fluid"
        alt="Logo"
    />
}
