import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { adminUpdatePasswordLink } from "../../Actions/authActions";
import { withButtonLoader } from "../../helpers";
import { subDomain } from "../../utils";
import validator from "validator";
import "./sendLinkAdmin.css";
import Toast from "../../Components/Toast";
import { get } from "lodash";
import { Helmet } from "react-helmet";

const SendLinkEmail = (props) => {
  const dispatch = useDispatch();
  const { match } = props || {};
  const params = useParams();
  const subdomain = params.subdomain || subDomain();
  const linkFailed = useSelector((state) => state.auth.adminupdatePassError);
  const linkSend = useSelector((state) => state.auth.adminupdatePassSuccess);
  const adminupdatePass = useSelector(
    (state) => state.auth.adminupdatePassLoading
  );
  const [sendBy, setSendBy] = useState("email");
  const [emailError, setEmailError] = useState("");
  const [adminPhone, setAdminPhone] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [sendLinkEmail, setSendLinkEmail] = useState(false);
  const [sendLinkError, setSendLinkError] = useState(false);
  const logoUrl = localStorage.getItem("logoUrl");
  const theme = JSON.parse(localStorage.getItem("theme"));
  const themeColor1 = get(theme, "themeColor1");
  const themeColor2 = get(theme, "themeColor2");
  let globalCustomCSSWithTheme;
  globalCustomCSSWithTheme = `.sos-custom-btn:hover .css-15llytj span {background: ${themeColor1}} .sos-custom-btn-css {background:${themeColor1};  border: 1px solid  ${themeColor1} } .sos-custom-btn-css:hover {color: ${themeColor1}; border: 1px solid ${themeColor1}}
   .text-cerulean {color:${themeColor1}} .text-cerulean:hover {color:${themeColor1}} .sos-content .form-control {border: 1px solid ${themeColor2}} .sos-content .form-control:focus {box-shadow: 0 0 0 0.15rem ${themeColor2}; border: ${themeColor2} }  `;

  useEffect(() => {
    if (linkSend) {
      setSendLinkEmail(linkSend);
    }
    if (linkFailed) {
      setSendLinkError(linkFailed);
    }
  }, [linkFailed, linkSend]);

  const handleSendBy = (sendBy) => {
    setSendBy(sendBy);
    setEmailError("");
    setAdminEmail("");
    setAdminPhone("");
  };

  const handleFields = (e) => {
    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
    const data = e.target.value;
    if (sendBy === "phone") {
      if (data.length === 10 && rx_live.test(e.target.value)) {
        setEmailError("");
      } else {
        setEmailError("Enter valid phone");
      }
      setAdminPhone(e.target.value);
    } else {
      const adminEmail = e.target.value.toLowerCase();
      if (sendBy === "email" && validator.isEmail(adminEmail)) {
        setEmailError("");
      } else {
        sendBy === "email"
          ? setEmailError("Enter valid email")
          : setEmailError("Enter valid phone");
      }
      setAdminEmail(adminEmail);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleRegister(e);
    }
  };

  const handleRegister = () => {
    if (
      (!emailError.length > 0 && adminEmail) ||
      (!emailError.length > 0 && adminPhone)
    ) {
      let data;
      if (sendBy === "phone") {
        data = { adminPhone: adminPhone, sendBy, subdomain };
      } else {
        data = { adminEmail, sendBy, subdomain };
      }
      dispatch(adminUpdatePasswordLink(data));
    } else {
      setEmailError("Incorrect Email or Phone");
    }
  };

  return (
    <>
      <div className="wrapper">
        <Helmet style={[{ cssText: `${globalCustomCSSWithTheme}` }]} />
        <div className="sos-card-login sos-card">
          <div className="logo w-100 d-flex justify-content-center">
            <img
              src={logoUrl ? logoUrl : `/images/logo.png`}
              className="img-fluid"
              alt="Logo"
            />
          </div>
          <div className="sos-content">
            <a href="#" className="text-cerulean d-block mb-4">
              {`
              Enter your ${
                sendBy === "email" ? "email address" : "phone number"
              } to
                receive ${
                  sendBy === "email" ? "an email" : "a text message"
                } with a code.
              `}
            </a>
            <div className="form-group">
              <label htmlFor="exampleInputText" className="form-label">
                {`Get a code ${
                  sendBy === "email" ? "emailed" : "texted"
                } to you to log in`}
              </label>
              <input
                type="text"
                data-cy="inputtext"
                name={`${sendBy === "email" ? "adminEmail" : "adminPhone"}`}
                className="form-control"
                placeholder={`Enter your ${
                  sendBy === "email"
                    ? "admin email address"
                    : "admin cell number"
                }`}
                onChange={(e) => handleFields(e)}
                onKeyPress={(e) => handleKeyPress(e)}
                value={adminEmail ? adminEmail : adminPhone}
              />
              <span className="color-text">{emailError}</span>
            </div>
            <div>
              {sendBy === "phone" && (
                <Link
                  to="#"
                  className="text-cerulean d-block mb-2 Standard-text"
                  onClick={() => handleSendBy("email")}
                >
                  Send code by email
                </Link>
              )}
              {sendBy === "email" && (
                <Link
                  to="#"
                  className="text-cerulean d-block mb-2 Standard-text"
                  onClick={() => handleSendBy("phone")}
                >
                  Send code by phone
                </Link>
              )}
            </div>

            {sendLinkEmail || sendLinkError ? (
              <>
                <Toast
                  isOpen={sendLinkEmail || sendLinkError}
                  showClose={false}
                  msg={
                    sendLinkEmail
                      ? `Link sent successfully. Please check your ${sendBy}.`
                      : "user not found"
                  }
                  type={sendLinkEmail ? "success" : "danger"}
                  dur={3000}
                />
              </>
            ) : (
              ""
            )}

            <div className="d-flex justify-content-end reset-link-btn">
              <button
                type="button"
                data-cy="resetbtn"
                disabled={!linkSend ? false : true || adminupdatePass}
                onClick={(e) => handleRegister(e)}
                className="sos-custom-btn btn-width-reset btn-size-admin-password sos-custom-btn-css"
              >
                {withButtonLoader(
                  "Send admin password reset link",
                  adminupdatePass
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendLinkEmail;
