import React, {useState, useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import get from "lodash/get";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import {withButtonLoader} from "../../helpers";
import {
    getPaymentsToken,
    getAdminUserData,
    getPayerUserById,
    updatePayerUserData, transactionError, onTransactionLoading,
} from "../../Actions/authActions";
import {
    formatCreditCardNumber,
    formatExpirationDate,
    formatCVC,
} from "../Payments/utils";
import PaymentSuccessPopup from "../Payments/paymentSuccessPopup";
import {amountFormatter} from "../../utils";
import CustomCheckbox from "../../Components/CustomCheckBox";
import "../Payments/payments.css";
import {CrossIcon, DownArrowIcon} from "../../Components/Icons";
import {PaymentFactory} from "../../Payments/paymentFactory";
import CardExpiration from "../../Components/Forms/CardExpiration";
import {SAVE_TRANSACTION_SUCCESS} from "../../constants";
import {showPaymentError} from "../../Payments/errorHandler";

const AdminEditCardInfo = (props) => {
    const subdomain = props.subdomain;
    const paymentMethodData = props.paymentMethodData;
    const setAddEditSwipeMode = props.setAddEditSwipeMode;
    const dispatch = useDispatch();
    const location = useLocation();
    const {patientPayerInfo} = localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')) : [];
    // const user = useSelector((state) => state.auth.user);
    const payerUser = props.payerUser;
    const adminUser = useSelector((state) => state.auth.adminUser);
    const isTransactionLoading = useSelector((state) => state.auth.transactionLoading);
    // const [showDefaultChecked, setShowDefaultChecked] = useState();
    const [isPaymentSaveDetails, setIsPaymentSaveDetails] = useState(true);
    const [paymentType, setPaymentType] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [paymentMsg, setPaymentMsg] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    // const [selectedPayment, setSelectedPayment] = useState("");
    const [isDefault, setIsDefault] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState({
        name: "",
        amount: "",
        cardnumber: "",
        expiry: "",
        cvv: "",
    });
    const [accountType, setAccountType] = useState("");
    const [manual, setManual] = useState(null);

    const postManualPayment = (response) => {
        try {
            const ssl_card_number = response?.ssl_card_number?.substr(-4) || "";
            const paymentMethod = response?.ssl_card_short_description || "";
            const ssl_token = response?.ssl_token || "";
            const currentCardIndex = payerUser.paymentMethods.findIndex(o => o._id === paymentMethodData._id);
            payerUser.paymentMethods[currentCardIndex] = {
                ...payerUser.paymentMethods[currentCardIndex],
                isDefault: isDefault,
                ssl_card_number,
                ssl_card_short_description: paymentMethod,
                ssl_token: ssl_token,
                bankName: response.bankName,
                ssl_recurring_id: response.ssl_recurring_id,
                ssl_bank_account_type: response.ssl_bank_account_type,
                ssl_bank_account_number: response.ssl_bank_account_number?.substr(-4) || "",
                ssl_company: response.ssl_company,
                ssl_last_name: response.ssl_last_name,
                ssl_first_name: response.ssl_first_name
            }
            const update_payerUser = {
                ...payerUser,
                paymentMethods: payerUser.paymentMethods,
            };
            dispatch(updatePayerUserData(update_payerUser));//david
            setPaymentInfo({
                name: "",
                cardnumber: "",
                expiry: "",
                cvv: "",
            });
            //david
        } catch (err) {
            showResult("error", err);
            return;
        }
        if (payerUser) {
            dispatch(getPayerUserById(payerUser));//david
        }
        showResult("approval");
        setTimeout(() => {
            dispatch(getPayerUserById(payerUser));
            dispatch({type: SAVE_TRANSACTION_SUCCESS});
            setAddEditSwipeMode("");
        }, 1000);
    }

    const initManualPayment = () => {
        const factory = new PaymentFactory();
        const newManual = factory.create('MANUAL', {});
        setManual(newManual);
    }

    useEffect(() => {
        dispatch(getAdminUserData(subdomain));
        initManualPayment();
    }, [!adminUser]);

    // useEffect(() => {
    //   if (!user.id) {
    //     dispatch(getUserData(subdomain));
    //   }
    // }, [!user]);

    useEffect(() => {
        if (paymentMethodData) {
            setPaymentType(paymentMethodData.paymentType);
            setIsDefault(paymentMethodData.isDefault);

            setPaymentInfo({
                cardFirstName: paymentMethodData?.ssl_first_name,
                cardLastName: paymentMethodData?.ssl_last_name,
                accountFirstName: paymentMethodData?.ssl_first_name,
                accountLastName: paymentMethodData?.ssl_last_name,
                companyName: paymentMethodData?.ssl_company,
                bankName: paymentMethodData?.bankName,
                bankAccountType: paymentMethodData?.ssl_bank_account_type,
                bankRoutingNumber: paymentMethodData?.ssl_aba_number?.slice(-4),
                bankAccountNumber: paymentMethodData?.ssl_bank_account_number.slice(-4),
            });
            if (paymentMethodData?.ssl_bank_account_type) {
                setAccountType(paymentMethodData?.ssl_bank_account_type);
            }

        }
    }, [paymentMethodData]);


    // currently  commented need to review
    // useEffect(() => {
    //   if (user) {
    //     setShowDefaultChecked(user.paymentMethods);
    //   }
    // }, [user]);

    //  previously commented

    // useEffect(() => {
    //   if (user && user.paymentMethods) {
    //     const { paymentMethods } = user || "";
    //     const filterData = filter(paymentMethods, (p) => p.isDefault)[0];
    //     if (filterData && filterData._id) {
    //       setSelectedCardId(filterData._id);
    //     }
    //     setSelectedPayment(filterData);
    //   }
    // }, [user]);

    const totalAmountsPaid = () => {
        const total = sumBy(
            map(patientPayerInfo, (i) => ({
                ...i,
                accountBalance: parseFloat(i.accountBalance) || 0,
            })),
            "accountBalance"
        );
        return total;
    };

    function showResult(status, err) {
        if (status === "approval") {
            setPaymentMsg("editSuccessful");
        } else {
            setPaymentMsg(`${showPaymentError(err)}`);
        }
        setPaymentStatus(status);
        setOpenModal(true);
    }

    const onCloseModal = () => {
        if (paymentStatus === "approval") {
            setOpenModal(false);
            // window.location.reload();
            // navigate(`${eventUrl(subdomain)}adminDashboard`);
            return false;
        } else {
            setOpenModal(false);
        }
    };

    const handleInputChange = ({target}) => {
        if (target.name === "cardnumber") {
            target.value = formatCreditCardNumber(target.value);
        } else if (target.name === "expiry") {
            target.value = formatExpirationDate(target.value);
        } else if (target.name === "cvc") {
            target.value = formatCVC(target.value);
        } else if (target.name === "accountType") {
            setAccountType(target.value)
        }
        setPaymentInfo({...paymentInfo, [target.name]: target.value});
    };

    const handleSubmit = async (e) => {
        dispatch(onTransactionLoading());
        let formData = [...e.target.elements]
            .filter((d) => d.name)
            .reduce((acc, d) => {
                acc[d.name] = d.value;
                return acc;
            }, {});

        const payerEmail = get(payerUser, "payerEmail") || "";

        let data = {
            amount: 0,
            payerEmail,
            subdomain,
        };
        if (paymentType === "eCheck") {
            const isPersonalAccount = formData.accountType == "0";
            const isBusinessAccount = formData.accountType == "1";
            data = {
                ...data,
                isEcheck: true,
                firstName: isPersonalAccount ? formData.accountFirstName : "",
                lastName: isPersonalAccount ? formData.accountLastName : "",
                companyName: isBusinessAccount ? formData.companyName : "",
                bankAccountType: formData.accountType,
                bankName: formData.bankName,
                bankRoutingNumber: formData.bankRoutingNumber,
                bankAccountNumber: formData.bankAccountNumber
            }
        } else {
            data = {
                ...data,
                isEcheck: false,
                firstName: formData.cardFirstName || "",
                lastName: formData.cardLastName || "",
                expirationDate: formData && formData.expiry ? formData.expiry.replace(/\//g, "") : "",
                cardNumber: formData && formData.cardnumber,
                cvv: formData && formData.cvc ? formData.cvc : ""
            };
        }
        manual.data = data;
        manual.pay(e).then((res) => {
            postManualPayment(res);
        }).catch((err) => {
            showResult("error", err);
            dispatch(transactionError());
        });
    }

    return (
        <>
            <div className="cross-btn" onClick={() => setAddEditSwipeMode("")}>
                <CrossIcon/>
            </div>
            <div
                className="creditCard"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
            >
                <div className="sos-content edit-payment-header payment-inner-toggleable">
                    <div className="edit-card-info mb-5">
                        <h3>Edit Payment Information</h3>
                    </div>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        {paymentType !== "eCheck" ? (
                            <>
                                <div className="form-group mb-3">
                                    <label
                                        htmlFor="exampleInputEmail1"
                                        className="form-label payment-form-label"
                                    >
                                        Cardholder Name
                                    </label>
                                    <div className="row g-2">
                                        <div className="col-md">
                                            <div className="payment-controlpanl-style">
                                                <input
                                                    type="text"
                                                    name="cardFirstName"
                                                    className="form-control custom-input-border-css"
                                                    id="exampleInputName3"
                                                    aria-describedby="nameHelp"
                                                    onChange={(e) => handleInputChange(e)}
                                                    placeholder={paymentMethodData?.ssl_first_name || ""}
                                                    // value={paymentMethodData?.ssl_first_name}
                                                    required
                                                />

                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div>
                                                <input
                                                    type="text"
                                                    name="cardLastName"
                                                    className="form-control custom-input-border-css"
                                                    id="exampleInputName3"
                                                    aria-describedby="nameHelp"
                                                    onChange={(e) => handleInputChange(e)}
                                                    placeholder={paymentMethodData?.ssl_last_name || ""}
                                                    // value={paymentMethodData?.ssl_last_name}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label
                                        htmlFor="exampleInputPassword1"
                                        className="form-label payment-form-label"
                                    >
                                        Credit Card Number
                                    </label>
                                    <input
                                        type="tel"
                                        name="cardnumber"
                                        className="form-control custom-input-border-css bg-white"
                                        id="cardnumber"
                                        placeholder={`**** **** **** ${paymentMethodData?.ssl_card_number?.slice(-4) || ""}`}
                                        pattern="[\d| ]{15,22}"
                                        required
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className="row g-2">
                                    <div className="col-md">
                                        <div className="payment-controlpanl-style">
                                            <div>
                                                <label className="payment-form-label">
                                                    Expiry Date
                                                </label>
                                            </div>
                                            <CardExpiration
                                                onChange={(e) => handleInputChange(e)}
                                            ></CardExpiration>
                                            {/*<input*/}
                                            {/*  type="tel"*/}
                                            {/*  className="form-control custom-input-border-css bg-white"*/}
                                            {/*  id="expiry"*/}
                                            {/*  placeholder="mm/yy"*/}
                                            {/*  name="expiry"*/}
                                            {/*  pattern="\d\d/\d\d"*/}
                                            {/*  required*/}
                                            {/*  onChange={(e) => handleInputChange(e)}*/}
                                            {/*/>*/}
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div>
                                            <div>
                                                <label className="payment-form-label">
                                                    Security Code
                                                </label>
                                            </div>
                                            <input
                                                type="tel"
                                                className="form-control custom-input-border-css bg-white"
                                                placeholder="Enter CVC"
                                                name="cvc"
                                                pattern="\d{3,4}"
                                                required
                                                onChange={(e) => handleInputChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="checkbox-list mt-3">
                                    <label className="label-check font-weight-light ">
                                        <CustomCheckbox
                                            defaultChecked
                                        />
                                        <span>Save these payment details for future payments</span>
                                    </label>
                                    <div className="payment-checbox">
                                        <label className="font-weight-light label-check ml-xl-5">
                                            <CustomCheckbox
                                                onChange={(e) => setIsDefault(e.target.checked)}
                                                defaultChecked={paymentMethodData?.isDefault || false}
                                            />
                                            <span className="word-break: break-word">Set as default payment method</span>
                                        </label>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="dropdown custom-dropdown m-b-20">
                                    <select
                                        name="accountType"
                                        className="form-control bg-white"
                                        required
                                        value={paymentInfo.accountType}
                                        onChange={(e) => handleInputChange(e)}
                                    >
                                        {(typeof paymentInfo.accountType !== 'undefined') ? (
                                            <option selected value="">
                                                Account Type
                                            </option>
                                        ) : ""}
                                        <option value="0">Personal</option>
                                        <option value="1">Business</option>
                                    </select>
                                    <div className="down-arrow">
                                        <DownArrowIcon/>
                                    </div>
                                </div>
                                {accountType == "0" ?
                                    (
                                        <div className="form-group mb-3">
                                            <label
                                                htmlFor="exampleInputBankName1"
                                                className="form-label payment-form-label"
                                            >
                                                Name On Account
                                            </label>
                                            <div className="row g-2">
                                                <div className="col-md">
                                                    <div className="payment-controlpanl-style">
                                                        <input
                                                            type="text"
                                                            name="accountFirstName"
                                                            className="form-control custom-input-border-css"
                                                            id="exampleInputName3"
                                                            aria-describedby="nameHelp"
                                                            placeholder="First Name"
                                                            value={paymentInfo.accountFirstName}
                                                            onChange={(e) => handleInputChange(e)}
                                                            required
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md">
                                                    <div>
                                                        <input
                                                            type="text"
                                                            name="accountLastName"
                                                            className="form-control custom-input-border-css"
                                                            id="exampleInputName3"
                                                            aria-describedby="nameHelp"
                                                            placeholder="Last Name"
                                                            value={paymentInfo.accountLastName}
                                                            onChange={(e) => handleInputChange(e)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="form-group mb-3">
                                            <label
                                                htmlFor="exampleInputBankName1"
                                                className="form-label payment-form-label"
                                            >
                                                Name On Account
                                            </label>
                                            <input
                                                type="text"
                                                name="companyName"
                                                required
                                                className="form-control custom-input-border-css"
                                                id="exampleInputName1"
                                                value={paymentInfo.companyName}
                                                aria-describedby="nameHelp"
                                                placeholder="Enter Name on the Account"
                                            />

                                        </div>
                                    )}
                                <div className="form-group mb-3">
                                    <label
                                        htmlFor="exampleInputBankName1"
                                        className="form-label payment-form-label"
                                    >
                                        Bank Name
                                    </label>
                                    <input
                                        type="text"
                                        name="bankName"
                                        required
                                        className="form-control bg-white"
                                        id="exampleInputBankName"
                                        aria-describedby="bankNameHelp"
                                        placeholder={paymentInfo.bankName}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label
                                        htmlFor="exampleInputBankNumber1"
                                        className="form-label payment-form-label"
                                    >
                                        Bank Routing Number
                                    </label>
                                    <input
                                        type="text"
                                        required
                                        className="form-control bg-white"
                                        name="bankRoutingNumber"
                                        id="exampleInputBankNumber1"
                                        placeholder="Enter Bank Routing Number"
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label
                                        htmlFor="exampleInputAccountNumber1"
                                        className="form-label payment-form-label"
                                    >
                                        Bank Account Number
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control bg-white"
                                        id="exampleInputAccountNumber1"
                                        required
                                        name="bankAccountNumber"
                                        placeholder={'**** **** **** ' + paymentInfo.bankAccountNumber}
                                    />
                                </div>

                                <div className="checkbox-list mt-3">
                                    <label className="label-check font-weight-light float-end">
                                        <CustomCheckbox
                                            checked={isPaymentSaveDetails}
                                            onChange={(e) =>
                                                setIsPaymentSaveDetails(e.target.checked)
                                            }
                                        />
                                        Save these payment details for future payments
                                    </label>
                                    <div className="payment-checbox">
                                        <label className="font-weight-light label-check ml-xl-5">
                                            <CustomCheckbox
                                                name="isDefault"
                                                onChange={(e) => setIsDefault(e.target.checked)}
                                            />
                                            Set as default payment method
                                        </label>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="checkbox-list mt-3">
                            <div className="d-flex justify-content-center">
                                <button
                                    type="submit"
                                    className="save-payment-btn sos-custom-btn-css">
                                    {withButtonLoader(
                                        "Save Payment Information",
                                        isTransactionLoading, "#fff"
                                    )}
                                    {/* #6e86c4 */}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* <div className="hommeexisting-img-edit">
        <img src="/images/payment-icon.png" />
      </div> */}
            <PaymentSuccessPopup
                openModal={openModal}
                paymentMsg={paymentMsg}
                onCloseModal={onCloseModal}
            />
        </>
    );
};

export default AdminEditCardInfo;
