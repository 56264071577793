import config from "../../config";
import React, {useState} from "react";
import HelloSign from "hellosign-embedded";

const client = new HelloSign();

const HelloSignUser = ({
                           embeddedHelloSignUrl,
                           payerEmail,
                           onSignHellosign,
                           showHelloSign,
                           setShowHelloSign,
                       }) => {
    localStorage.debug = "hellosign-embedded:*";
    localStorage.removeItem('helloSignCalled');
    const [helloSignCalled, setHelloSignCalled] = useState(false);

    client.open(embeddedHelloSignUrl || "", {
        clientId: config.HELLOSIGN_CLIENT_ID,
        skipDomainVerification: window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('.dev') > -1,
        // redirectTo: url after signing
        allowCancel: true,
        testMode: window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('.dev') > -1,
        debug: window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('.dev') > -1,
        requestingEmail: payerEmail,
    });

    client.on("sign", (data) => {
        if (helloSignCalled || localStorage.getItem('helloSignCalled')) {
            return;
        }
        let signedDocs = [];
        if (localStorage.getItem('helloSignDocs')) {
            signedDocs.push(...JSON.parse(localStorage.getItem('helloSignDocs')));
        }
        if (signedDocs.includes(data.signatureId)) {
            return;
        }
        signedDocs.push(data.signatureId);
        localStorage.setItem('helloSignDocs', JSON.stringify(signedDocs));
        localStorage.setItem('helloSignCalled', 'true');
        setHelloSignCalled(true);
        console.log(data);
        onSignHellosign(data.signatureId);
        setShowHelloSign(!showHelloSign);
        client.close();
        setTimeout(() => {
            setHelloSignCalled(false);
            localStorage.removeItem('helloSignCalled');
        }, 2000);
    });

    client.on('error', (data) => {
        console.log(data);
        console.log(`Something went wrong! Please try again. Error code: ${data.code}`);
    });

    client.once("cancel", () => {
        setShowHelloSign(!showHelloSign);
        client.close();
    });

    return <></>;
};
export default HelloSignUser;
