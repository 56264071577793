import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {updatePasswordLink} from "../../Actions/authActions";
import {getParameterByName, withButtonLoader} from "../../helpers";
import {subDomain, eventUrl} from "../../utils";
import {map} from "lodash";
import validator from "validator";
import "./forgot.css";
import Toast from "../../Components/Toast";
import {get} from "lodash";
import {Helmet} from "react-helmet";
import {UPDATEPASSWORD_LINK_ERROR_RESET, UPDATEPASSWORD_LINK_SUCCESS_RESET} from "../../constants";

const ForgotPassword = (props) => {
    const {match} = props || {};
    const params = useParams();
    const subdomain = params.subdomain || subDomain();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const logoUrl = localStorage.getItem("logoUrl");
    const theme = useSelector((state) => state.auth.theme);
    const themeColor1 = get(theme, "themeColor1");
    const themeColor2 = get(theme, "themeColor2");

    let globalCustomCSSWithTheme;
    globalCustomCSSWithTheme = `.sos-custom-btn:hover .css-15llytj span {background: ${themeColor1}} .sos-custom-btn-css {background:${themeColor1};  border: 1px solid  ${themeColor1} } .sos-custom-btn-css:hover {color: ${themeColor1}; border: 1px solid ${themeColor1}}
  .text-cerulean {color:${themeColor1}} .text-cerulean:hover {color:${themeColor1}} .sos-content .form-control {border: 1px solid ${themeColor2}} .sos-content .form-control:focus {box-shadow: 0 0 0 0.15rem ${themeColor2}; border: ${themeColor2} }  `;
    const [emailError, setEmailError] = useState("");
    const cemail = getParameterByName("cemail");
    const [editableData, setEditableData] = useState({
        payerEmail: cemail,
    });
    const [sendCodeMessage, setSendCodeMessage] = useState(
        "Link sent. Please check your email."
    );
    const [checkNumber, setCheckNumber] = useState("");
    const linkSendSuccess = useSelector((state) => state.auth.updateLinkSuccess);
    const updatePasswordLinkSuccessReset = () => dispatch({type: UPDATEPASSWORD_LINK_SUCCESS_RESET});

    const linkSend = useSelector((state) => state.auth.isforgotEmailSuccess);
    const updateLinkLoading = useSelector(
        (state) => state.auth.updateLinkLoading
    );
    const inValidPhoneOrEmail = useSelector(
        (state) => state.auth.updateLinkError
    );
    const updatePasswordLinkErrorReset = () => dispatch({type: UPDATEPASSWORD_LINK_ERROR_RESET});


    const [fieldsList, setFieldsList] = useState([
        {
            key: "payerEmail",
            type: "email",
            title: "Enter email or phone",
            placeholder: "Enter email or phone",
            err: false,
            errText: "",
            className: "col-12 color-text",
            value: "",
        },
    ]);

    const handleFields = (e, i, key) => {
        const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
        if (rx_live.test(e.target.value)) {
            const data = e.target.value;
            setCheckNumber({
                payerCell: e.target.value,
                subdomain,
                sendby: "phone",
            });
            if (data.length === 10) {
                setEmailError("");
                setSendCodeMessage("Link sent. Please check your phone.");
            } else {
                setEmailError("Enter valid email or phone");
            }
        } else {
            if (validator.isEmail(e.target.value)) {
                setEmailError("");
            } else {
                setEmailError("Enter valid email or phone");
            }
            setCheckNumber({
                payerEmail: e.target.value.toLowerCase(),
                subdomain,
                sendby: "email",
            });
            setSendCodeMessage("Link sent. Please check your email.");
        }

        const obj = {...editableData};
        const arr = [...fieldsList];
        const {value} = e.target;

        const len = value.length;
        if (len < 31) {
            obj[key] = value;
        }
        if (arr[i].err) {
            arr[i].err = false;
            arr[i].errText = "";
        }
        if (key === "payerEmail") {
            arr[i].value = value.toLowerCase();
        } else {
            arr[i].value = value;
        }
        setEditableData(obj);
        setFieldsList(arr);
    };

    const handleRegister = (e) => {
        e.preventDefault();

        if (checkNumber && !emailError) {
            dispatch(updatePasswordLink(checkNumber));
        } else if (checkNumber.payerEmail || checkNumber.payerCell) {
            setEmailError("Email or phone is Invalid");
        } else {
            setEmailError("Email or phone is required");
        }
    };

    const handleRedirect = () => {
        navigate(`${eventUrl(subdomain)}login`);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleRegister(e);
        }
    };

    return (
        <>
            <div className="wrapper">
                <Helmet style={[{cssText: `${globalCustomCSSWithTheme}`}]}/>
                <div className="sos-card-login sos-card">
                    <div className="logo w-100 d-flex justify-content-center">
                        <img
                            src={logoUrl ? logoUrl : `/images/logo.png`}
                            className="img-fluid"
                            alt="Logo"
                        />
                    </div>

                    <div className="sos-content">
                        {!linkSend ? (
                            <form>
                                {map(fieldsList, (i, index) => {
                                    return (
                                        <div className="form-group mb-2" key={index}>
                                            {i && i.title && (
                                                <label
                                                    htmlFor="exampleInputEmail1"
                                                    className="form-label"
                                                >
                                                    {i.title}
                                                </label>
                                            )}
                                            <input
                                                type={i.type}
                                                className="form-control"
                                                id="inputEmail"
                                                data-cy="inputtext"
                                                value={i.value}
                                                aria-describedby="emailHelp"
                                                placeholder={i.placeholder}
                                                onChange={(e) => handleFields(e, index, i.key)}
                                                onKeyPress={(e) => handleKeyPress(e)}
                                            />
                                        </div>
                                    );
                                })}
                                <span className="color-text" data-cy="forget-password-error">{emailError}</span>
                                <div className={`${(linkSendSuccess || inValidPhoneOrEmail) ? "mb-5" : ""}`}>
                                    {linkSendSuccess && (
                                        <div data-cy="link-sent-message">
                                            <Toast
                                                isOpen={linkSendSuccess}
                                                showClose={false}
                                                msg={sendCodeMessage}
                                                type={"success"}
                                                dur={4000}
                                                closeCallbackFn={updatePasswordLinkSuccessReset}
                                            />
                                        </div>
                                    )}
                                    {inValidPhoneOrEmail && (
                                        <div data-cy="forget-password-invalid-error">
                                            <Toast
                                                isOpen={inValidPhoneOrEmail}
                                                showClose={false}
                                                msg={"Invalid credentials"}
                                                type={"danger"}
                                                dur={4000}
                                                closeCallbackFn={updatePasswordLinkErrorReset}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="d-flex justify-content-end save-btn mt-4">
                                    <button
                                        type="button"
                                        data-cy="resetbtn"
                                        onClick={(e) => handleRegister(e)}
                                        className="sos-custom-btn btn-width-reset sos-custom-btn-css"
                                        disabled={updateLinkLoading}
                                    >
                                        {withButtonLoader(
                                            "Send password reset link",
                                            updateLinkLoading
                                        )}
                                    </button>
                                </div>
                            </form>
                        ) : (
                            <>
                                <div className="form-group mb-2">
                                    <label className="content-design">
                                        {"A password reset link was sent to your {email/phone}"}
                                    </label>
                                    <label className="content-design">
                                        {
                                            "Please check your {email / phone } and login using that link."
                                        }
                                    </label>
                                </div>
                                <div className="d-flex justify-content-end save-btn mt-4">
                                    <button
                                        type="button"
                                        onClick={(e) => handleRedirect(e)}
                                        className="sos-custom-btn btn-width-reset sos-custom-btn-css"
                                    >
                                        Home
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
