import config from "../../config";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HelloSign from "hellosign-embedded";

const client = new HelloSign({
  clientId: config.HELLOSIGN_CLIENT_ID,
  allowCancel: true,
  skipDomainVerification: window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('.dev') > -1,
  testMode: window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('.dev') > -1,
  debug: window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('.dev') > -1
  // container: document.getElementById("sign-here"),
});

const AdminHelloTest = (props) => {
  const { match } = props || {};
  const params = useParams();

  //   {
  //   editHelloSignUrl,
  //   onCreateHelloSignTemplate,
  //   userAdmin,
  //   formIndex,
  //   updateAdminUser,
  //   showHelloSign,
  //   setShowHelloSign,
  //   setOpenModalUpdate,
  //   setIsOpenEditIcon,
  //   setPopUpMessage,
  //   setHelloSignLoader,
  // }) => {
  const [fullDebugUrl, setFullDebugUrl] = useState("");
  const { debugurl } = useParams();
  useEffect(() => {
    // const fullDbgUrl = `https://embedded.hellosign.com/prep-and-send/embedded-template?cached_params_token=${debugurl}`
    const fullDbgUrl = `https://embedded.hellosign.com/prep-and-send/embedded-template?cached_params_token=e260155605416faf8e926420784f3a50`;
    
    setFullDebugUrl(fullDbgUrl);

  }, []);


  localStorage.debug = "hellosign-embedded:*";

  useEffect(() => {
    if (fullDebugUrl > "") {
      client.open(fullDebugUrl || "");
    }
  }, [fullDebugUrl]);

  // client.on("open", (data) => {
  // });

  // client.on("ready", (data) => {
  //   setHelloSignLoader(false);
  // });

  // client.on("error", (data) => {
  //   setHelloSignLoader(false);
  // });

  // client.once("createTemplate", (data) => {
  //   const { templateId: template_id } = data;
  //   const intakeformId = userAdmin.intakeforms[formIndex]._id.toString();
  //   onCreateHelloSignTemplate(
  //     subdomain,
  //     sosCustomerId,
  //     intakeformId,
  //     template_id
  //   );
  //   setShowHelloSign(!showHelloSign);
  // });

  // client.on("cancel", () => {
  // });

  // client.on("close", () => {
  //   setShowHelloSign(false);
  //   setHelloSignLoader(false);
  // });

  // client.once("cancel", (data) => {
  //   updateAdminUser();
  //   setIsOpenEditIcon(false);
  //   setShowHelloSign(!showHelloSign);
  //   setHelloSignLoader(false);
  //   client.close();
  // });
  // client.on("finish", () => {
  //   setShowHelloSign(!showHelloSign);
  //   setOpenModalUpdate(true);
  //   setPopUpMessage("Intake form updated successfully");
  //   setIsOpenEditIcon(false);
  // });
  return <div id="sign-here">
    <p>we are having fun</p>
  </div>;
};
export default AdminHelloTest;
