import {SwipePayment} from "./swipePayment";
import {ManualPayment} from "./manualPayment";

export class PaymentFactory {
    create(type, data) {
        switch (type) {
            case 'SWIPE':
                return new SwipePayment(data);
            case 'SELECTED':
            case 'MANUAL':
                return new ManualPayment(data);
        }
    }
}
