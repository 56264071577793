import React, {useState} from "react";
import {CrossIcon} from "../Icons";
import {Modal} from "react-bootstrap";

const CardExpiration = (props) => {
    const {
        onChange
    } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [error, setError] = useState(null);

    const handleInputChange = function (e) {
        if (!e.target.value) {
            return;
        }
        if (e.target.value.length !== 5) {
            setError("Please use format mm/yy for expiration date");
            setModalOpen(true);
            return;
        }
        let month = +e.target.value.split('/')[0];
        let year = +('20' + e.target.value.split('/')[1]);
        if (month < 1 || month > 12) {
            setError("The month for expiration date is wrong");
            setModalOpen(true);
            e.target.value = null;
            return;
        }
        if (year < new Date().getFullYear()) {
            setError("The year for expiration date already expired");
            setModalOpen(true);
            e.target.value = null;
            return;
        }
        onChange(e);
    }

    const clearNumber = function (value = "") {
        return value.replace(/\D+/g, "");
    }

    const formatExpirationDate = function (e) {
        const clearValue = clearNumber(e.target.value);
        if (clearValue.length >= 3) {
            e.target.value = (`${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`);
            return;
        }

        e.target.value = (clearValue);
    }


    return (
        <div>
            <input
                type="tel"
                className="form-control custom-input-border-css bg-white"
                id="expiry"
                placeholder="mm/yy"
                name="expiry"
                pattern="\d\d/\d\d"
                required
                onChange={(e) => formatExpirationDate(e)}
                onBlur={(e) => handleInputChange(e)}
            />
            <Modal show={modalOpen} size="md" centered className="success-payment">
                <div onClick={() => setModalOpen(false)} className="close-modal-btn">
                    <CrossIcon/>
                </div>
                <Modal.Body>
                    <div className="modal-headr-text">
                        {error}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default CardExpiration;
