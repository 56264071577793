import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import map from "lodash/map";
import {
  adminLoginAct,
  verifyAdminLoginAuthenticateCode,
  getAdminLoginAuthenticateCode,
  getLogo,
} from "../../Actions/authActions";
import { withButtonLoader } from "../../helpers";
import { subDomain, eventUrl } from "../../utils";
import Toast from "../../Components/Toast";
import "./../Login/login.css";
import { get } from "lodash";
import { Helmet } from "react-helmet";
import { LaptopWindows } from "@material-ui/icons";


const AdminLogin = (props) => {
  const { match } = props || {};
  const params = useParams();
  const subdomain = params.subdomain || subDomain();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { existingUser } = localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')) : false;
  const data = localStorage.getItem("user");
  const dataLogin = JSON.parse(data) || {};
  const isLoginLoad = useSelector((state) => state.auth.isLoginAdminLoading);
  const adminUser = useSelector((state) => state.auth.adminUser);
  const logoUrl = useSelector((state) => state.auth.logoUrl);
  const theme = useSelector((state) => state.auth.theme);
  const [isSmsSent, setIsSmsSent] = useState(false);
  const themeColor1 = get(theme, "themeColor1");
  const themeColor2 = get(theme, "themeColor2");

  let globalCustomCSSWithTheme;
  globalCustomCSSWithTheme = `.sos-custom-btn:hover .css-15llytj span {background: ${themeColor1}} .sos-custom-btn-css {background:${themeColor1};  border: 1px solid  ${themeColor1} } .sos-custom-btn-css:hover {color: ${themeColor1}; border: 1px solid ${themeColor1}}
   .text-cerulean {color:${themeColor1}} .text-cerulean:hover {color:${themeColor1}}  .custom-input-border-css {border: 1px solid ${themeColor2 ? themeColor2 : "#94bbad"
    }} .sos-content .custom-input-border-css {border: 1px solid ${themeColor2 ? themeColor2 : "#94bbad"
    }} .sos-content .form-control:focus {box-shadow: 0 0 0 0.15rem ${themeColor2}; border: ${themeColor2} } `;
  const adminSendCodeSuccess = useSelector(
    (state) => state.auth.isAdminLoginCodeSuccess
  );
  const isVerifyLoginCodeError = useSelector(
    (state) => state.auth.isVerifyLoginCodeError
  );

  const [emailAndPhoneMessage, setEmailAndPhoneMessage] = useState("email");
  const _id = useSelector(
    (state) => state.auth.adminUser && state.auth.adminUser._id
  );
  const loginCheck = useSelector((state) => state.auth.isLoginAdminSuccess);
  const codeVerified = useSelector(
    (state) => state.auth.isVerifyLoginCodeSuccess
  );

  const verifyLoginCodeLoading = useSelector(
    (state) => state.auth.isVerifyLoginCodeLoading
  );

  const isAuthenticated = useSelector(
    (state) => state.auth.isAuthenticated
  );

  const [editableData, setEditableData] = useState({});
  const [accessCode, setAccessCode] = useState("");

  const [error, setError] = useState({
    isOpenClose: false,
    errorText: "",
  });
  const [fieldsList, setFieldsList] = useState([
    {
      key: "adminEmail",
      type: "email",
      placeholder: "Enter email",
      label: "Email",
      err: false,
      errText: "",
      value: "",
    },
    {
      key: "password",
      type: "password",
      placeholder: "Enter password",
      label: "Password",
      err: false,
      errText: "",
      value: "",
    },
  ]);

  useEffect(() => {
    if (codeVerified) {
      window.location.href = `${eventUrl(subdomain)}adminDashboard`;
    }
  }, [codeVerified]);

  useEffect(() => {
    if (isAuthenticated) {
      window.location.href = `${eventUrl(subdomain)}adminDashboard`;
    }
  }, [isAuthenticated]);

  const handleFields = (e, i, key) => {
    const obj = { ...editableData };
    const arr = [...fieldsList];
    const { value } = e.target;
    const len = value.length;
    // if (len < 31) {
      if (key === "adminEmail") {
        obj[key] = value.toLowerCase();
      } else {
        obj[key] = value;
      }
    // }
    if (arr[i].err) {
      arr[i].err = false;
      arr[i].errText = "";
    }
    if (key === "adminEmail") {
      arr[i].value = value.toLowerCase();
    } else {
      arr[i].value = value;
    }
    setEditableData(obj);
    setFieldsList(arr);
  };

  useEffect(() => {
    if (!logoUrl) {
      dispatch(getLogo(subdomain));
    }
  }, [!logoUrl]);

  const isValid = () => {
    const arr = [...fieldsList];
    const { adminEmail, password } = editableData || {};
    const handleErr = (i, msg) => {
      arr[i].err = true;
      arr[i].errText = msg;
    };
    if (!existingUser) {
      if (!adminEmail) {
        handleErr(0, "Email is required.");
      }
    }
    if (!password) {
      handleErr(1, "Password is required.");
    }
    setFieldsList(arr);
    if ((!existingUser && !adminEmail) || !password) {
      return false;
    }
    return true;
  };

  const handleOnChangeCode = (e) => {
    const code = e.target.value;
    setAccessCode(code);
  };

  const handleSubmitCode = () => {
    if (!accessCode) {
      setError({ isOpenClose: true, errorText: "Code required" });
    } else if (accessCode.length < 6 || accessCode.length > 6) {
      setError({
        isOpenClose: true,
        errorText: "Code must be 6 digits long",
      });
    } else {
      setError({
        isOpenClose: false,
        errorText: "",
      });
      const temp = {
        subdomain,
        accessCode,
        _id,
      };
      dispatch(verifyAdminLoginAuthenticateCode(temp));
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (isValid()) {
      const { adminEmail, password } = editableData || {};
      if (existingUser) {
        const data = {
          password,
          adminEmail: dataLogin.adminEmail,
          createPass: false,
          subdomain,
          sendBy: "email",
        };
        dispatch(adminLoginAct(data));
      } else {
        dispatch(adminLoginAct({ adminEmail, password, subdomain }));
      }
    }
  };

  const handleKeyPress = (e, key) => {
    if (e.key === "Enter") {
      if (key === 0) {
        handleLogin(e);
      }
      if (key === 1) {
        handleSubmitCode();
      }
    }
  };

  const handleEmailText = (i) => {
    let data = {};
    if (i === "email") {
      setEmailAndPhoneMessage("email");

      data = {
        adminEmail: adminUser.adminEmail,
        _id,
        sendBy: i,
      };
    }
    if (i === "phone") {
      if (isSmsSent) {
        setError({
          toastOpenClose: true,
          errorMsg: "Please wait 10 sec before attempting again.",
          type: "danger",
        });
        return;
      }
      setIsSmsSent(true);
      setTimeout(() => {
        setIsSmsSent(false);
      }, 10000);
      setEmailAndPhoneMessage("phone");
      data = {
        adminEmail: adminUser.adminEmail,
        _id,
        sendBy: i,
      };
    }
    dispatch(getAdminLoginAuthenticateCode(data));
  };

  const navigateOut = () => {
    window.location.href = `${eventUrl(subdomain)}sendlink`;
  }

  return (
    <>
      <div className="wrapper">
        <Helmet style={[{ cssText: `${globalCustomCSSWithTheme}` }]} />
        <div className="sos-card-login sos-card">
          <div className="logo w-100 d-flex justify-content-center">
            <img
              src={logoUrl ? logoUrl : `/images/logo.png`}
              className="img-fluid"
              alt="Logo"
            />
          </div>
          {!loginCheck ? (
            <div className="sos-content ">
              <>
                <form>
                  {map(fieldsList, (f, i) => {
                    if (existingUser && f.type === "email") {
                      return false;
                    }
                    return (
                      <div className="form-group mb-4" key={i}>
                        <label
                          htmlFor={`exampleInput${f.key}${i}`}
                          className="form-label"
                        >
                          {f.label}
                        </label>
                        <input
                          type={f.type}
                          name={f.key}
                          value={f.value}
                          onChange={(e) => handleFields(e, i, f.key)}
                          className="form-control custom-input-border-css"
                          id={`exampleInput${f.key}${i}`}
                          aria-describedby={`${f.key}Help`}
                          placeholder={f.placeholder}
                          onKeyPress={(e) => handleKeyPress(e, 0)}
                        />
                        <Toast
                          isOpen={f.err}
                          showClose={false}
                          msg={f.errText}
                          type={"danger"}
                          dur={3000}
                        />
                      </div>
                    );
                  })}
                </form>
                <a
                  className="text-cerulean extra-spacing d-block text-cerulean-position"
                  onClick={() => navigateOut()}
                >
                  Forgot Password?
                </a>
              </>
              <div className="d-flex justify-content-end">
                <button
                  type="submit"
                  data-cy="sos-login-btn"
                  className="sos-custom-btn sos-custom-btn-css"
                  onClick={(e) => handleLogin(e)}
                  disabled={isLoginLoad}
                >
                  {withButtonLoader("Login", isLoginLoad)}
                </button>
              </div>
            </div>
          ) : (
            <>
              <>
                <div className="sos-content">
                  <div>
                    <span className="text-cerulean d-block mb-4 text-cerulean-position text-decoration-underline">
                      {`You should have received a code sent to your ${emailAndPhoneMessage}. Please
                      enter that code into the field below.`}
                    </span>
                    <>
                      <div className="form-group mb-4">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Enter the code you received
                        </label>

                        <input
                          type="text"
                          name="accessCode"
                          className="form-control custom-input-border-css"
                          id="accessCode"
                          aria-describedby="emailHelp"
                          placeholder={"Enter Your Code"}
                          onChange={(e) => handleOnChangeCode(e)}
                          onKeyPress={(e) => handleKeyPress(e, 1)}
                        />
                      </div>
                    </>
                    <div className="d-flex">
                      <Link
                        to={`#`}
                        className="text-cerulean d-block mb-4 text-cerulean-position text-decoration-none"
                        onClick={() => handleEmailText("email")}
                      >
                        Send by email
                      </Link>

                      <Link
                        to={`#`}
                        className="text-cerulean d-block mb-4 text-cerulean-position text-decoration-none"
                        onClick={() => handleEmailText("phone")}
                      >
                        Send by text
                      </Link>
                    </div>
                  </div>

                  {error.isOpenClose && (
                    <Toast
                      isOpen={error.isOpenClose}
                      showClose={false}
                      msg={error.errorText}
                      type={"danger"}
                    />
                  )}
                  {adminSendCodeSuccess && (
                    <Toast
                      isOpen={true}
                      showClose={false}
                      msg={`Code sent. Please check your ${emailAndPhoneMessage}.`}
                      type={"success"}
                      dur={5000}
                    />
                  )}
                  {isVerifyLoginCodeError && (
                    <Toast
                      isOpen={true}
                      showClose={false}
                      msg={`Invalid code. Err 0dc44d63`}
                      type={"danger"}
                      dur={5000}
                    />
                  )}
                  <div className="d-flex justify-content-end save-btn mt-4">
                    <button
                      type="button"
                      data-cy="sos-access-login-btn"
                      onClick={(e) => handleSubmitCode(e)}
                      className="sos-custom-btn sos-custom-btn-css"
                      disabled={verifyLoginCodeLoading}
                    >
                      {withButtonLoader("Submit", verifyLoginCodeLoading)}
                    </button>
                  </div>
                </div>
              </>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
