import {get} from "lodash";
import {useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import React from "react";

export const GlobalTheme = () => {
    const theme = useSelector((state) => state.auth.theme);
    const themeColor1 = get(theme, "themeColor1");
    const themeColor2 = get(theme, "themeColor2");
    let globalCustomCSSWithTheme;
    globalCustomCSSWithTheme = `.sos-custom-btn:hover .css-15llytj span {background: ${themeColor1}} .sos-custom-btn-css {background:${themeColor1};  border: 1px solid  ${themeColor1} } .sos-custom-btn-css:hover {color: ${themeColor1}; border: 1px solid ${themeColor1}}
  .text-cerulean {color:${themeColor1}} .text-cerulean:hover {color:${themeColor1}} .sos-content .form-control {border: 1px solid ${themeColor2}} .sos-content .form-control:focus {box-shadow: 0 0 0 0.15rem ${themeColor2}; border: ${themeColor2} }  `;

    return {
        render: <Helmet style={[{cssText: `${globalCustomCSSWithTheme}`}]}/>,
        themeColor1,
        themeColor2
    }
}
