import React, {useState} from "react";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getParameterByName, withButtonLoader} from "../../helpers";
import {
    generateAccessCode,
    verificationAccessCode,
} from "../../Actions/authActions";
import {subDomain} from "../../utils";
import Toast from "../../Components/Toast";
import {get, map} from "lodash";
import {Helmet} from "react-helmet";

const AccessCode = (props) => {
    const {match} = props || {};
    const params = useParams();
    const subdomain = params.subdomain || subDomain();
    const logoUrl = localStorage.getItem("logoUrl");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const cname = getParameterByName("cname") || "";
    const {createdUser, isAdmin} = localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')) : false;
    const loginLoad = useSelector((state) => state.auth.isLoginLoad);
    const loginError = useSelector((state) => state.auth.isLoginError);
    const [isSmsSent, setIsSmsSent] = useState(false);
    const [error, setError] = useState({
        toastOpenClose: false,
        errorMsg: "",
    });
    const [editableData, setEditableData] = useState({
        code: cname.split(" ")[0],
    });
    const generatedAccessCode = useSelector(
        (state) => state.auth.isGenerateAccessCodeSuccess
    );
    const generatedAccessCodeError = useSelector(
        (state) => state.auth.isGenerateAccessCodeError
    );
    const sendCodeSuccess = useSelector((state) => state.auth.isSendCodeSuccess);

    const theme = useSelector((state) => state.auth.theme);
    const themeColor1 = get(theme, "themeColor1");
    const themeColor2 = get(theme, "themeColor2");
    let globalCustomCSSWithTheme;
    globalCustomCSSWithTheme = `.sos-custom-btn:hover .css-15llytj span {background: ${themeColor1}} .sos-custom-btn-css {background:${themeColor1};  border: 1px solid  ${themeColor1} } .sos-custom-btn-css:hover {color: ${themeColor1}; border: 1px solid ${themeColor1}}
  .text-cerulean {color:${themeColor1}} .text-cerulean:hover {color:${themeColor1}} .sos-content .form-control {border: 1px solid ${themeColor2}} .sos-content .form-control:focus {box-shadow: 0 0 0 0.15rem ${themeColor2}; border: ${themeColor2} }  `;
    const [emailAndPhoneMessage, setEmailAndPhoneMessage] = useState("phone");

    const [fieldsList, setFieldsList] = useState([
        {
            key: "code",
            type: "text",
            title: "Enter the code you received",
            placeholder: "Enter Your Code",
            err: false,
            errText: "",
            className: "col-12",
        },
    ]);

    const handleFields = (e, i, key) => {
        const obj = {...editableData};
        const arr = [...fieldsList];
        const {value} = e.target;
        const len = value.length;
        if (len < 31) {
            obj[key] = value;
        }
        if (arr[i].err) {
            arr[i].err = false;
            arr[i].errText = "";
        }
        setEditableData(obj);
        setFieldsList(arr);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleRegister(e);
        }
    };

    const isValid = () => {
        const arr = [...fieldsList];
        const {code} = editableData || {};
        const handleErr = (i, msg) => {
            arr[i].err = true;
            arr[i].errText = msg;
        };

        if (!code) {
            handleErr(0, "Code is required.");
        } else if (code.length < 6) {
            handleErr(0, "Code must be 6 digits long");
        }
        setFieldsList(arr);
        if (!code || code.length < 6) {
            return false;
        }
        return true;
    };

    const handleRegister = () => {
        if (isValid()) {
            const {code} = editableData || {};
            const data = {
                ...createdUser,
                accessCode: code,
                subdomain,
            };
            if (code) {
                dispatch(verificationAccessCode(data, navigate, true));
            }
        }
    };
    const handleEmailText = (i) => {
        let data = {};
        if (i === "email") {
            setEmailAndPhoneMessage("email");
            data = {
                tokenHash: createdUser.tokenHash,
                password: createdUser.password,
                createPass: createdUser.createPass,
                subdomain: createdUser.subdomain,
                payerEmail: createdUser.payerEmail,
                sendBy: i,
            };

            dispatch(generateAccessCode(data));
        }
        if (i === "phone") {
            if (isSmsSent) {
                setError({
                    toastOpenClose: true,
                    errorMsg: "Please wait 10 sec before attempting again.",
                    type: "danger",
                });
                return;
            }
            setIsSmsSent(true);
            setTimeout(() => {
                setIsSmsSent(false);
            }, 10000);
            setEmailAndPhoneMessage("phone");
            data = {
                tokenHash: createdUser.tokenHash,
                password: createdUser.password,
                createPass: createdUser.createPass,
                subdomain: createdUser.subdomain,
                payerEmail: createdUser.payerEmail,
                sendBy: i,
            };
            dispatch(generateAccessCode(data));
        }
    };

    return (
        <>
            <div className="wrapper">
                <Helmet style={[{cssText: `${globalCustomCSSWithTheme}`}]}/>
                <div className="sos-card-login sos-card">
                    <div className="logo w-100 d-flex justify-content-center">
                        <img
                            src={logoUrl ? logoUrl : "/images/logo.png"}
                            className="img-fluid"
                            alt="Logo"
                        />
                    </div>
                    <div className="sos-content">
                        <div>
              <span className="text-cerulean d-block mb-4 text-cerulean-position text-decoration-underline">
                {`You should have received a code sent to your ${emailAndPhoneMessage}. Please
                      enter that code into the field below.`}
              </span>
                            <form>
                                {map(fieldsList, (i, index) => {
                                    return (
                                        <div key={index}>
                                            <div className="form-group" key={index}>
                                                <label
                                                    htmlFor="exampleInputText"
                                                    className="form-label"
                                                >
                                                    {i.title}
                                                </label>
                                                <input
                                                    type={i.type}
                                                    className="form-control"
                                                    id="codeInputText"
                                                    aria-describedby="codeHelp"
                                                    placeholder={i.placeholder}
                                                    onChange={(e) => handleFields(e, index, i.key)}
                                                    onKeyPress={(e) => handleKeyPress(e, index, i.key)}
                                                />

                                                {i && i.err && (
                                                    <Toast
                                                        isOpen={i.err}
                                                        showClose={false}
                                                        msg={i.errText}
                                                        type={"danger"}
                                                        dur={3000}
                                                    />
                                                )}
                                            </div>
                                            <div className="d-flex mt-3">
                        <span
                            className="text-cerulean d-block mb-4 custom-link"
                            onClick={() => handleEmailText("email")}
                        >
                          Send by email
                        </span>
                                                <span
                                                    to="#"
                                                    className="text-cerulean d-block mb-4 custom-link"
                                                    onClick={() => handleEmailText("phone")}
                                                >
                          Send by text
                        </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </form>
                            {error.toastOpenClose && (
                                <div data-cy="general-code-error">
                                    <Toast
                                        isOpen={error.toastOpenClose}
                                        showClose={false}
                                        msg={error.errorMsg}
                                        type={error.type}
                                    />
                                </div>
                            )}
                            {generatedAccessCode ||
                                (sendCodeSuccess && (
                                    <Toast
                                        isOpen={true}
                                        showClose={false}
                                        msg={`Code sent. Please check your ${emailAndPhoneMessage}.`}
                                        type={"success"}
                                        dur={5000}
                                    />
                                ))}
                            {loginError && (
                                <Toast
                                    isOpen={true}
                                    showClose={false}
                                    msg={`Invalid code. Err e21f1407`}
                                    type={"danger"}
                                    dur={4000}
                                />
                            )}
                            {generatedAccessCodeError && (
                                <Toast
                                    isOpen={true}
                                    showClose={false}
                                    msg={"Something went wrong while sending code"}
                                    type={"danger"}
                                    dur={4000}
                                />
                            )}
                        </div>
                        <div className="d-flex justify-content-end submit-btn">
                            <button
                                type="button"
                                className="sos-custom-btn sos-custom-btn-css"
                                onClick={(e) => handleRegister(e)}
                                disabled={loginLoad}
                            >
                                {withButtonLoader("Submit", loginLoad)}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccessCode;
