import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import IdleTimer from "react-idle-timer";
import { logoutAct, validateJwtTokenExpiry } from "../../Actions/authActions";
import TimeoutModal from "../../Pages/Dashboard/TimeoutPopup";
import config from "../../config";
import {
  eventUrl,
} from "../../utils";

let logoutTimer = null;
 

function ProtectedAdminRoute({ children }) {
  
  const isAuthenticated = useSelector(
    (state) => state.auth.isAuthenticated
  );
  const user = JSON.parse(localStorage.getItem("user")) || {};

  const dispatch = useDispatch();
  let idleTimer = React.createRef();
  const subdomain = window.location.pathname.replace("/", "").split("/")[0];
  const [showModal, setShowModal] = useState(false);

  const MINUTE_MS = 60000;//0;

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(validateJwtTokenExpiry(subdomain));//code runs every 10 minutes
    }, MINUTE_MS);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear our interval to prevent memory leaks.
  }, [])


  const onIdleFunc = () => {
    setShowModal(!showModal);
    logoutTimer = setTimeout(() => {
      dispatch(logoutAct(true, subdomain));
    }, 170400000);
  };

  const handleStayLoggedIn = () => {
    if (logoutTimer) {
      clearTimeout(logoutTimer);
      logoutTimer = null;
    }
    idleTimer.current.reset();
    setShowModal(!showModal);
  };

  const redirect = () => {
    window.location.href = `${eventUrl(subdomain)}admin`;
  }


  return (
    <>
      <IdleTimer
        ref={idleTimer}
        element={document}
        stopOnIdle={true}
        onIdle={onIdleFunc}
        timeout={parseInt(config.USER_INACTIVE)}
      />
      <TimeoutModal
        openModal={showModal}
        onCloseModal={setShowModal}
        handleStayLoggedIn={handleStayLoggedIn}
      />
    
            { (user && Object.keys(user).length > 0) ? (
              children
            ) : (
              //<Navigate to={`${subdomain}/admin`} replace />
              redirect()
            )
        }
    
    </>
  );
}

export default ProtectedAdminRoute;
