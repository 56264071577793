import React from "react";
import { BeatLoader } from "react-spinners";
import { css } from "@emotion/react";

const buttonLoaderCss = css`
  width: 100%;
  height: 17.28px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const withButtonLoader = (text, isLoad, color = "#fff") => {
  if (isLoad) {
    return <BeatLoader css={buttonLoaderCss} color={color} />;
  }
  return text;
};

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getParameterByName = (name, url = window.location.href) => {
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const scrollToElement = (id, dist, type) => {
  const elem = document.getElementById(id);
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  if (elem) {
    const { top } = elem.getBoundingClientRect() || {};
    const elemTop = top + scrollTop;
    window.scrollTo({
      top: type === "bottom" ? elemTop + (dist || 0) : elemTop - (dist || 0),
      behavior: "smooth",
    });
  }
};
