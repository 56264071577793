import React from "react";
import { Modal } from "react-bootstrap";

const TimeoutModal = ({ openModal, handleStayLoggedIn }) => {
  return (
    <Modal
      show={openModal}
      keyboard={false}
      backdrop="static"
      size="md"
      centered
      className="reply-msg-content"
    >
      <Modal.Header className=" justify-content-center">
        Session Timeout
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className=" text-center mt-4">
            Your session will expire in 5 minutes due to inactivity.
          </div>
          <div className="time-out">
            <button
              type="submit"
              name="send"
              className="sos-custom-btn keep-login mt-4 sos-custom-btn-css"
              onClick={handleStayLoggedIn}
            >
              Keep me logged in
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TimeoutModal;
