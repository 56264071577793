import React, {useState, useEffect, useMemo, memo} from "react";
import classname from "classnames";
import "./style.css";

const Toast = ({isOpen, showClose, msg, type, dur, closeCallbackFn, position='relative' }) => {
    const [isClose, setClose] = useState(true);

    const handleClose = () => setClose(true);

    useEffect(() => {
        if (isOpen) {
            setClose(false);
            if (dur) {
                setTimeout(() => {
                    if (closeCallbackFn) {
                        closeCallbackFn();
                    }
                    return handleClose();
                }, dur || 10000);
            }
        }
    }, [isOpen, dur]);

    const toastType = useMemo(() => {
        switch (type) {
            case "success":
                return "success";
            case "danger":
                return "danger";
            default:
                return "success";
        }
    }, [type]);

    if (isClose) {
        return <></>;
    }

    return (
        <div className="toast-container" style={{position: 'relative'}}>
            <p className={classname(`toast-bar toast-bar-${toastType}`)}>
                {msg || ""}
                {showClose && (
                    <span onClick={() => handleClose()}>
            <i className="fa fa-times"/>
          </span>
                )}
            </p>
        </div>
    );
};

export default memo(Toast);
