import React from "react";
import { Modal } from "react-bootstrap";
import "./OfficeContactPopup.css";
import { CrossIcon } from "../../Components/Icons";

const ContactPopup = (props) => {
  const { openModal, onCloseModal, msg } = props;

  return (
    <Modal show={openModal} size="md" centered onHide={onCloseModal}>
      <div onClick={onCloseModal} className="cross-btn">
        <CrossIcon />
      </div>
      <Modal.Body>
        <div className="modal-headr-text-style">{msg}</div>
      </Modal.Body>
    </Modal>
  );
};

export default ContactPopup;
