import {DigitalPen, PrinterIcon, ReadOnlyIcon} from "./Icons";

export const AppropriateFormTypeIcon = ({formType}) => {
    if (formType === "readOnly") {
        return ((<>
            <ReadOnlyIcon
                width={'1em'}
                height={'1em'}/>
            <span className={'ms-2'}><i>Read only document</i></span>
        </>));
    } else if (formType === "printSign") {
        return (<>
            <PrinterIcon
                width={'1em'}
                height={'1em'}/>
            <span className={'ms-2'}><i>Printable form</i></span>
        </>);
    } else if (formType === "eSign") {
        return (
            (<>
                <DigitalPen
                    width={'1em'}
                    height={'1em'}/>
                <span className={'ms-2'}><i>Digital form</i></span>
            </>)
        );
    }
    return (<>Read Only</>);
}

export const formTypeIconMessages = {
    "eSign": "Digital form: e-sign online",
    "printSign": "Printable form: Download, print, sign, upload.",
    "readOnly": "Readable document: no signature required",
};

export const formInstructionsPerType = [{
    formType: "readOnly",
    // patientInstructions: `"Download and read {this/these} {document/documents} or read {it/them} online."`,
    patientInstructions: `"Download and read document(s) or read online."`,
    longPatientInstructions: `Download and read the following document(s) or read them online:`,
    buttonText: "Set as read only document",
    buttonCssClasses: "sos-custom-btn sos-secondary-btn-css",
}, {
    formType: "printSign",
    // patientInstructions: `"Download, print, sign, and upload {this/these} intake {form/forms}."`,
    patientInstructions: `"Download, print, sign, and upload intake form(s)."`,
    longPatientInstructions: `Download, print, sign, and upload the following intake form(s):`,
    buttonText: "Set as printable form",
    buttonCssClasses: "sos-custom-btn sos-secondary-btn-css",
}, {
    formType: "eSign",
    // patientInstructions: `"Sign {this/these} electronic {form/forms} online."`,
    patientInstructions: `"Sign electronic form(s) online."`,
    longPatientInstructions: `Please sign these electronic form(s) online:`,
    buttonText: "Set as digital form; (you will be redirected to add, edit, and save electronic form fields)",
    buttonCssClasses: "sos-custom-btn sos-custom-btn-css fontsize18",
}];
