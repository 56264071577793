import React from "react";
import {withButtonLoader} from "../../../helpers";

export const SoButton = ({isLoading, text, onClick}) => {
    return (
        <button
            type="submit"
            data-cy="sos-login-btn"
            className="sos-custom-btn sos-custom-btn-css so-btn"
            onClick={onClick}
            disabled={isLoading}
        >
            {withButtonLoader(text, isLoading)}
        </button>
    )
}
