import React, {useState, useEffect} from "react";
import "./view-appointment.css";
import {GlobalTheme} from "../../UI/GlobalTheme/global-theme";
import {Logo} from "../../UI/Logo/logo";
import {useSearchParams} from "react-router-dom";
import axios from "axios";
import {useSelector} from "react-redux";

export const ViewAppointment = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const logoUrl = useSelector((state) => state.auth.logoUrl);
    useEffect(async () => {
        const token = searchParams.get("token");
        if (!token) {
            return;
        }
        let data = atob(token);
        data = JSON.parse(data);
        if (!data.videoSession || !data.token) {
            return;
        }
        try {
            let result = await axios.get('video-sessions/join/' + data.videoSession + '?token=' + data.token, {headers: {'micro-service': 'appointment'}});
            const api = new JitsiMeetExternalAPI("8x8.vc", {
                roomName: result.data.room,
                parentNode: document.querySelector('#jaas-container'),
                jwt: result.data.token,
                interfaceConfigOverwrite: {
                    DEFAULT_LOGO_URL: logoUrl,
                    DEFAULT_WELCOME_PAGE_LOGO_URL: logoUrl
                },
                configOverwrite: {
                    prejoinPageEnabled: false,
                    prejoinConfig: {
                        enabled: false
                    },
                    disableLocalVideoFlip: true,
                    startWithAudioMuted: false,
                    startWithVideoMuted: false
                }
            });
        } catch (e) {
            console.error(e);
        }

    }, []);

    return (
        <>
            <GlobalTheme/>
            <div className="wrapper">
                <div className="sos-card-login view-card">
                    <div className="logo w-100 d-flex justify-content-center">
                        <Logo/>
                    </div>
                    <div id="jaas-container"/>
                </div>
            </div>
        </>
    );
};
