import {withButtonLoader} from "../../helpers";
import ContactPopup from "../../Pages/OfficeContactPopup";
import React from "react";

const CreateNewMessage = (props) => {
    const {
        titleText = "Contact the Office",
        subtitleText = "Your message will be sent to our office.",
        handleSubmitMessage,
        sendLoader,
        isOpenReply,
        errorMsg,
        toggleReplyPopup,
        contactOfficeMessage,
        handleContactOfficeMessage,
    } = props;

    return (<div className="homeexisting-patient-box account-card position-relative m-auto mt-2 mb-5">
            <div className="hommeexisting-large-text hommeexisting-hoiz-text">
                {titleText}
            </div>
            <div className="hommeexisting-mask-inner">
                <div className="mb-3">
                      <textarea
                          className="form-control custom-input-border-css"
                          id="exampleFormControlTextarea1"
                          data-cy="formControlTextarea1"
                          rows={3}
                          placeholder="Message*"
                          value={contactOfficeMessage}
                          onChange={(e) => handleContactOfficeMessage(e)}
                      />
                </div>
                <div className="hommeexisting-large-text-inner">
                    <h6>{subtitleText}</h6>
                </div>
            </div>
            <div className="hommeexisting-img">
                <img src="/images/email-icon.png"/>
            </div>
            <div className="d-flex justify-content-end paymet-btn">
                <button
                    type="submit"
                    data-cy="send-message-btn"
                    className="sos-custom-btn sos-custom-btn-css"
                    onClick={(e) => {
                        handleSubmitMessage(e);
                    }}
                    disabled={sendLoader}
                >
                    {withButtonLoader("Send Message", sendLoader)}
                </button>
            </div>
            <ContactPopup
                openModal={isOpenReply}
                msg={errorMsg}
                onCloseModal={toggleReplyPopup}
            />
        </div>)
}
export default CreateNewMessage;
