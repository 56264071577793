import React from "react";
import {Modal} from "react-bootstrap";
import moment from "moment";
import {CrossIcon} from "../../Components/Icons";
import "./historyPopup.css";

const HistoryIconPopup = (props) => {
    const {openModal, onCloseModal, history} = props;

    return (
        <Modal show={openModal} size="md" centered className="reply-msg-content">
            <div onClick={() => onCloseModal(false)} className="close-modal-btn">
                <CrossIcon/>
            </div>
            <Modal.Body>
                <div>
                    <div className="p-4">
                        <h3 className="text-center">Signature History</h3>
                        <div className="table-responsive mt-4">
                            <table className="table table-bordered border-dark mb-0 signature-history">
                                <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Type</th>
                                </tr>
                                </thead>
                                <tbody>
                                {history?.map((data, index) => (
                                    <tr key={index}>
                                        <td>
                                            {moment(data.date).format(
                                                "MM/DD/YYYY"
                                            )}
                                        </td>
                                        <td>
                                            {data.action === 'Signed'
                                                ? "Electronically signed"
                                                : "Uploaded"}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default HistoryIconPopup;
