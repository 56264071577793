import React from "react";
import { Modal } from "react-bootstrap";
import { CrossIcon } from "../Icons";

const customePopUp = (props) => {
  const { openModal, onCloseModal, message } = props;
  return (
    <Modal show={openModal} size="md" centered className="update-successfully">
      <div data-cy="modal-pop-up" onClick={() => onCloseModal(false)} className="close-modal-btn">
        <CrossIcon />
      </div>
      <Modal.Body>
        <div className="modal-headr-text">
          <p>{message}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default customePopUp;
