import {getUserData, onTransactionLoading, transactionError} from "../Actions/authActions";
import get from "lodash/get";
import map from "lodash/map";
import {amountFormatterWithZero} from "../utils";
import {useDispatch} from "react-redux";
import {postManualPayment} from "./postManualPayment.js";

export const handleSubmit = async ({
                                       target,
                                       paymentInfo,
                                       paymentType,
                                       patientPayerInfo,
                                       totalAmountsPaid,
                                       user,
                                       adminUser,
                                       subdomain,
                                       isPaymentSaveDetails,
                                       isDefault,
                                       accountType,
                                       manual,
                                       showResult,
                                       runTransactionLoading,
                                       runGetUserData,
                                       runPayUserSuccess,
                                       runTransactionError,
                                       savePaymentsData,
                                       setPaymentInfo,
                                       dispatch,
                                        oldData
                                   }) => {

    runTransactionLoading();
    let formData;
    if (target.elements) {
        formData = [...target.elements]
            .filter((d) => d.name)
            .reduce((acc, d) => {
                if (["isPaymentSaveDetails", "isDefault"].includes(d.name)) {
                    acc[d.name] = d.value === "on";
                } else {
                    acc[d.name] = d.value;
                }
                return acc;
            }, {});

    } else {
        formData = paymentInfo;
    }
    const payerEmail = get(user, "payerEmail") || "";
    const patientPayer_info = [];
    map(patientPayerInfo, (i) => {
        patientPayer_info.push({
            patientFirst: i.patientFirst, patientLast: i.patientLast,
            patientNumber: i.patientNumber, patientPayerId: i.patientPayerId, amountPaid: i.accountBalance,
        });
    });
    let data = {
        amount: amountFormatterWithZero(totalAmountsPaid()),
        payerUser: user,
        adminUser,
        payerEmail,
        subdomain,
        isPaymentSaveDetails,
        isDefault,
        patientPayer: patientPayer_info
    };
    if (paymentType === "eCheck") {
        const isPersonalAccount = accountType === "0";
        const isBusinessAccount = accountType === "1";
        data = {
            ...data,
            isEcheck: true,
            firstName: isPersonalAccount ? formData.accountFirstName : "",
            lastName: isPersonalAccount ? formData.accountLastName : "",
            companyName: isBusinessAccount ? formData.companyName : "",
            bankAccountType: accountType,
            bankRoutingNumber: formData.bankRoutingNumber,
            bankAccountNumber: formData.bankAccountNumber,
            bankName: formData.bankName
        }
    } else {
        data = {
            ...data,
            isEcheck: false,
            firstName: formData.cardFirstName || "",
            lastName: formData.cardLastName || "",
            expirationDate: formData && formData.expiry ? formData.expiry.replace(/\//g, "") : "",
            cardNumber: formData && formData.cardnumber,
            cvv: formData && formData.cvc ? formData.cvc : ""
        };
    }
    manual.data = data;
    // manual.pay(e).then((res) => {
    manual.pay({}).then((res) => {
        if (!(data.isEcheck && data.isPaymentSaveDetails && +res.ssl_amount > 0.01)) {
            const postManual = {
                response: res,
                paymentType,
                patientPayerInfo,
                user,
                adminUser,
                isPaymentSaveDetails,
                isDefault,
                setPaymentInfo,
                showResult,
                runGetUserData,
                dispatch,
                oldData
            }
            postManualPayment(postManual);
        } else if (data.isEcheck && data.isPaymentSaveDetails) {
            if (res.data.message === "Transaction successful") {//do we need  || res.ssl_result==="0" ?
                showResult("approval");
                setTimeout(() => {
                    runGetUserData();
                    runPayUserSuccess();
                    setPaymentInfo({
                        name: "", cardnumber: "", expiry: "", cvv: "",
                    });
                }, 1000);
            } else {
                showResult("failure", err);
            }
            runTransactionError();
        }

    }).catch((err) => {
        console.error(err);
        console.error("Err #bbfhdb3h", err);
        showResult("error", err);
        runTransactionError();
    });
};
