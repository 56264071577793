import React from "react";

const Support = () => {
  return (
    <>
      <div className="wrapper">
        <div className="sos-card">
          <div className="logo w-100 d-flex justify-content-center">
            <img src="/images/logo.png" className="img-fluid" alt="Logo" />
          </div>
          <div className="sos-content">
            <div>
              <div className="text-support mb-4 ">
                Thank you for your interest in Sosoft Pay.
              </div>
              <div className="text-support mb-4">
                To log in to your account, please go to the website 
                URL you received in your email or in your account statement.
              </div>
              <div className="text-support mb-4">
                For assistance, please email
                <a href="#"> support@sosoftpay.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
