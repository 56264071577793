import React from "react";
import classnames from "classnames";
import "./style.css";

const CustomCheckbox = ({
                            name,
                            className,
                            label,
                            checked,
                            disabled,
                            ...rest
                        }) => {
    return (
        <>
            <label className={`custom-checkbox ${disabled ? "disabled" : ""}`}>
                <input
                    {...rest}
                    className={classnames("hide", {[className]: className})}
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    name={name}
                />

                {label || ""}
                <span className={"custom-checkbox-checked"}></span>

            </label>
        </>
    );
};

export default CustomCheckbox;
