import map from "lodash/map";
import {savePaymentsData} from "../Actions/authActions";

export const postManualPayment = async function ({
                                                     response,
                                                     paymentType,
                                                     patientPayerInfo,
                                                     user,
                                                     adminUser,
                                                     isPaymentSaveDetails,
                                                     isDefault,
                                                     setPaymentInfo,
                                                     showResult,
                                                     runGetUserData,
                                                     dispatch,
                                                     oldData
                                                 }) {


    try {
        let patientPayer_info = [];
        map(patientPayerInfo, (i) => {
            patientPayer_info.push({
                patientFirst: i.patientFirst, patientLast: i.patientLast,
                patientNumber: i.patientNumber, patientPayerId: i.patientPayerId, amountPaid:  i.accountBalance < 0 ? 0 : i.accountBalance,
            });
        });
        let paymentsDataToSave;
        if (paymentType === "eCheck") {
            const transactionId = response?.ssl_txn_id || "";
            const ssl_bank_account_number = response.response?.ssl_bank_account_number?.substr(-4) || "";

            const ssl_card_short_description = response?.ssl_card_short_description || "";
            const ssl_card_type = response?.response.ssl_card_type || "";

            paymentsDataToSave = {
                paymentType,
                ssl_card_short_description,
                ssl_card_type,
                ssl_bank_account_number,
                userId: user && user.id,
                transactionsData: {...response.response, bankName: response.bankName},
                transactionId,
                sosCustomersId: adminUser.id,
                patientPayer: patientPayer_info,
                isDefault: isDefault,
                isPaymentSaveDetails: isPaymentSaveDetails,
                isAdmin: false,
                addedBy: {userId: user.id},
                initiatedBy: {userId: user.id},
                subdomain: user.subdomain,
                oldData
            };
        } else {
            const ssl_card_number = response?.ssl_card_number?.substr(-4) || "";
            const transactionId = response?.ssl_txn_id || "";
            const ssl_token = response?.ssl_token || "";

            const ssl_card_short_description = response?.ssl_card_short_description || "";
            const ssl_card_type = response?.response.ssl_card_type || "";


            paymentsDataToSave = {
                paymentType,
                ssl_card_short_description,
                ssl_card_type,
                userId: user.id,
                transactionsData: response,
                transactionId,
                ssl_card_number, // dateTimeStampUTC: new Date().toISOString(),
                ssl_token,
                sosCustomersId: adminUser.id,
                patientPayer: patientPayer_info,
                isDefault,
                isPaymentSaveDetails,
                isAdmin: false,
                addedBy: {userId: user.id},
                initiatedBy: {userId: user.id},
                subdomain: user.subdomain,
            };
        }

        const res = await savePaymentsData(paymentsDataToSave)(dispatch);
        if (res.message === "Transaction successful") {
            showResult("approval");
        } else {
            showResult("failure", err);
        }

        setPaymentInfo({
            name: "", cardnumber: "", expiry: "", cvv: "",
        });
    } catch (err) {
        console.error(err);
        console.trace();
        console.log("Err #3iu53urg", err.message);
        showResult("error", err);
    }
    if (user) {
        runGetUserData();
    }
}

