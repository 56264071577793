// import { Popover } from "bootstrap";
import classNames from "classnames";
import React from "react";
import { Overlay, Popover } from "react-bootstrap";
import "./popover.css";

const CustomPopover = ({
  title = "",
  show,
  target,
  onRemove,
  close,
  setShowPopUp,
  flag = false,
}) => {
  return (
    <>
      <div>
        <Overlay
          show={show}
          target={target}
          placement="bottom"
          trigger="click"
          rootClose
          onHide={() => setShowPopUp(!show)}
        >
          <Popover
            id="doc"
            className={classNames(
              "mt-2",
              flag ? "custom-popup-modal" : "popOverCustome"
            )}
          >
            <Popover.Header className="bg-white border-bottom-0">
              {title}
            </Popover.Header>
            <Popover.Body className="popover-button">
              <button
                className="custom-popover-button close-btn sos-custom-btn-css"
                onClick={close}
              >
                Cancel
              </button>
              <button
                className="custom-popover-button sos-custom-btn-css"
                onClick={onRemove}
              >
                Remove
              </button>
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
    </>
  );
};

export default CustomPopover;
