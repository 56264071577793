import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import get from "lodash/get";
import {withButtonLoader} from "../../helpers";
import {
    getPaymentsToken,
    getAdminUserData,
    getPayerUserById,
    savePaymentsDataAdmin, transactionError, onTransactionLoading,
} from "../../Actions/authActions";

import {
    formatCreditCardNumber,
    formatExpirationDate,
    formatCVC,
} from "../Payments/utils";
import PaymentSuccessPopup from "../Payments/paymentSuccessPopup";
import CustomCheckbox from "../../Components/CustomCheckBox";
import "../Payments/payments.css";
import "./addcard.css";
import {CrossIcon, DownArrowIcon} from "../../Components/Icons";
import {PaymentFactory} from "../../Payments/paymentFactory";
import {SAVE_TRANSACTION_LOADING, SAVE_TRANSACTION_ERROR, SAVE_TRANSACTION_SUCCESS} from "../../constants";
import CardExpiration from "../../Components/Forms/CardExpiration";
import {showPaymentError} from "../../Payments/errorHandler";

const AdminAddCardInfo = (props) => {
    const {
        userAdmin,
        subdomain,
        paymentMethodData,
        setAddEditSwipeMode,
        payerUser,
        handleSwipeAddCardInfo,
    } = props;


    const dispatch = useDispatch();
    const adminUser = useSelector((state) => state.auth.adminUser);
    const isTransactionLoading = useSelector((state) => state.auth.transactionLoading);
    const [isPaymentSaveDetails, setIsPaymentSaveDetails] = useState(true);
    const [paymentType, setPaymentType] = useState(paymentMethodData.paymentType);
    const [openModal, setOpenModal] = useState(false);
    const [paymentMsg, setPaymentMsg] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    const [isDefault, setIsDefault] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState({
        name: "",
        amount: "",
        cardnumber: "",
        expiry: "",
        cvv: "",
    });
    const [accountType, setAccountType] = useState("");
    const [manual, setManual] = useState(null);

    const postManualPayment = (response) => {
        dispatch({type: SAVE_TRANSACTION_LOADING});
        let paymentsDataToSave = {};

        if (paymentType === "eCheck") {
            const transactionId = response?.transactionId || "";
            const ssl_bank_account_number = response.response?.ssl_bank_account_number?.substr(-4) || "";
            const ssl_card_short_description = response?.ssl_card_short_description || "";
            const ssl_card_type = response?.response.ssl_card_type || "";


            paymentsDataToSave = {
                paymentType: "eCheck",
                ssl_card_short_description,
                ssl_card_type,
                ssl_recurring_id: response.ssl_recurring_id,
                ssl_bank_account_type: response.ssl_bank_account_type,
                ssl_bank_account_number: response.ssl_bank_account_number?.substr(-4) || "",
                ssl_company: response.ssl_company,
                ssl_last_name: response.ssl_last_name,
                ssl_first_name: response.ssl_first_name,
                userId: payerUser && payerUser.id,
                transactionsData: {...response.response, bankName: response.bankName},
                transactionId,
                sosCustomersId: userAdmin.id,
                patientPayer: payerUser.patientPayer,
                isDefault,
                isPaymentSaveDetails,
                isAdmin: false,
                addedBy: {sosCustomersId: userAdmin.id},
                initiatedBy: {sosCustomersId: userAdmin.id},
                subdomain,
            };
        } else {
            const ssl_card_number = response?.ssl_card_number?.substr(-4) || "";
            const transactionId = response?.ssl_txn_id || "";
            const ssl_token = response?.ssl_token || "";
            const ssl_card_short_description = response?.ssl_card_short_description || "";
            const ssl_card_type = response.response?.ssl_card_type || "";

            paymentsDataToSave = {
                paymentType, //eCheck, creditCard, hhaCard
                ssl_card_short_description,
                ssl_card_type,
                ssl_first_name: response.firstName,
                ssl_last_name: response.lastName,
                userId: payerUser && payerUser.id,
                transactionsData: response.response,
                transactionId,
                ssl_card_number,
                ssl_token,
                // dateTimeStampUTC: new Date(),
                sosCustomersId: userAdmin.id,
                // patientPayer: balancesPaid,
                patientPayer: payerUser.patientPayer,
                isDefault,
                isPaymentSaveDetails,
                isAdmin: true,
                addedBy: {sosCustomersId: userAdmin.id},
                initiatedBy: {sosCustomersId: userAdmin.id},
                subdomain,
            };
        }
        try {
            dispatch(savePaymentsDataAdmin(paymentsDataToSave, dispatch)).then((res) => {
                if (res) {
                    showResult("approval");
                    setTimeout(() => {
                        dispatch(getPayerUserById(payerUser));
                        dispatch({type: SAVE_TRANSACTION_SUCCESS});
                        setAddEditSwipeMode("");
                    }, 1000);
                } else {
                    showResult("error", response);
                    dispatch({type: SAVE_TRANSACTION_ERROR});
                }
            });
        } catch (err) {
            showResult("error", err);
            console.log("Err #hueh2o30", err);
            dispatch({type: SAVE_TRANSACTION_ERROR});
        }
    }

    const initManualPayment = () => {
        const factory = new PaymentFactory();
        const newManual = factory.create('MANUAL', {});
        setManual(newManual);
    }

    useEffect(() => {
        dispatch(getAdminUserData(subdomain));
        initManualPayment();
    }, [!adminUser]);

    function showResult(status, err) {
        if (status === "approval") {
            setPaymentMsg("addSuccessful");
        } else {
            setPaymentMsg(`${showPaymentError(err)}`);
        }
        setPaymentStatus(status);
        setOpenModal(true);
    }

    const onCloseModal = () => {
        if (paymentStatus === "approval") {
            setOpenModal(false);
            return false;
        } else {
            setOpenModal(false);
        }
    };

    const handleInputChange = ({target}) => {
        if (target.name === "cardnumber") {
            target.value = formatCreditCardNumber(target.value);
        } else if (target.name === "expiry") {
            target.value = formatExpirationDate(target.value);
        } else if (target.name === "cvc") {
            target.value = formatCVC(target.value);
        } else if (target.name === "accountType") {
            setAccountType(target.value)
        }
        setPaymentInfo({...paymentInfo, [target.name]: target.value});
    };

    const handleSubmit = async (e) => {
        dispatch({type: SAVE_TRANSACTION_LOADING});
        let formData = [...e.target.elements]
            .filter((d) => d.name)
            .reduce((acc, d) => {
                acc[d.name] = d.value;
                return acc;
            }, {});

        const payerEmail = get(payerUser, "payerEmail") || "";

        let data = {
            amount: 0,
            payerEmail,
            subdomain,
        };

        if (paymentType === "eCheck") {
            const isPersonalAccount = formData.accountType === "0";
            const isBusinessAccount = formData.accountType === "1";
            data = {
                ...data,
                isEcheck: true,
                firstName: isPersonalAccount ? formData.accountFirstName : "",
                lastName: isPersonalAccount ? formData.accountLastName : "",
                companyName: isBusinessAccount ? formData.companyName : "",
                bankAccountType: formData.accountType,
                bankName: formData.bankName,
                bankRoutingNumber: formData.bankRoutingNumber,
                bankAccountNumber: formData.bankAccountNumber,
                isPaymentSaveDetails: isPaymentSaveDetails,
            }
        } else {
            data = {
                ...data,
                isEcheck: false,
                firstName: formData.cardFirstName || "",
                lastName: formData.cardLastName || "",
                expirationDate: formData && formData.expiry ? formData.expiry.replace(/\//g, "") : "",
                cardNumber: formData && formData.cardnumber,
                cvv: formData && formData.cvc ? formData.cvc : "",
                isPaymentSaveDetails: isPaymentSaveDetails,
            };
        }
        manual.data = data;
        manual.pay(e).then((res) => {
            postManualPayment(res);
            dispatch({type: SAVE_TRANSACTION_SUCCESS});
        }).catch((err) => {
            showResult("error", err);
            console.log("err #ansj3j2k");
            console.log(err);
            dispatch({type: SAVE_TRANSACTION_ERROR});
        });
    }

    return (
        <>
            <div className="cross-btn" onClick={() => {
                setAddEditSwipeMode("");
            }}>
                <CrossIcon/>
            </div>
            <div
                className="creditCard"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
            >
                <div className="sos-content edit-payment-header payment-inner-toggleable">
                    <div className="edit-card-info mb-5">
                        <h3>Add Payment Method</h3>
                        {paymentType !== "eCheck" ? (
                            <button
                                type="button"
                                onClick={(e) => handleSwipeAddCardInfo(e)}
                                className="toggle-manual-swipe add-card-btn toggle-manual-swipe"
                            >
                                Swipe
                            </button>
                        ) : ""}
                    </div>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        {paymentType !== "eCheck" ? (
                            <>
                                <div className="form-group mb-3">
                                    <label
                                        htmlFor="exampleInputEmail1"
                                        className="form-label payment-form-label"
                                    >
                                        Cardholder Name
                                    </label>
                                    <div className="row g-2">
                                        <div className="col-md">
                                            <div className="payment-controlpanl-style">
                                                <input
                                                    type="text"
                                                    name="cardFirstName"
                                                    className="form-control custom-input-border-css"
                                                    id="exampleInputName3"
                                                    aria-describedby="nameHelp"
                                                    placeholder="First Name"
                                                    onChange={(e) => handleInputChange(e)}
                                                    // value={paymentMethodData?.ssl_first_name}
                                                    required
                                                />

                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div>
                                                <input
                                                    type="text"
                                                    name="cardLastName"
                                                    className="form-control custom-input-border-css"
                                                    id="exampleInputName3"
                                                    aria-describedby="nameHelp"
                                                    placeholder="Last Name"
                                                    onChange={(e) => handleInputChange(e)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label
                                        htmlFor="exampleInputPassword1"
                                        className="form-label payment-form-label"
                                    >
                                        Credit Card Number
                                    </label>
                                    <input
                                        type="tel"
                                        name="cardnumber"
                                        className="form-control custom-input-border-css bg-white"
                                        id="cardnumber"
                                        placeholder={`**** **** **** ****`}
                                        pattern="[\d| ]{15,22}"
                                        required
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className="row g-2">
                                    <div className="col-md">
                                        <div className="payment-controlpanl-style">
                                            <div>
                                                <label className="payment-form-label">
                                                    Expiry Date
                                                </label>
                                            </div>
                                            <CardExpiration
                                                onChange={(e) => handleInputChange(e)}
                                            ></CardExpiration>
                                            {/*<input*/}
                                            {/*    type="tel"*/}
                                            {/*    className="form-control custom-input-border-css bg-white"*/}
                                            {/*    id="expiry"*/}
                                            {/*    placeholder="mm/yy"*/}
                                            {/*    name="expiry"*/}
                                            {/*    pattern="\d\d/\d\d"*/}
                                            {/*    required*/}
                                            {/*    onChange={(e) => handleInputChange(e)}*/}
                                            {/*/>*/}
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div>
                                            <div>
                                                <label className="payment-form-label">
                                                    Security Code
                                                </label>
                                            </div>
                                            <input
                                                type="tel"
                                                className="form-control custom-input-border-css bg-white"
                                                placeholder="Enter CVC"
                                                name="cvc"
                                                pattern="\d{3,4}"
                                                required
                                                onChange={(e) => handleInputChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="checkbox-list mt-3">
                                    <label className="label-check font-weight-light ">
                                        <CustomCheckbox defaultChecked/>
                                        <span>Save these payment details for future payments</span>
                                    </label>
                                    <div className="payment-checbox">
                                        <label className="font-weight-light label-check ml-xl-5">
                                            <CustomCheckbox
                                                onChange={(e) => setIsDefault(e.target.checked)}
                                                defaultChecked={paymentMethodData?.isDefault || false}
                                            />
                                            <span className="word-break: break-word">Set as default payment method</span>
                                        </label>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="dropdown custom-dropdown m-b-20">
                                    <select
                                        name="accountType"
                                        className="form-control bg-white"
                                        required
                                        onChange={(e) => handleInputChange(e)}>
                                        <option selected value="">
                                            Account Type
                                        </option>
                                        <option value="0">Personal</option>
                                        <option value="1">Business</option>
                                    </select>
                                    <div className="down-arrow">
                                        <DownArrowIcon/>
                                    </div>
                                </div>
                                {accountType === "0" ?
                                    (
                                        <div className="form-group mb-3">
                                            <label
                                                htmlFor="exampleInputBankName1"
                                                className="form-label payment-form-label"
                                            >
                                                Name On Account
                                            </label>
                                            <div className="row g-2">
                                                <div className="col-md">
                                                    <div className="payment-controlpanl-style">
                                                        <input
                                                            type="text"
                                                            name="accountFirstName"
                                                            className="form-control custom-input-border-css"
                                                            id="exampleInputName3"
                                                            aria-describedby="nameHelp"
                                                            placeholder="First Name"
                                                            onChange={(e) => handleInputChange(e)}
                                                            required
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md">
                                                    <div>
                                                        <input
                                                            type="text"
                                                            name="accountLastName"
                                                            className="form-control custom-input-border-css"
                                                            id="exampleInputName3"
                                                            aria-describedby="nameHelp"
                                                            placeholder="Last Name"
                                                            onChange={(e) => handleInputChange(e)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="form-group mb-3">
                                            <label
                                                htmlFor="exampleInputBankName1"
                                                className="form-label payment-form-label"
                                            >
                                                Name On Account
                                            </label>
                                            <input
                                                type="text"
                                                name="companyName"
                                                required
                                                className="form-control custom-input-border-css"
                                                id="exampleInputName1"
                                                aria-describedby="nameHelp"
                                                placeholder="Enter Name on the Account"
                                            />

                                        </div>
                                    )}
                                <div className="form-group mb-3">
                                    <label
                                        htmlFor="exampleInputBankName1"
                                        className="form-label payment-form-label"
                                    >
                                        Bank Name
                                    </label>
                                    <input
                                        type="text"
                                        name="bankName"
                                        required
                                        className="form-control bg-white"
                                        id="exampleInputBankName"
                                        aria-describedby="bankNameHelp"
                                        placeholder="Enter Bank Name"
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label
                                        htmlFor="exampleInputBankNumber1"
                                        className="form-label payment-form-label"
                                    >
                                        Bank Routing Number
                                    </label>
                                    <input
                                        type="text"
                                        required
                                        className="form-control bg-white"
                                        name="bankRoutingNumber"
                                        id="exampleInputBankNumber1"
                                        placeholder="Enter Bank Routing Number"
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label
                                        htmlFor="exampleInputAccountNumber1"
                                        className="form-label payment-form-label"
                                    >
                                        Bank Account Number
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control bg-white"
                                        id="exampleInputAccountNumber1"
                                        required
                                        name="bankAccountNumber"
                                        placeholder="Enter Bank Account Number"
                                    />
                                </div>
                                <div className="checkbox-list mt-3">
                                    <label className="label-check font-weight-light float-end">
                                        <CustomCheckbox
                                            checked={isPaymentSaveDetails}
                                            onChange={(e) =>
                                                setIsPaymentSaveDetails(e.target.checked)
                                            }
                                        />
                                        Save these payment details for future payments
                                    </label>
                                    <div className="payment-checbox">
                                        <label className="font-weight-light label-check ml-xl-5">
                                            <CustomCheckbox
                                                name="isDefault"
                                                onChange={(e) => setIsDefault(e.target.checked)}
                                            />
                                            Set as default payment method
                                        </label>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="checkbox-list mt-3">
                            <div className="d-flex justify-content-center">
                                <button
                                    type="submit"
                                    className="save-payment-btn sos-custom-btn-css"
                                >
                                    {withButtonLoader("Save Payment Information", isTransactionLoading)}
                                    {/* "#6e86c4" */}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* <div className="hommeexisting-img-edit">
        <img src="/images/payment-icon.png" />
      </div> */}
            <PaymentSuccessPopup
                openModal={openModal}
                paymentMsg={paymentMsg}
                onCloseModal={onCloseModal}
            />
        </>
    );
};

export default AdminAddCardInfo;
