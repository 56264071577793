import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyAdminEmailChange } from "../../Actions/authActions";
import { withButtonLoader } from "../../helpers";
import { subDomain, eventUrl } from "../../utils";
import config from "../../config";

const VerifyAdminEmailChange = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [isEmailVerified, setIsEmailVerified] = useState(null);

  const subdomain = params.subdomain || subDomain();
  useEffect(() => {
    const subdomain = params.subdomain || subDomain();

    if (params && params.tokenHash) {
      const { tokenHash } = params || "";
      dispatch(verifyAdminEmailChange(tokenHash, subdomain, navigate, setIsEmailVerified));
    } else {
      window.location.href = `${eventUrl(subdomain)}admin`;
    };
  }, []);

  const goToAdmin = (e) => {
    e.preventDefault();
    window.location.href = `${eventUrl(subdomain)}admin`;
  }

  return (<div className="pt-5 text-center">
    {isEmailVerified === null && (<div>
      {withButtonLoader("Loading.....", true, "#000")}
    </div>)}
    {isEmailVerified !== null && (
      <>
        <div className="wrapper">
          <div className="sos-card">
            <div className="logo w-100 d-flex justify-content-center">
              <img src="/images/logo.png" className="img-fluid" alt="Logo" />
            </div>
            <div className="sos-content">
              <div>
                {/* <label className="text-support mb-4 "> */}
                <label className="form-label">
                  {`Admin email address change ${isEmailVerified === false ? 'not' : ''} successfully verified`}
                </label>
                <div className="mb-2" />
                {isEmailVerified === false ? 'For security reasons, admin email change request must be verified within a few minutes' : ''}
                <div className="mb-3" />
                <div className="text-support mb-4">
                  <button
                    className="sos-custom-btn sos-custom-btn-css"
                    // style={{ backgroundColor: 'transparent', borderStyle: 'none' }} 
                    onClick={goToAdmin}>Log in</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )}
  </div>
  );
};

export default VerifyAdminEmailChange;
