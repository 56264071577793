import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import {useLocation} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import get from "lodash/get";
import {withButtonLoader} from "../../helpers";
import {
    getPaymentsToken,
    getAdminUserData,
    getPayerUserById,
    savePaymentsDataAdmin,
} from "../../Actions/authActions";

import {
    formatCreditCardNumber,
    formatExpirationDate,
    formatCVC,
} from "../Payments/utils";
import PaymentSuccessPopup from "../Payments/paymentSuccessPopup";
import CustomCheckbox from "../../Components/CustomCheckBox";
import "../Payments/payments.css";
import "./swipe.css";
import {CrossIcon, DownArrowIcon} from "../../Components/Icons";
import {PaymentFactory} from "../../Payments/paymentFactory";
import {showPaymentError} from "../../Payments/errorHandler";

const AdminPaymentAddSwipe = (props) => {

    const {
        userAdmin,
        subdomain,
        paymentMethodData,
        setAddEditSwipeMode,
        payerUser,
    } = props;

    const dispatch = useDispatch();
    const location = useLocation();
    const adminUser = useSelector((state) => state.auth.adminUser);
    const isPayerLoading = useSelector((state) => state.auth.isPayerLoading);
    const [isPaymentSaveDetails, setIsPaymentSaveDetails] = useState(true);
    const [paymentType, setPaymentType] = useState("creditCard");
    const [openModal, setOpenModal] = useState(false);
    const [paymentMsg, setPaymentMsg] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    const [isDefault, setIsDefault] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState({
        name: "",
        amount: "",
        cardnumber: "",
        expiry: "",
        cvv: "",
    });
    const [accountType, setAccountType] = useState("");

    useEffect(() => {
        dispatch(getAdminUserData(subdomain));
    }, [!adminUser]);


    const handleAddCardInfo = props.handleAddCardInfo;

    const [lastFourNumbers, setLastFourNumbers] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [disguisedSwipeNumber, setDisguisedSwipeNumber] = useState("");
    const [swiper, setSwiper] = useState(null);


    useEffect(() => {
        const factory = new PaymentFactory();
        const newSwiper = factory.create('SWIPE', {
            subdomain,
            adminUser,
            payerUser,
            amount: 0
        });
        newSwiper.init();
        setSwiper(newSwiper);
        document.addEventListener("cardSwiped", (e) => {
            setFirstName(e.detail.firstName);
            setLastName(e.detail.lastName);
            setLastFourNumbers(e.detail.lastFourNumbers);
            setDisguisedSwipeNumber(e.detail.disguisedSwipeNumber);
        });
        return () => {
            if (newSwiper) {
                newSwiper.destroy();
            }
        };
    }, []);


    function showResult(status, err) {
        if (status === "approval") {
            setPaymentMsg("successful");
            setPaymentStatus("approval");
        } else if (status === "txnApprovedButPmtMethodNotStored") {
            setPaymentMsg("Transaction successful. Unable to store payment method at this time.");
            setPaymentStatus("approval");
        } else if (status === "failure" || status === "error") {
            setPaymentMsg(`${showPaymentError(err)}`);
            setPaymentStatus("error");
        }
        setOpenModal(true);
    }

    const onCloseModal = () => {
        if (paymentStatus === "approval") {
            setOpenModal(false);
            return false;
        } else {
            setOpenModal(false);
        }
    };

    const handleInputChange = ({target}) => {
        if (target.name === "cardnumber") {
            target.value = formatCreditCardNumber(target.value);
        } else if (target.name === "expiry") {
            target.value = formatExpirationDate(target.value);
        } else if (target.name === "cvc") {
            target.value = formatCVC(target.value);
        } else if (target.name === "accountType") {
            setAccountType(target.value)
        }
        setPaymentInfo({...paymentInfo, [target.name]: target.value});
    };


    const handlePay = async (e) => {
        swiper.data.isDefault = isDefault;
        swiper.data.isDefault = isDefault;
        let res = await swiper.pay(e);
        if (res.data.message === 'Transaction successful') {
            showResult("approval");
            setTimeout(() => {
                dispatch(getPayerUserById(payerUser));
                dispatch({type: "PAYUSER_SUCCESS"});
                setAddEditSwipeMode("");
            }, 1000);
        } else {
            showResult("error", res.data);
        }
    }

    return (
        <>


            <div className="cross-btn" onClick={() => {
                setAddEditSwipeMode("");
            }}>
                <CrossIcon/>
            </div>
            <div
                className="creditCard"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
            >

                <div className="sos-content edit-payment-header payment-inner-toggleable">
                    <div id="parentForSwipeInput"></div>

                    <div className="edit-card-info mb-5">
                        <h3>Swipe Credit/Debit Card</h3>
                        <button
                            type="button"
                            onClick={(e) => handleAddCardInfo(e)}
                            className="add-card-btn toggle-manual-swipe"
                        >
                            Manual Entry
                        </button>
                        <p style={{fontSize: "16px", fontWeight: 500}}>Swiping will fill in the fields below</p>
                    </div>

                    {/* <form onSubmit={(e) => handleSubmit(e)}> */}
                    <form onSubmit={(e) => handlePay(e)}>
                        {paymentType !== "eCheck" ? (
                            <>
                                {/* {lastFourNumbers && <> */}
                                {<>

                                    <div className="form-group mb-3">

                                        <label
                                            htmlFor="exampleInputEmail1"
                                            className="form-label payment-form-label"
                                        >
                                            Cardholder Name
                                        </label>


                                        <div className="row g-2">
                                            <div className="col-md">
                                                <div className="payment-controlpanl-style">
                                                    <input
                                                        type="text"
                                                        name="cardFirstName"
                                                        className="form-control custom-input-border-css"
                                                        id="exampleInputName3"
                                                        aria-describedby="nameHelp"
                                                        placeholder="First Name"
                                                        onChange={(e) => handleInputChange(e)}
                                                        value={firstName || ""}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-md">
                                                <div>
                                                    <input
                                                        type="text"
                                                        name="cardLastName"
                                                        className="form-control custom-input-border-css"
                                                        id="exampleInputName3"
                                                        aria-describedby="nameHelp"
                                                        placeholder="Last Name"
                                                        onChange={(e) => handleInputChange(e)}
                                                        value={lastName || ""}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group mb-3">
                                        <label
                                            htmlFor="exampleInputPassword1"
                                            className="form-label payment-form-label"
                                        >
                                            Credit Card Number
                                        </label>
                                        <input
                                            type="tel"
                                            name="cardnumber"
                                            className="form-control custom-input-border-css bg-white"
                                            id="cardnumber"
                                            placeholder={`**** **** **** ****`}
                                            // pattern="[\d| ]{15,22}"
                                            required
                                            onChange={(e) => handleInputChange(e)}
                                            value={lastFourNumbers ? disguisedSwipeNumber : ""}
                                        />
                                    </div>


                                    <div className="checkbox-list mt-3">
                                        <label className="label-check font-weight-light ">
                                            <CustomCheckbox defaultChecked/>
                                            <span>Save these payment details for future payments</span>
                                        </label>
                                        <div className="payment-checbox">
                                            <label className="font-weight-light label-check ml-xl-5">
                                                <CustomCheckbox
                                                    onChange={(e) => setIsDefault(e.target.checked)}
                                                    defaultChecked={paymentMethodData?.isDefault || false}
                                                />
                                                <span className="word-break: break-word">
                          Set as default payment method
                        </span>
                                            </label>
                                        </div>
                                    </div>
                                </>}
                            </>
                        ) : (
                            <>

                                {accountType == "0" ?
                                    (
                                        <div className="form-group mb-3">
                                            <label
                                                htmlFor="exampleInputBankName1"
                                                className="form-label payment-form-label"
                                            >
                                                Name On Account
                                            </label>
                                            <div className="row g-2">
                                                <div className="col-md">
                                                    <div className="payment-controlpanl-style">
                                                        <input
                                                            type="text"
                                                            name="accountFirstName"
                                                            className="form-control custom-input-border-css"
                                                            id="exampleInputName3"
                                                            aria-describedby="nameHelp"
                                                            placeholder="First Name"
                                                            onChange={(e) => handleInputChange(e)}
                                                            required
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md">
                                                    <div>
                                                        <input
                                                            type="text"
                                                            name="accountLastName"
                                                            className="form-control custom-input-border-css"
                                                            id="exampleInputName3"
                                                            aria-describedby="nameHelp"
                                                            placeholder="Last Name"
                                                            onChange={(e) => handleInputChange(e)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="form-group mb-3">
                                            <label
                                                htmlFor="exampleInputBankName1"
                                                className="form-label payment-form-label"
                                            >
                                                Name On Account
                                            </label>
                                            <input
                                                type="text"
                                                name="companyName"
                                                required
                                                className="form-control custom-input-border-css"
                                                id="exampleInputName1"
                                                aria-describedby="nameHelp"
                                                placeholder="Enter Name on the Account"
                                            />

                                        </div>
                                    )}
                                <div className="form-group mb-3">
                                    <label
                                        htmlFor="exampleInputBankName1"
                                        className="form-label payment-form-label"
                                    >
                                        Bank Name
                                    </label>
                                    <input
                                        type="text"
                                        name="exampleInputBankName"
                                        required
                                        className="form-control bg-white"
                                        id="exampleInputBankName"
                                        aria-describedby="bankNameHelp"
                                        placeholder="Enter Bank Name"
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label
                                        htmlFor="exampleInputBankNumber1"
                                        className="form-label payment-form-label"
                                    >
                                        Bank Routing Number
                                    </label>
                                    <input
                                        type="text"
                                        required
                                        className="form-control bg-white"
                                        name="bankRoutingNumber"
                                        id="exampleInputBankNumber1"
                                        placeholder="Enter Bank Routing Number"
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label
                                        htmlFor="exampleInputAccountNumber1"
                                        className="form-label payment-form-label"
                                    >
                                        Bank Account Number
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control bg-white"
                                        id="exampleInputAccountNumber1"
                                        required
                                        name="bankAccountNumber"
                                        placeholder="Enter Bank Account Number"
                                    />
                                </div>
                                <div className="dropdown custom-dropdown">
                                    <select
                                        name="accountType"
                                        className="form-control bg-white"
                                        required
                                        onChange={(e) => handleInputChange(e)}
                                    >
                                        <option selected value="">
                                            Account Type
                                        </option>
                                        <option value="0">Personal</option>
                                        <option value="1">Business</option>
                                    </select>
                                    <div className="down-arrow">
                                        <DownArrowIcon/>
                                    </div>
                                </div>
                                <div className="checkbox-list mt-3">
                                    <label className="label-check font-weight-light float-end">
                                        <CustomCheckbox
                                            checked={isPaymentSaveDetails}
                                            onChange={(e) =>
                                                setIsPaymentSaveDetails(e.target.checked)
                                            }
                                        />
                                        Save these payment details for future payments
                                    </label>
                                    <div className="payment-checbox">
                                        <label className="font-weight-light label-check ml-xl-5">
                                            <CustomCheckbox
                                                name="isDefault"
                                                onChange={(e) => setIsDefault(e.target.checked)}
                                            />
                                            Set as default payment method
                                        </label>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="checkbox-list mt-3">
                            <div className="d-flex justify-content-center">
                                {lastFourNumbers && (
                                    <button
                                        type="submit"
                                        className="save-payment-btn sos-custom-btn-css"
                                    >
                                        {withButtonLoader("Save Payment Information", isPayerLoading)}
                                        {/* "#6e86c4" */}
                                    </button>)}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* <div className="hommeexisting-img-edit">
        <img src="/images/payment-icon.png" />
      </div> */}
            <PaymentSuccessPopup
                openModal={openModal}
                paymentMsg={paymentMsg}
                onCloseModal={onCloseModal}
            />
        </>
    );
};

export default AdminPaymentAddSwipe;
