import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  verifyAccessCode,
  getValidHashAdmin,
  updatePasswordAdmin,
  getLogo,
} from "../../Actions/authActions";
import { subDomain, eventUrl } from "../../utils";
import { map, get } from "lodash";
import { withButtonLoader } from "../../helpers";
import { Helmet } from "react-helmet";

const UpdatePasswordAdmin = (props) => {
  const { match } = props || {};
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subdomain = params.subdomain || subDomain();
  const dataValid = useSelector((state) => state.auth.adminValidTokenSuccess);
  const dataValidPass = useSelector((state) => state.auth.adminPasswordSuccess);
  const codeSent = useSelector(
    (state) => state.auth.isGenerateAccessCodeSuccess
  );
  const adminPasswordLoading = useSelector(
    (state) => state.auth.adminPasswordLoading
  );
  const verifyAccessCodeLoading = useSelector(
    (state) => state.auth.isVerifyAccessCodeLoading
  );
  const logoUrl = localStorage.getItem("logoUrl");
  const theme = JSON.parse(localStorage.getItem("theme"));
  const themeColor1 = get(theme, "themeColor1");
  const themeColor2 = get(theme, "themeColor2");
  let globalCustomCSSWithTheme;
  globalCustomCSSWithTheme = `.sos-custom-btn:hover .css-15llytj span {background: ${themeColor1}} .sos-custom-btn-css {background:${themeColor1};  border: 1px solid  ${themeColor1} } .sos-custom-btn-css:hover {color: ${themeColor1}; border: 1px solid ${themeColor1}}
   .text-cerulean {color:${themeColor1}} .text-cerulean:hover {color:${themeColor1}} .sos-content .form-control {border: 1px solid ${themeColor2}} .sos-content .form-control:focus {box-shadow: 0 0 0 0.15rem ${themeColor2}; border: ${themeColor2} }  `;

  //   const Verified = useSelector((state) => state.auth.isVerifyAccessCodeSuccess);
  const [editableData, setEditableData] = useState({});
  const [accessCode, setAccessCode] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [fieldsList, setFieldsList] = useState([
    {
      key: "newpwd",
      type: "password",
      title: "Enter new password",
      placeholder: "Enter new password",
      err: false,
      errText: "",
      className: "col-12",
    },
    {
      key: "repwd",
      type: "password",
      title: "Re-enter new password",
      placeholder: "Re-enter new password",
      err: false,
      errText: "",
      className: "col-12",
    },
  ]);
  useEffect(() => {
    if (!logoUrl) {
      dispatch(getLogo(subdomain));
    }
  }, [!logoUrl]);

  useEffect(() => {
    if (dataValidPass) {
      window.location.href = `${eventUrl(subdomain)}admin`;
    }
  }, [dataValidPass]);

  useEffect(() => {
    const nparam = `?tokenHash=${params.tokenHash}&subdomain=${subdomain}`;
    dispatch(getValidHashAdmin(nparam));
  }, []);

  useEffect(() => {
    if (!dataValid && dataValid !== undefined) {
      window.location.href = `${eventUrl(subdomain)}login`;
    }
  }, [dataValid]);

  //   useEffect(() => {
  //     if (dataValidPass) {
  //       const { newpwd } = editableData || {};
  //       const data = {
  //         tokenHash: params.userToken,
  //         password: newpwd,
  //         createPass: true,
  //       };
  //       dispatch(generateAccessCode(data));
  //     }
  //   }, [dataValidPass]);

  const handleFields = (e, i, key) => {
    const obj = { ...editableData };
    const arr = [...fieldsList];
    const { value } = e.target;
    const len = value.length;
    if (len >= 12) {
      arr[i].err = false;
      arr[i].errText = "";
      obj[key] = value;
      if (obj.newpwd !== obj.repwd && key === "repwd") {
        setErrorMessage("Password does not match");
      } else {
        setErrorMessage("");
      }
    } else {
      arr[i].err = true;
      arr[i].errText = "Password should be more than 12 characters";
    }

    setEditableData(obj);
    setFieldsList(arr);
  };

  
  const handleRegister = (e) => {
    e.preventDefault();
    const { newpwd } = editableData || {};

    const data = {
      tokenHash: params.tokenHash,
      password: newpwd,
      subdomain,
    };
    dispatch(updatePasswordAdmin(data));
  };

  const handleSubmitCode = () => {
    const { newpwd } = editableData || {};
    const data = {
      accessCode,
      tokenHash: params.userToken,
      password: newpwd,
      createPass: true,
    };
    dispatch(verifyAccessCode(data));
    // if (Verified) {
    //   navigate(`/adminDashboard`);
    // }
  };

  //   useEffect(() => {
  //     if (Verified) {
  //       navigate(`${eventUrl(subdomain)}adminDashboard`);
  //     }
  //   }, [Verified]);

  const handleOnChangeCode = (e) => {
    setAccessCode(e.target.value);
  };

  const handleKeyPress = (e, key) => {
    if (e.key === "Enter") {
      if (key === 0) {
        handleRegister(e);
      }
      if (key === 1) {
        handleSubmitCode(e);
      }
    }
  };

  return (
    <>
      <Helmet style={[{ cssText: `${globalCustomCSSWithTheme}` }]} />
      {dataValid && (
        <div className="wrapper">
          <div className="sos-card-login sos-card">
            <div className="logo w-100 d-flex justify-content-center">
              <img
                src={`${
                  logoUrl
                    ? logoUrl
                    : `${window.location.origin}/images/logo.png`
                }`}
                className="img-fluid"
                alt="Logo"
              />
            </div>
            <div className="sos-content">
              {!codeSent ? (
                <form>
                  {map(fieldsList, (i, index) => {
                    return (
                      <div className="form-group mb-2" key={index}>
                        {i && i.title && (
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            {i.title}
                          </label>
                        )}
                        <input
                          type={i.type}
                          className="form-control"
                          id="password"
                          aria-describedby="emailHelp"
                          placeholder={i.placeholder}
                          onChange={(e) => handleFields(e, index, i.key)}
                          onKeyPress={(e) => handleKeyPress(e, 0)}
                        />
                        {i.err && <span className="errorMsg">{i.errText}</span>}
                      </div>
                    );
                  })}
                  {errorMessage && (
                    <span className="errorMsg">{errorMessage}</span>
                  )}
                  <div className="d-flex justify-content-end save-btn mt-4">
                    <button
                      type="button"
                      onClick={(e) => handleRegister(e)}
                      className="sos-custom-btn sos-custom-btn-css"
                      disabled={adminPasswordLoading}
                    >
                      {withButtonLoader("Submit", adminPasswordLoading)}
                    </button>
                  </div>
                </form>
              ) : (
                <>
                  <div className="form-group mb-2">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Enter code
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      id="accessCode"
                      aria-describedby="emailHelp"
                      placeholder={"Enter code"}
                      onChange={(e) => handleOnChangeCode(e)}
                      onKeyPress={(e) => handleKeyPress(e, 1)}
                    />
                  </div>
                  <div className="d-flex justify-content-end save-btn mt-4">
                    <button
                      type="button"
                      onClick={(e) => handleSubmitCode(e)}
                      className="sos-custom-btn sos-custom-btn-css"
                      disabled={verifyAccessCodeLoading}
                    >
                      {withButtonLoader("Submit", verifyAccessCodeLoading)}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default UpdatePasswordAdmin;
