import React from "react";
import {useTable, useSortBy, usePagination, useFilters} from "react-table";
import "./../ReactTable/reacttable.css";

//https://akashmittal.com/react-table-learn-filter-sort-pagination-in-10-minutes/
//https://medium.com/@blaiseiradukunda/react-table-7-tutorial-3d8ba6ac8b16

function Table({columns, data, noDataMessage, colWidths}) {
    const useColWidths = colWidths && colWidths.length && colWidths.length >= columns.length;

    const defaultColumn = React.useMemo(() => ({
        Filter: DefaultColumnFilter
    }), []);

    function DefaultColumnFilter({
                                     column: {filterValue, preFilteredRows, setFilter}
                                 }) {
        const count = preFilteredRows.length;
        return (<input
            className="rounded-0 custom-input-border-css react-table-filter-inputs"
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={``}
            style={{
                width: "100%", borderWidth: "1px 1px 1px 1px",
            }}
        />);
    }

    const filterTypes = React.useMemo(() => ({
        text: (rows, id, filterValue) => {
            return rows.filter((row) => {
                const rowValue = row.values[id];
                return rowValue !== undefined ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase()) : true;
            });
        }
    }), []);


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize},
    } = useTable({
        columns, data, defaultColumn, filterTypes, initialState: {pageIndex: 0},
    }, useFilters, useSortBy, usePagination);


    return (<>
        <>
            {/*text-cerulean*/}
            <div className="react-table table-font-size table-responsive" style={{color: "black"}}>
                <table
                    className="zui-table zui-table-rounded table-striped table-hover table-sm"
                    border="1"
                    {...getTableProps()}
                    style={{tableLayout: (colWidths && colWidths.length === columns.length) ? "fixed" : "auto"}}
                >

                    <thead className="tableheader">
                    {headerGroups.map((headerGroup, index) => (<tr
                        {...headerGroup.getHeaderGroupProps()}
                        key={index}
                    >
                        {headerGroup.headers.map((column, colIndex) => {
                            return (<th
                                key={colIndex}
                                style={{
                                    verticalAlign: "baseline",
                                    width: useColWidths && colWidths[colIndex],
                                    ...column.colStyle,
                                }}
                            >
                                <table>
                                    <tbody>
                                    <tr>
                                        <td
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            style={{
                                                width: useColWidths && colWidths[colIndex],
                                            }}
                                        >
                                            {column.render("Header")}
                                            <span>
                                                    {column.isSorted ? column.isSortedDesc ? " 🔽" : " 🔼" :
                                                        <span>&nbsp;</span>}
                                                </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            style={{
                                                width: useColWidths && colWidths[colIndex],
                                            }}
                                        >
                                            {column.canFilter ? column.render("Filter") : null}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </th>)
                        })}
                    </tr>))}
                    </thead>
                    <tbody className="react-table" {...getTableBodyProps()}>
                    {page.length > 0 ? (page.map((row, index) => {
                        prepareRow(row);
                        return (<tr className="payer-table" {...row.getRowProps()} key={index}>
                            {row.cells.map((cell, cellIndex) => {
                                return (<td
                                    {...cell.getCellProps()}
                                    key={cellIndex}
                                    style={{
                                        width: useColWidths && colWidths[cellIndex],
                                    }}
                                >
                                    {cell.render("Cell")}
                                </td>);
                            })}
                        </tr>);
                    })) : (<>
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((w, j) => {
                            return (<tr
                                key={j}
                                className="payer-table"
                            >
                                {columns.map((v, i) => {
                                    const useColWidths = colWidths && colWidths.length && colWidths.length > i;
                                    return (<td
                                        key={i}
                                        style={{
                                            width: useColWidths && colWidths[i],
                                        }}
                                    >
                                        {j === 5 && i === (Math.floor(colWidths.length / 2)) ? (noDataMessage || "No data") : (
                                            <span>&nbsp;</span>)}
                                    </td>);
                                })}
                            </tr>)
                        })}
                    </>)}
                    </tbody>
                </table>
                <div className="tablepagination ">
                    {/* <button className="fa fa-fa-icon fa-trash-o float-left font-weight-bold "></button> */}
                    {/*<button className=" fa-fa-icon fa fa-refresh float-left  fa-fa-icon"></button>*/}
                    {/*<button className="fa-fa-icon  fa fa-search float-left  fa-fa-icon"></button>*/}
                    <div className="d-inline ml-5 color-black">
                        <button
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                            className="fa-fa-icon  fa fa-step-backward  fa-fa-icon color-black"
                        ></button>
                        <button
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                            className="fa-fa-icon  fa fa-fast-backward  fa-fa-icon color-black"
                        ></button>
                        <button
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                            className="fa-fa-icon  fa fa-fast-forward   fa-fa-icon color-black"
                        ></button>
                        <button
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                            className="fa-fa-icon  fa fa-step-forward  fa-fa-icon color-black"
                        ></button>
                    </div>
                    <span className="ml-3 color-black ">{" "}
                            Page{" "}
                        <strong>{pageIndex + 1} of {pageOptions.length}</strong>
                    </span>
                    <span className="color-black ">{" "}
                        | Go to page:{" "}
                        <input
                            type="number"
                            className="border w-40px color-black"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(page);
                            }}

                        />
                    </span>
                    <div className="d-inline float-lg-right mr-1 mt-1 color-black">
                      <span>
                        <select
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                            }}
                        >
                          {[100, 200, 500].map((pageSize) => (<option key={pageSize} value={pageSize} className="">
                              Show {pageSize}
                          </option>))}
                        </select>
                      </span>
                    </div>
                    <span>
                        {` `}Total Records: {data.length}
                    </span>
                </div>
            </div>
        </>
    </>);
}

export default Table;
