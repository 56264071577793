import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAdminUserByHash } from "../../Actions/authActions";
import { withButtonLoader } from "../../helpers";
import { subDomain } from "../../utils";

const LoginAdminWithToken = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const subdomain = params.subdomain || subDomain();
    if (params && params.userLoginToken) {
      const { userLoginToken } = params || "";
      dispatch(getAdminUserByHash(userLoginToken, subdomain, navigate));
    } else {
      window.location.href = `/admin`;
    }
  }, []);

  return (
    <div className="pt-5 text-center">
      {withButtonLoader("Loading.....", true, "#000")}
    </div>
  );
};

export default LoginAdminWithToken;
