import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  updateEmail,
  getCheckValidEmailHash,
  getLogo,
} from "../../Actions/authActions";
import { withButtonLoader, getParameterByName } from "../../helpers";
import { subDomain } from "../../utils";
import { map } from "lodash";
import validator from "validator";
import { get } from "lodash";
import { Helmet } from "react-helmet";

const UpdateEmail = (props) => {
  const { match } = props || {};
  const params = useParams();
  const subdomain = params.subdomain || subDomain();
  const dataValid = useSelector((state) => state.auth.isCheckValidEmailSuccess);
  const updateEmailLoading = useSelector(
    (state) => state.auth.isUpdateEmailLoading
  );
  const logoUrl = localStorage.getItem("logoUrl");
  const theme = JSON.parse(localStorage.getItem("theme"));
  const themeColor1 = get(theme, "themeColor1");
  const themeColor2 = get(theme, "themeColor2");
  let globalCustomCSSWithTheme;
  globalCustomCSSWithTheme = `.sos-custom-btn:hover .css-15llytj span {background: ${themeColor1}} .sos-custom-btn-css {background:${themeColor1};  border: 1px solid  ${themeColor1} } .sos-custom-btn-css:hover {color: ${themeColor1}; border: 1px solid ${themeColor1}}
    .sos-content .form-control {border: 1px solid ${themeColor2}} .sos-content .form-control:focus {box-shadow: 0 0 0 0.15rem ${themeColor2}; border: ${themeColor2} }  `;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const cemail = getParameterByName("cemail");
  const [editableData, setEditableData] = useState({
    payerEmail: cemail,
  });
  const [fieldsList, setFieldsList] = useState([
    {
      key: "newPayerEmail",
      type: "email",
      title: "Enter new email",
      placeholder: "Enter email",
      err: false,
      errText: "",
      className: "col-12",
      value: "",
    },
  ]);

  useEffect(() => {
    if (!logoUrl) {
      dispatch(getLogo(subdomain));
    }
  }, [!logoUrl]);

  useEffect(() => {
    const tokenValid = `?updateEmailTokenHash=${params.updateEmailTokenHash}`;
    dispatch(getCheckValidEmailHash(tokenValid));
  }, []);
  const validateEmail = (e) => {
    const newPayerEmail = e.target.value;

    if (validator.isEmail(newPayerEmail)) {
      setEmailError("");
    } else {
      setEmailError("Enter valid email");
    }
  };
  const handleFields = (e, i, key) => {
    validateEmail(e);
    const obj = { ...editableData };
    const arr = [...fieldsList];
    const { value } = e.target;
    const len = value.length;
    if (len < 31) {
      if (key === "payerEmail") {
        obj[key] = value.toLowerCase();
      } else {
        obj[key] = value;
      }
    }
    if (arr[i].err) {
      arr[i].err = false;
      arr[i].errText = "";
    }
    if (key === "payerEmail") {
      arr[i].value = value.toLowerCase();
    }
    setEditableData(obj);
    setFieldsList(arr);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    const { payerEmail } = editableData || {};
    const data = {
      updateEmailTokenHash: params.updateEmailTokenHash,
      payerEmail: payerEmail,
    };

    dispatch(updateEmail(data, subdomain, navigate));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleRegister(e);
    }
  };
  return (
    <>
      <Helmet style={[{ cssText: `${globalCustomCSSWithTheme}` }]} />
      {dataValid ? (
        <div className="wrapper">
          <div className="sos-card-login sos-card">
            <div className="logo w-100 d-flex justify-content-center">
              <img
                src={
                  logoUrl
                    ? logoUrl
                    : `${window.location.origin}/images/logo.png`
                }
                className="img-fluid"
                alt="Logo"
              />
            </div>

            <div className="sos-content">
              <form>
                {map(fieldsList, (i, index) => {
                  return (
                    <div className="form-group mb-2" key={index}>
                      {i && i.title && (
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          {i.title}
                        </label>
                      )}
                      <input
                        type={i.type}
                        value={i.value}
                        className="form-control"
                        id="inputemail"
                        aria-describedby="emailHelp"
                        placeholder={i.placeholder}
                        onChange={(e) => handleFields(e, index, i.key)}
                        onKeyPress={(e) => handleKeyPress(e)}
                      />
                    </div>
                  );
                })}
                <span className="error_email">{emailError}</span>
                <div className="d-flex justify-content-end save-btn">
                  <button
                    type="button"
                    onClick={(e) => handleRegister(e)}
                    className="sos-custom-btn sos-custom-btn-css"
                    disabled={updateEmailLoading}
                  >
                    {withButtonLoader("send", updateEmailLoading)}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <span>Invalid</span>
      )}
    </>
  );
};

export default UpdateEmail;
