import Toast from "../../Components/Toast";
import React, {useState} from "react";
import {connect, useSelector} from "react-redux";
import {Modal} from "react-bootstrap";
import "./reply-message-popup.css";
import validator from "validator";
import {CrossIcon} from "../Icons";
import {withButtonLoader} from "../../helpers";
import classNames from "classnames";
import {executePost} from "../../Api";


const SendIntakeMultipleForm = (props) => {
    const {openModal, onCloseModal, subdomain, setIntakeFormToSend, intakeFormToSend, intakeforms} = props;
    const [payer, setPayer] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const sendIntakeLoading = useSelector(
        (state) => state.auth.isSendIntakeLoading
    );
    const [sendIntakeEmail, setSendIntakeEmail] = useState(false);
    const [sendInatakeError, setSendInatakeError] = useState(false);
    const [users, setUsers] = useState([]);
    const onClose = () => {
        setErrorMsg("");
        onCloseModal(false);
        setSendInatakeError(false);
        setSendIntakeEmail(false);
        setIntakeFormToSend({});
    };
    const handleOnChangeNumber = (e) => {
        const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
        if (rx_live.test(e.target.value)) {
            const data = (e.target && e.target.value) || "";
            if (data) {
                if (!data.match("^\\w{10}$")) {
                    setErrorMsg("Phone must be of 10 digit");
                } else {
                    setPayer(e.target.value);
                    setSendBy("phone");
                    setErrorMsg("");
                }
            } else {
                setErrorMsg("Enter valid email or phone");
            }
        } else {
            if (validator.isEmail(e.target.value)) {
                setErrorMsg("");
                setSendBy("email");
            } else {
                setErrorMsg("Enter valid email or phone ");
            }
            setPayer(e.target.value.toLowerCase());
        }
    };
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            const test = (e.target && e.target.value) || "";
            if (test) {
                onClickSend();
            }
        }
    };

    const addUser = () => {
        if (!payer || payer.length === 0) {
            return;
        }
        setUsers([...users, {value: payer}]);
        setPayer("");
    }

    const onClickSend = () => {
        setSendInatakeError(false);
        setSendIntakeEmail(false);
        if (
            (!errorMsg.length > 0 && payer)
        ) {
            let data = {payers: users.map(o => o.value), subdomain, intakeFormToSend};

            executePost(`SosCustomers/intakeSendMultiple`, data)
                .then((res) => {
                    setSendIntakeEmail(true);
                })
                .catch((err) => {
                    console.log("err qqpwojro", err);
                    setSendInatakeError(true);
                });
        } else {
            setErrorMsg("Email or Phone required");
        }
    };

    return (
        <Modal
            onHide={() => onClose()}
            show={openModal}
            size="md"
            centered
            className="reply-msg-content"
        >
            <div onClick={() => onClose()} className="close-modal-btn">
                <CrossIcon/>
            </div>

            <Modal.Body>
                <div className="">
                    <div className="">
                        <div className="card-head-large-text card-hoiz-text ">
                            <div className="col-sm-12 reply-header">
                                Send document / form request to payer
                            </div>
                        </div>
                        <div className="row px-2">
                            <p className="send-intake-msg px-3">
                                Send an email or text notification asking the payer to log in and read the
                                documents and/or sign the intake forms:
                            </p>
                            <p className="send-intake-msg px-3">

                                {users && users.map((user, key) => {
                                    return (
                                        <div className="form-group" key={key}>
                                            <label
                                                className="px-2">{`${user.value || ""}`}</label>
                                        </div>
                                    )
                                })}

                                {/*{intakeforms && intakeforms.map((intakeform) => {*/}

                                {/*    const formDisplayName = truncateString(intakeform?.originalname || "", 36);*/}


                                {/*return (*/}
                                {/*    <div className="form-group px-4" key={intakeform?._id}>*/}
                                {/*        <input*/}
                                {/*            type="checkbox"*/}
                                {/*            id={intakeform?._id}*/}
                                {/*        ></input>*/}
                                {/*        <label*/}
                                {/*            className="px-2"*/}
                                {/*            for={intakeform?._id}>{`${formDisplayName || ""}`}</label>*/}
                                {/*    </div>*/}
                                {/*)*/}
                                {/*})}*/}
                            </p>
                        </div>
                        <div className="px-3 mt-2 flex align-item flex-gap-10">
                            <div className={'flex align-item'}>
                                Email or Phone
                            </div>
                            <div>
                                <input
                                    value={payer}
                                    type="text"
                                    placeholder="Email or phone"
                                    name="payerEmail"
                                    className="office-label custom-input-border-css msg-input"
                                    onChange={(e) => handleOnChangeNumber(e)}
                                    autoComplete="off"
                                />
                            </div>
                            <div>
                                <button
                                    name="send"
                                    className={classNames("sos-custom-btn ml-4 sos-custom-btn-css")}
                                    onClick={() => addUser()}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                        <div className="row px-2">
                            <div className="col-md-5"></div>
                            <div className="col-md-7 error-intake-email">{errorMsg}</div>
                        </div>

                        {/*<div className="document-img">*/}
                        {/*    <img src="../images/email-icon.png" alt="documentIcon"/>*/}
                        {/*</div>*/}
                    </div>
                </div>
                {sendIntakeEmail || sendInatakeError ? (
                    <div className="toast-intake mt-4">
                        <Toast
                            isOpen={sendIntakeEmail || sendInatakeError}
                            showClose={false}
                            msg={
                                sendIntakeEmail ? "Document/Form request sent successfully" : "User not found"
                            }
                            type={sendIntakeEmail ? "success" : "danger"}
                            dur={2000}
                        />
                    </div>
                ) : (
                    ""
                )}
                <div className="text-center mt-4">
                    <button
                        type="submit"
                        name="send"
                        className={classNames("sos-custom-btn ml-4 sos-custom-btn-css")}
                        onClick={() => onClickSend()}
                    >
                        {withButtonLoader("Send", sendIntakeLoading, "#6e86c4")}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

const mapDispatchToProps = {
    SendIntakeMultipleForm,
};
export default connect(null, mapDispatchToProps)(SendIntakeMultipleForm);
