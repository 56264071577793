import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  updatePassword,
  getValidHash,
  generateAccessCode,
  verifyAccessCode,
  getLogo,
} from "../../Actions/authActions";
import { subDomain, eventUrl } from "../../utils";
import { map, get } from "lodash";
import { withButtonLoader } from "../../helpers";
import Toast from "../../Components/Toast";
import { Helmet } from "react-helmet";
import "./updatePassword.css";

const UpdatePassword = (props) => {
  const { match } = props || {};
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subdomain = params.subdomain || subDomain();
  const logoUrl = localStorage.getItem("logoUrl");
  const dataValid = useSelector((state) => state.auth.isCheckValidHashSuccess);

  const theme = JSON.parse(localStorage.getItem("theme"));
  const themeColor1 = get(theme, "themeColor1");
  const themeColor2 = get(theme, "themeColor2");
  let globalCustomCSSWithTheme;
  globalCustomCSSWithTheme = `.sos-custom-btn:hover .css-15llytj span {background: ${themeColor1}} .sos-custom-btn-css {background:${themeColor1};  border: 1px solid  ${themeColor1} } .sos-custom-btn-css:hover {color: ${themeColor1}; border: 1px solid ${themeColor1}}
  .text-cerulean {color:${themeColor1}} .text-cerulean:hover {color:${themeColor1}} .sos-content .form-control {border: 1px solid ${themeColor2}} .sos-content .form-control:focus {box-shadow: 0 0 0 0.15rem ${themeColor2}; border: ${themeColor2} }  `;
  const dataValidPass = useSelector(
    (state) => state.auth.isUpdatePasswordSuccess
  );
  const generatedAccessCodeError = useSelector(
    (state) => state.auth.isGenerateAccessCodeError
  );
  const updatePasswordSuccess = useSelector(
    (state) => state.auth.isUpdatePasswordSuccess
  );
  const isVerifyAccessCodeError = useSelector(
    (state) => state.auth.isVerifyAccessCodeError
  );

  const user = useSelector((state) => state.auth.user);
  const Verified = useSelector((state) => state.auth.isVerifyAccessCodeSuccess);
  const updatePasswordLoading = useSelector(
    (state) => state.auth.isUpdatePasswordLoading
  );
  const generateAccessCodeLoading = useSelector(
    (state) => state.auth.isVerifyAccessCodeLoading
  );
  const generatedAccessCode = useSelector(
    (state) => state.auth.isGenerateAccessCodeSuccess
  );
  const [editableData, setEditableData] = useState({});
  const [accessCode, setAccessCode] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [emailAndPhoneMessage, setEmailAndPhoneMessage] = useState("phone");
  const [successUpdate, setSuccessUpdate] = useState(false);
  const [isSmsSent, setIsSmsSent] = useState(false);
  const [error, setError] = useState({
    toastOpenClose: false,
    errorMsg: "",
  });
  const [fieldsList, setFieldsList] = useState([
    {
      key: "newpwd",
      type: "password",
      title: "Enter new password",
      placeholder: "Enter new password",
      err: false,
      errText: "",
      className: "col-12",
    },
    {
      key: "repwd",
      type: "password",
      title: "Re-enter new password",
      placeholder: "Re-enter new password",
      err: false,
      errText: "",
      className: "col-12",
    },
  ]);

  useEffect(() => {
    if (!logoUrl) {
      dispatch(getLogo(subdomain));
    }
  }, [!logoUrl]);

  useEffect(() => {
    if (updatePasswordSuccess) {
      setTimeout(() => {
        setSuccessUpdate(updatePasswordSuccess);
      }, 2000);
    }
  }, [updatePasswordSuccess]);

  useEffect(() => {
    if (!dataValid && dataValid !== undefined) {
      window.location.href = `${eventUrl(subdomain)}login`;
    }
  }, [dataValid]);

  useEffect(() => {
    if (dataValidPass) {
      const { newpwd } = editableData || {};
      const data = {
        tokenHash: params.userToken,
        password: newpwd,
        createPass: true,
        subdomain,
        payerEmail: user.payerEmail,
        sendBy: "phone",
      };
      dispatch(generateAccessCode(data));
    }
  }, [dataValidPass]);

  useEffect(() => {
    const nparam = `?tokenHash=${params.userToken}&subdomain=${subdomain}`;
    dispatch(getValidHash(nparam));
  }, []);

  useEffect(() => {
    if (Verified) {
      window.location.href = `${eventUrl(subdomain)}home`;
    }
  }, [Verified]);

  const handleFields = (e, i, key) => {
    const obj = { ...editableData };
    const arr = [...fieldsList];
    const { value } = e.target;
    const len = value.length;
    if (len >= 8) {
      obj[key] = value;
      arr[i].err = false;
      arr[i].errText = "";

      if (obj.newpwd !== obj.repwd && key === "repwd") {
        setErrorMessage("Password does not match");
      } else {
        setErrorMessage("");
      }
    } else {
      arr[i].err = true;
      arr[i].errText = "Password should be more than 8 characters";
      setErrorMessage("");
    }

    setEditableData(obj);
    setFieldsList(arr);
  };

  const isValid = () => {
    const { newpwd, repwd } = editableData || {};
    if (!newpwd || !repwd || repwd !== newpwd || newpwd.length < 8) {
      return false;
    }
    return true;
  };


  const handleRegister = (e) => {
    e.preventDefault();
    if (isValid()) {
      const { newpwd } = editableData || {};
      const data = {
        tokenHash: params.userToken,
        password: newpwd,
        subdomain,
      };
      dispatch(updatePassword(data));
    }
  };

  const handleSubmitCode = () => {
    const { newpwd } = editableData || {};
    const data = {
      accessCode,
      tokenHash: params.userToken,
      password: newpwd,
      createPass: true,
      subdomain,
    };
    dispatch(verifyAccessCode(data, navigate, subdomain));
  };

  const handleOnChangeCode = (e) => {
    setAccessCode(e.target.value);
  };

  const handleKeyPress = (e, key) => {
    if (e.key === "Enter") {
      if (key === 0) {
        handleRegister(e);
      }
      if (key === 1) {
        handleSubmitCode(e);
      }
    }
  };

  const handleEmailText = (i) => {
    let data = {};
    if (i === "email") {
      setEmailAndPhoneMessage("email");
      const { newpwd } = editableData || {};
      data = {
        tokenHash: params.userToken,
        password: newpwd,
        createPass: true,
        subdomain,
        payerEmail: user.payerEmail,
        sendBy: i,
      };

      dispatch(generateAccessCode(data));
    }
    if (i === "phone") {
      if (isSmsSent) {
        setError({
          toastOpenClose: true,
          errorMsg: "Please wait 10 sec before attempting again.",
          type: "danger",
        });
        return;
      }
      setIsSmsSent(true);
      setTimeout(() => {
        setIsSmsSent(false);
      }, 10000);
      setEmailAndPhoneMessage("phone");
      const { newpwd } = editableData || {};
      data = {
        tokenHash: params.userToken,
        password: newpwd,
        createPass: true,
        subdomain,
        payerEmail: user.payerEmail,
        sendBy: i,
      };
      dispatch(generateAccessCode(data));
    }
  };
  return (
    <>
      <Helmet style={[{ cssText: `${globalCustomCSSWithTheme}` }]} />
      {dataValid && (
        <div className="wrapper">
          <div className="sos-card-login sos-card">
            <div className="logo w-100 d-flex justify-content-center">
              <img
                src={
                  logoUrl
                    ? logoUrl
                    : `${window.location.origin}/images/logo.png`
                }
                className="img-fluid"
                alt="Logo"
              />
            </div>
            <div className="sos-content">
              {!successUpdate ? (
                <form>
                  {map(fieldsList, (i, index) => {
                    return (
                      <div className="form-group mb-2" key={index}>
                        {i && i.title && (
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            {i.title}
                          </label>
                        )}
                        <input
                          type={i.type}
                          className="form-control"
                          id="inputEmail1"
                          aria-describedby="emailHelp"
                          placeholder={i.placeholder}
                          onChange={(e) => handleFields(e, index, i.key)}
                          onKeyPress={(e) => handleKeyPress(e, 0)}
                        />
                        {i.err && <span className="errorMsg">{i.errText}</span>}
                      </div>
                    );
                  })}
                  {errorMessage && (
                    <span className="errorMsg">{errorMessage}</span>
                  )}
                  {updatePasswordSuccess && (
                    <Toast
                      isOpen={true}
                      showClose={false}
                      msg={"Password updated successfully"}
                      type={"success"}
                      dur={2000}
                    />
                  )}
                  <div className="d-flex justify-content-end save-btn mt-4">
                    <button
                      type="button"
                      onClick={(e) => handleRegister(e)}
                      className="sos-custom-btn sos-custom-btn-css"
                      disabled={updatePasswordLoading}
                    >
                      {withButtonLoader("Submit", updatePasswordLoading)}
                    </button>
                  </div>
                </form>
              ) : (
                <>
                  <div className="form-group mb-2">
                    <span className="text-cerulean d-block mb-4 text-cerulean-position text-decoration-underline">
                      {`You should have received a code sent to your ${emailAndPhoneMessage}. Please
                      enter that code into the field below.`}
                    </span>
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Enter the code you received
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      id="accessCode"
                      aria-describedby="emailHelp"
                      placeholder={"Enter your code"}
                      onChange={(e) => handleOnChangeCode(e)}
                      onKeyPress={(e) => handleKeyPress(e, 1)}
                    />
                  </div>
                  <div className="d-flex mt-4">
                    <Link
                      to="#"
                      className="text-cerulean d-block mb-4 text-decoration-none"
                      onClick={() => handleEmailText("email")}
                    >
                      Send by email
                    </Link>
                    <Link
                      to="#"
                      className="text-cerulean d-block mb-4 text-decoration-none"
                      onClick={() => handleEmailText("phone")}
                    >
                      Send by text
                    </Link>
                  </div>
                  <div>
                    {error.toastOpenClose && (
                        <div data-cy="general-code-error">
                          <Toast
                              isOpen={error.toastOpenClose}
                              showClose={false}
                              msg={error.errorMsg}
                              type={error.type}
                          />
                        </div>
                    )}
                    {generatedAccessCode && (
                      <Toast
                        isOpen={true}
                        showClose={false}
                        msg={`Code sent. Please check your ${emailAndPhoneMessage}.`}
                        type={"success"}
                        dur={4000}
                      />
                    )}
                    {isVerifyAccessCodeError && (
                      <Toast
                        isOpen={true}
                        showClose={false}
                        msg={`Invalid code. Err a9b7fce0`}
                        type={"danger"}
                        dur={4000}
                      />
                    )}
                    {generatedAccessCodeError && (
                      <Toast
                        isOpen={true}
                        showClose={false}
                        msg={"Something went wrong while sending code"}
                        type={"danger"}
                        dur={4000}
                      />
                    )}
                  </div>

                  <div className="d-flex justify-content-end save-btn mt-4">
                    <button
                      type="button"
                      onClick={(e) => handleSubmitCode(e)}
                      className="sos-custom-btn sos-custom-btn-css"
                      disabled={generateAccessCodeLoading}
                    >
                      {withButtonLoader("Submit", generateAccessCodeLoading)}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default UpdatePassword;
