import React, { useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { getTransactionData } from "../../Actions/authActions";
import { amountFormatter, subDomain } from "../../utils";
import sumBy from "lodash/sumBy";
import map from "lodash/map";
import { useParams } from 'react-router-dom';

import Table from "./ReactTable/ReactTableMain";

const Transaction = (props) => {
  const { match } = props || {};
  const params = useParams();
  const subdomain = params.subdomain || subDomain();
  const dispatch = useDispatch();
  const transaction = useSelector((state) => state.auth.transactionData);

  useEffect(() => {
    dispatch(getTransactionData(subdomain));
  }, []);

  const totalAmounts = (row) => {
    const total = sumBy(
      map(row.patientPayer, (i) => ({
        ...i,
        amountPaid: parseFloat(i.amountPaid) || 0,
      })),
      "amountPaid"
    );
    return total;
  };

  const columns = React.useMemo(() => [
    {
      Header: "No",
      // accessor: (_row: any, i: number) => i + 1,
      accessor: (_row, i) => i + 1,
    },
    {
      Header: "Patient Number",
      accessor: (row) =>
        row.patientPayer ? row.patientPayer[0].patientNumber : undefined,
    },
    {
      Header: "Patient PayerNumber",
      accessor: (row) =>
        row.patientPayer ? row.patientPayer[0].patientPayerId : undefined,
    },
    {
      Header: "User Id",
      accessor: "userId",
    },
    {
      Header: "Transaction Id",
      accessor: "transactionId",
    },

    {
      Header: "Date",
      accessor: (row) => moment(row.createdAt).format("MM-DD-YY"),
    },
    {
      Header: "Account Balance",
      accessor: (row) => amountFormatter(totalAmounts(row)),
    },
  ]);

  return (
    <>
      <div className="container">
        {<Table columns={columns} data={transaction} />}
      </div>
    </>
  );
};

export default Transaction;
