import React from "react";
import classnames from "classnames";
import "./style.css";

const CustomRadio = ({
  defaultChecked,
  checked,
  className,
  label,
  ...rest
}) => {
  return (
    <label className="custom-radio">
      <input
        {...rest}
        type="radio"
        checked={checked}
        defaultChecked={defaultChecked}
        className={classnames("hide", { [className]: className })}
      />
      {label || ""}
      <span className={"custom-radio-checked"}></span>
    </label>
  );
};

export default CustomRadio;
