import React, {useState, useEffect} from "react";
import {useNavigate, Link, useLocation, useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import map from "lodash/map";
import {
    loginAct,
    sentAccessCode,
    verifyLoginAuthenticateCode,
    adminLoginAct,
    verifyAdminLoginAuthenticateCode,
    getLoginAuthenticateCode,
    getLogo,
} from "../../Actions/authActions";
import {withButtonLoader} from "../../helpers";
import {subDomain, eventUrl} from "../../utils";
import Toast from "../../Components/Toast";
import "./login.css";
import validator from "validator";
import {get} from "lodash";
import {Helmet} from "react-helmet";
import {LOGIN_RESET_ERROR_STATE} from "../../constants";

const Login = (props) => {
    const {match} = props || {};
    // const { params } = match || {};

    const params = useParams();
    const subdomain = params.subdomain || subDomain();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {existingUser, tokenHash, userEmail, isAdmin} =
    localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')) : false;
    const user = JSON.parse(localStorage.getItem("user")) || {};
    const isLoginLoad = useSelector((state) => state.auth.isLoginLoad);
    const id = useSelector((state) => state.auth.user.id);
    const _id = useSelector((state) => state.auth.user._id);
    const loginCheck = useSelector((state) => state.auth.isLoginSuccess);
    const userForResendCode = useSelector((state) => state.auth.user);
    const codeSendError = useSelector(
        (state) => state.auth.isVerifyLoginCodeError
    );
    const isVerifyLoginCodeLoading = useSelector(
        (state) => state.auth.isVerifyLoginCodeLoading
    );
    const codeVerified = useSelector(
        (state) => state.auth.isVerifyLoginCodeSuccess
    );
    const isAuthenticated = useSelector(
        (state) => state.auth.isAuthenticated
    );
    const logoUrl = useSelector((state) => state.auth.logoUrl);
    const theme = useSelector((state) => state.auth.theme);
    const themeColor1 = get(theme, "themeColor1");
    const themeColor2 = get(theme, "themeColor2");
    let globalCustomCSSWithTheme;
    globalCustomCSSWithTheme = `.sos-custom-btn:hover .css-15llytj span {background: ${themeColor1}} .sos-custom-btn-css {background:${themeColor1};  border: 1px solid  ${themeColor1} } .sos-custom-btn-css:hover {color: ${themeColor1}; border: 1px solid ${themeColor1}}
  .text-cerulean {color:${themeColor1}} .text-cerulean:hover {color:${themeColor1}} .sos-content .form-control {border: 1px solid ${themeColor2}} .sos-content .form-control:focus {box-shadow: 0 0 0 0.15rem ${themeColor2}; border: ${themeColor2} }  `;
    const sendCodeError = useSelector((state) => state.auth.isSendCodeError);
    const unAuthorized = useSelector((state) => state.auth.isLoginError);
    const codeSendSuccess = useSelector((state) => state.auth.isLoginCodeSuccess);
    const [editableData, setEditableData] = useState({});
    const [accessCode, setAccessCode] = useState("");
    const [loginSuccessCheck, setLoginSuccessCheck] = useState(false);
    const [emailAndPhoneMessage, setEmailAndPhoneMessage] = useState("phone");
    const [isSmsSent, setIsSmsSent] = useState(false);
    const [error, setError] = useState({
        toastOpenClose: false,
        errorMsg: "",
    });
    const [fieldsList, setFieldsList] = useState([
        {
            key: "payerEmail",
            type: "email",
            placeholder: "Enter email",
            label: "Email",
            dataCy: 'Email',
            err: false,
            errText: "",
            value: "",
        },
        {
            key: "password",
            type: "password",
            dataCy: 'Password',
            placeholder: "Enter password",
            label: "Password",
            err: false,
            errText: "",
            value: "",
        },
    ]);

    // useEffect(() => {
    //   if (user && Object.keys(user).length > 0) {
    //     window.location.href = `${eventUrl(subdomain)}home`;
    //   }
    // }, [user]);

    useEffect(() => {
        if (isAuthenticated) {
            window.location.href = `${eventUrl(subdomain)}home`;
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (!logoUrl) {
            dispatch(getLogo(subdomain));
        }
    }, [!logoUrl]);

    useEffect(() => {
        if (loginCheck) {
            setLoginSuccessCheck(true);
        }
    }, [loginCheck]);

    useEffect(() => {
        if (codeVerified) {
            window.location.href = `${eventUrl(subdomain)}home`;
        }
    }, [codeVerified]);

    const handleFields = (e, i, key) => {
        const obj = {...editableData};
        const arr = [...fieldsList];
        const {value} = e.target;
        const len = value.length;
        if (len < 31) {
            if (key === "payerEmail") {
                obj[key] = value.toLowerCase();
            } else {
                obj[key] = value;
            }
        }
        if (arr[i].err) {
            arr[i].err = false;
            arr[i].errText = "";
        }
        if (key === "payerEmail") {
            arr[i].value = value.toLowerCase();
        } else {
            arr[i].value = value;
        }
        setEditableData(obj);
        setFieldsList(arr);
    };

    const isValid = () => {
        const arr = [...fieldsList];
        const {payerEmail, password} = editableData || {};
        const handleErr = (i, msg, show) => {
            arr[i].err = show;
            arr[i].errText = msg;
        };

        if (!existingUser) {
            if (!payerEmail) {
                handleErr(0, "Email is required.", true);
            } else {
                handleErr(0, "", false);
            }
            if (payerEmail) {
                if (validator.isEmail(payerEmail)) {
                    handleErr(0, "", false);
                } else {
                    handleErr(0, "Enter valid Email", true);
                }
            }
        }

        if (!password) {
            handleErr(1, "Password is required.", true);
        } else {
            if (password.length < 8) {
                handleErr(1, "Password must be more than 8 characters", true);
            } else {
                handleErr(1, "", false);
            }
        }
        setFieldsList(arr);
        if (
            (!existingUser && !payerEmail) ||
            !password ||
            password.length < 8 ||
            (!existingUser && !validator.isEmail(payerEmail))
        ) {
            return false;
        }
        return true;
    };

    const handleOnChangeCode = (e) => {
        const code = e.target.value;
        setAccessCode(code);
    };

    const handleSubmitCode = () => {
        if (!accessCode) {
            setError({
                toastOpenClose: true,
                errorMsg: "Code is required",
                type: "danger",
            });
        } else if (accessCode.length < 6 || accessCode.length > 6) {
            setError({
                toastOpenClose: true,
                errorMsg: "Code must be 6 digits long",
                type: "danger",
            });
        } else {
            setError({
                toastOpenClose: false,
                errorMsg: "",
            });
            const data = {
                accessCode,
                id,
                subdomain,
            };
            const temp = {
                accessCode,
                _id,
            };
            if (isAdmin) {
                dispatch(verifyAdminLoginAuthenticateCode(temp));
            } else {
                dispatch(verifyLoginAuthenticateCode(data));
            }
        }
    };

    const handleKeyPress = (e, key) => {
        if (e.key === "Enter") {
            if (key === 0) {
                handleLogin(e);
            }
            if (key === 1) {
                handleSubmitCode(e);
            }
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        const {payerEmail, password} = editableData || {};
        if (isValid()) {
            if (existingUser) {
                const data = {
                    password,
                    tokenHash,
                    createPass: false,
                    payerEmail: userEmail,
                    subdomain,
                    sendBy: "phone",
                };

                dispatch(sentAccessCode(data, navigate));
            } else {
                if (isAdmin) {
                    dispatch(
                        adminLoginAct(
                            {adminEmail: payerEmail, password, subdomain, tokenHash},
                            navigate
                        )
                    );
                } else {
                    dispatch(loginAct({payerEmail, password, subdomain}, navigate));
                }
            }
        }
    };

    const handleEmailText = (i) => {
        let data = {};
        if (i === "email") {
            setEmailAndPhoneMessage("email");
            data = {
                payerEmail: userForResendCode.email,
                id: userForResendCode.id,
                sendBy: i,
            };
        }
        if (i === "phone") {
            if (isSmsSent) {
                setError({
                    toastOpenClose: true,
                    errorMsg: "Please wait 10 sec before attempting again.",
                    type: "danger",
                });
                return;
            }
            setIsSmsSent(true);
            setTimeout(() => {
                setIsSmsSent(false);
            }, 10000);
            setEmailAndPhoneMessage("phone");

            data = {
                payerEmail: userForResendCode.email,
                id: userForResendCode.id,
                sendBy: i,
            };
        }
        dispatch(getLoginAuthenticateCode(data));
    };

    const navigateOut = () => {

        window.location.href = subdomain ? `${eventUrl(subdomain)}forgotPassword` : '/';
    }

    const loginResetErrorState = () => dispatch({type: LOGIN_RESET_ERROR_STATE});

    return (
        <>
            <Helmet style={[{cssText: `${globalCustomCSSWithTheme}`}]}/>
            <div className="wrapper">
                <div className="sos-card-login sos-card">
                    <div className="logo w-100 d-flex justify-content-center">
                        <img
                            src={logoUrl ? logoUrl : `/images/logo.png`}
                            className="img-fluid"
                            alt="Logo"
                        />
                    </div>
                    {!loginSuccessCheck ? (
                        <div className="sos-content">
                            <>
                                <form>
                                    {map(fieldsList, (f, i) => {
                                        if (existingUser && f.type === "email") {
                                            return false;
                                        }
                                        return (
                                            <div className="form-group mb-4" key={i}>
                                                <label
                                                    htmlFor={`exampleInput${f.key}${i}`}
                                                    className="form-label"
                                                >
                                                    {f.label}
                                                </label>
                                                <input
                                                    type={f.type}
                                                    name={f.key}
                                                    value={f.value}
                                                    data-cy={f.dataCy}
                                                    onChange={(e) => handleFields(e, i, f.key)}
                                                    className="form-control"
                                                    id={`exampleInput${f.key}${i}`}
                                                    aria-describedby={`${f.key}Help`}
                                                    placeholder={f.placeholder}
                                                    onKeyPress={(e) => handleKeyPress(e, 0)}
                                                />
                                                {f.err && (
                                                    <div className='mb-7'
                                                         data-cy={f.dataCy + 'Error'}>
                                                        <Toast
                                                            isOpen={f.err}
                                                            showClose={false}
                                                            msg={f.errText}
                                                            type={"danger"}
                                                        />

                                                    </div>
                                                )}
                                                {(existingUser && sendCodeError || unAuthorized && i === fieldsList.length - 1) && (
                                                    <div className="mb-7" data-cy={'InvalidPasswordLoginToast'}>
                                                        <Toast
                                                            style={{position: "relative"}}
                                                            isOpen={true}
                                                            showClose={false}
                                                            msg={"Incorrect credentials"}
                                                            type={"danger"}
                                                            dur={4000}
                                                            closeCallbackFn={loginResetErrorState}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </form>
                            </>
                            <a
                                className={`text-cerulean extra-spacing d-block text-cerulean-position}`}
                                style={{position: "relative"}}
                                onClick={() =>
                                    navigateOut(`${eventUrl(subdomain)}forgotPassword`)
                                }
                            >
                                Forgot Password?
                            </a>
                            <div className="d-flex justify-content-end">
                                <button
                                    type="submit"
                                    data-cy="sos-login-btn"
                                    className="sos-custom-btn sos-custom-btn-css"
                                    onClick={(e) => handleLogin(e)}
                                    disabled={isLoginLoad}
                                >
                                    {withButtonLoader("Login", isLoginLoad)}
                                </button>
                            </div>
                        </div>
                    ) : (
                        <>
                            <>
                                <div className="sos-content">
                                    <div>
                    <span className="text-cerulean d-block mb-4 text-cerulean-position text-decoration-underline">
                      {`You should have received a code sent to your ${emailAndPhoneMessage}. Please
                      enter that code into the field below.`}
                    </span>
                                        <>
                                            <div className="form-group mb-4">
                                                <label
                                                    htmlFor="exampleInputEmail1"
                                                    className="form-label"
                                                    data-cy="enter-code-label"
                                                >
                                                    Enter the code you received
                                                </label>

                                                <input
                                                    type="text"
                                                    name="accessCode"
                                                    data-cy="access-code"
                                                    className="form-control"
                                                    id="accessCode"
                                                    aria-describedby="emailHelp"
                                                    placeholder={"Enter Your Code"}
                                                    onChange={(e) => handleOnChangeCode(e)}
                                                    onKeyPress={(e) => handleKeyPress(e, 1)}
                                                />
                                            </div>
                                        </>
                                        <div className="d-flex">
                                            <Link
                                                data-cy="send-by-email"
                                                to={`#`}
                                                className="text-cerulean d-block mb-4 text-cerulean-position text-decoration-none"
                                                onClick={() => handleEmailText("email")}
                                            >
                                                Send by email
                                            </Link>

                                            <Link
                                                data-cy="send-by-text"
                                                to={`#`}
                                                className="text-cerulean d-block mb-4 text-cerulean-position text-decoration-none"
                                                onClick={() => handleEmailText("phone")}
                                            >
                                                Send by text
                                            </Link>
                                        </div>
                                    </div>
                                    {error.toastOpenClose && (
                                        <div data-cy="general-code-error">
                                            <Toast
                                                isOpen={error.toastOpenClose}
                                                showClose={false}
                                                msg={error.errorMsg}
                                                type={error.type}
                                            />
                                        </div>
                                    )}
                                    {codeSendSuccess && (
                                        <Toast
                                            isOpen={true}
                                            showClose={false}
                                            msg={`Code sent. Please check your ${emailAndPhoneMessage}.`}
                                            type={"success"}
                                            dur={4000}
                                        />
                                    )}
                                    {codeSendError && (
                                        <div data-cy="invalid-code-error">
                                            <Toast
                                                isOpen={true}
                                                showClose={false}
                                                msg={`Invalid code. Err 83314c2b`}
                                                type={"danger"}
                                                dur={4000}
                                            />
                                        </div>
                                    )}
                                    <div className="d-flex justify-content-end save-btn mt-4">
                                        <button
                                            type="button"
                                            data-cy="sos-access-login-btn"
                                            onClick={(e) => handleSubmitCode(e)}
                                            className="sos-custom-btn sos-custom-btn-css"
                                            disabled={isVerifyLoginCodeLoading}
                                        >
                                            {withButtonLoader("Submit", isVerifyLoginCodeLoading)}
                                        </button>
                                    </div>
                                </div>
                            </>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Login;
