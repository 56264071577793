import React, {useState, useEffect} from "react";
import {Modal} from "react-bootstrap";
import "./edit-intake-form-popup.css";
import {withButtonLoader} from "../../helpers";
import {CrossIcon, DigitalPen, PrinterIcon, ReadOnlyIcon} from "../Icons";
import {truncateString} from "../../utils";
import {AppropriateFormTypeIcon, formInstructionsPerType} from "../AppropriateFormType";
import {getAdminUserData, getUserforms, updateIntakeFormType} from "../../Actions/authActions";
import {useDispatch} from "react-redux";


const EditIntakeFormPopup = (props) => {
    const {
        openModal,
        onCloseModal,
        onshowHelloSign,
        setShowHelloSign,
        isLoad,
        setIsLoad,
        fileToEdit,
        whatToEdit,
        setWhatToEdit,
    } = props;

    const formDisplayName = truncateString(fileToEdit?.originalname || "", 36);

    const dispatch = useDispatch();

    const closeEditIcon = () => {
        onCloseModal();
        setIsLoad(false);
        setShowHelloSign(false);
    };

    const doShowHelloSign = (e) => {
        e.preventDefault();
        onshowHelloSign();
    };

    const setIntakeFormType = (e, formType) => {
        e.preventDefault();
        fileToEdit.formType = formType;
        dispatch(updateIntakeFormType(formType, fileToEdit._id));
    }

    // useEffect(() => {
    //     if (!Object.keys(userAdmin).length && homePage === "forms") {
    //         dispatch(getAdminUserData(subdomain));
    //
    //     }
    // }, [!userAdmin]);



    return (
        <Modal show={openModal} size="md" centered className="reply-msg-content">
            <div onClick={() => closeEditIcon()} className="close-modal-btn">
                <CrossIcon/>
            </div>

            {whatToEdit === "editFormFields" &&
                <Modal.Body>
                    <div className="text-center mt-4 py-3 px-3">
                        <><h3>Add/Edit e-Signature Form Fields</h3>
                            <p className="">Payers/patients will be asked to electronically sign online.</p>
                        </>
                        <p className="mt-3"><AppropriateFormTypeIcon formType={fileToEdit?.formType}/>&nbsp;
                            <em>{formDisplayName}</em></p>
                        <div className={"mt-5"}>
                            To prepare this intake form for use with e-signatures:
                            <ul className="mt-3 ms-5" style={{textAlign: "left"}}>
                                <li>1. Select OK</li>
                                <li>2. Add/edit form fields</li>
                                <li>3. Select CONTINUE to save the form</li>
                            </ul>
                        </div>
                        <button
                            type="submit"
                            name="send"
                            className="sos-custom-btn continue-btn mt-3 sos-custom-btn-css"
                            onClick={(e) => doShowHelloSign(e)}
                            disabled={isLoad}
                        >
                            {withButtonLoader("OK", isLoad)}
                        </button>

                    </div>
                </Modal.Body>}

            {whatToEdit === "editFormType" && <Modal.Body>
                <div className="text-center mt-4 py-3 px-3">

                    <h3>Set Form Type and Patient Instructions</h3>
                    <div className="mt-5 flex space-around">
                        <em>{formDisplayName}</em>
                        <div><AppropriateFormTypeIcon formType={fileToEdit?.formType}/></div>
                    </div>
                    <>
                        {/*<p className="mt-4">Patients will be given instructions appropriate for the form type.</p>*/}
                        <div className="ms-1 me-3">
                            <div className="row mt-5">
                                <div className="col-6" style={{textAlign: "left",}}
                                >
                                    <b>Patient Instructions:</b>
                                </div>
                                <div className="col-6" style={{textAlign: "left",}}
                                >
                                    <b>Choose Form Type:</b>
                                </div>
                            </div>
                            {formInstructionsPerType.map((f,i) => {
                                return (<div className="row mt-3" key={i}>
                                    <div className="col-6 flex flex-column flex-center" style={{textAlign: "left"}}
                                    ><span>
                                        {f.patientInstructions}
                                        </span>
                                    </div>
                                    <button
                                        type="submit"
                                        name="readOnlyForm"
                                        className={`${"col-6"} ${f.buttonCssClasses}`}
                                        onClick={(e) => {
                                            if (f.formType === "eSign") {
                                                setWhatToEdit("editFormFields");
                                            } else {
                                                setIntakeFormType(e, f.formType)
                                            }
                                        }}
                                        disabled={isLoad}
                                    >
                                        {withButtonLoader(<>{f.formType === "eSign"
                                            ? (<><p><AppropriateFormTypeIcon formType={f.formType}/></p><p>You will be redirected to edit and save form fields.</p></>)
                                            : (<AppropriateFormTypeIcon formType={f.formType}/>)}</>, isLoad)}
                                    </button>
                                </div>)
                            })}
                        </div>
                    </>
                </div>
            </Modal.Body>}
        </Modal>);
};

export default EditIntakeFormPopup;
