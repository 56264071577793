import axios from "axios";

export class SwipePayment {

    rawSwipedData;
    timeout = null;
    cardInfo = [];
    sslEncryptedTrack1Data;
    sslEncryptedTrack2Data;
    sslKsn;
    lastFourNumbers;
    firstName;
    lastName;
    data;
    disguisedSwipeNumber;
    listener;

    constructor(data) {
        this.data = data;

    }

    init() {
        this.createSwipeInput();

    }

    destroy() {
        if (document.getElementById("swipeInput")) {
            document.getElementById("swipeInput").removeEventListener('blur', this.listener);
        }
        document.getElementById("parentForSwipeInput").innerHTML = "";
    }

    async pay(e) {
        e.preventDefault();
        return await this.storeCreditCard();
    }

    createSwipeInput() {
        if (!document.getElementById("swipeInput")) {
            const input = document.createElement("input");
            input.id = "swipeInput";
            input.style = `width: 0px; z-index: -100; position: fixed; top: -100px; left: -100px;`;
            // input.style = "display:none;";
            input.addEventListener("change", (e) => this.parseCardInfo(e));
            this.listener = input.addEventListener("blur", (e) => {
                if (document.getElementById("swipeInput")) {
                    document.getElementById("swipeInput").focus();
                }
            });

            input.setAttribute("data-private", "true");
            input.setAttribute("tab-index", "-1");
            const parentForSwipeInput = document.getElementById("parentForSwipeInput");
            parentForSwipeInput.appendChild(input);
        }
        setTimeout(() => {
            document.getElementById("swipeInput").focus();
        }, 100)
    }

    parseCardInfo(e) {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        const target = e.target;
        if (!target.value || target.value.length === 0) {
            return;
        }
        this.timeout = setTimeout(() => {
            const cardInfoMatches = target.value.matchAll(/(?:\|)[\w\d]+/g);
            this.cardInfo = [];
            for (let element of cardInfoMatches) {
                this.cardInfo.push(element && element[0] || "");
            }

            this.rawSwipeData = target.value;
            this.sslEncryptedTrack1Data = this.cardInfo[1].replace('|', '');
            this.sslEncryptedTrack2Data = this.cardInfo[2].replace('|', '');
            this.sslKsn = this.cardInfo[7].replace('|', '');

            const lastFourRegex = /[\d]{4}(?=\^)/;
            const lastFourMatch = target.value.match(lastFourRegex);
            this.lastFourNumbers = lastFourMatch.length > 0 && lastFourMatch[0];

            const nameRegEx = /[\^]([\w ]+)[\/]([\w .]+)[\^]/;
            const fullName = target.value.match(nameRegEx);

            this.firstName = (fullName && fullName[fullName.length - 1] || "").trim();
            this.lastName = fullName && fullName[1] || "";
            this.disguisedSwipeNumber = "**** **** **** " + this.lastFourNumbers;

            document.dispatchEvent(new CustomEvent("cardSwiped", {
                detail: {
                    lastFourNumbers: this.lastFourNumbers,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    disguisedSwipeNumber: this.disguisedSwipeNumber
                }
            }));
            document.getElementById("swipeInput").value = "";
        }, 100);

    }

    serializeDataForConverge() {
        return {
            // ssl_amount: this.data.amount == 0 ? "0.01" : this.data.amount,
            ssl_amount: this.getAmount(),
            ssl_transaction_type: this.data.amount == 0 ? "ccauthonly" : "ccsale",
            // ssl_txn_auth_token: token.data,
            // ssl_card_number: formData && formData.cardnumber.replaceAll(" ", ""),
            // ssl_exp_date: formData && formData.expiry.replace(/\//g, ""),
            // ssl_cvv2cvc2: formData && formData.cvc,
            ssl_encrypted_track1_data: this.sslEncryptedTrack1Data,
            ssl_encrypted_track2_data: this.sslEncryptedTrack2Data,
            ssl_ksn: this.sslKsn,
            lastFourNumbers: this.lastFourNumbers,

            ssl_first_name: this.firstName,
            ssl_last_name: this.lastName,

            ssl_email: this.data.payerEmail,
            ssl_get_token: "Y",
            ssl_add_token: "Y",

        };
    }

    getAmount() {
        // return +this.data.amount === 0 ? "0.01" : this.data.amount;
        let ssl_amount = +this.data.amount === 0 ? "0.01" : this.data.amount;
        if (typeof ssl_amount === 'number') {
            ssl_amount = ssl_amount.toString();
        }
        if (typeof ssl_amount !== 'string') {
            ssl_amount = "0.01";
        }
        ssl_amount = ssl_amount.replaceAll(',','');
        console.log("ssl_amount", ssl_amount);
        return ssl_amount;

    }

    storeCreditCard() {
        return axios.post("/transaction/swipeTxn", {
            subdomain: this.data.subdomain,
            adminUser: this.data.adminUser,
            payerUser: this.data.payerUser,
            paymentMethod: {
                isPaymentSaveDetails: this.data.isPaymentSaveDetails,
                isDefault: this.data.isDefault,
                paymentType: "swipe"
            },
            patientPayer: this.data.patientPayer,
            ...this.serializeDataForConverge(),
            rawSwipeData: this.rawSwipeData
        });
    }

}
