import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useParams, useNavigate} from "react-router-dom";
import {
    DollarSignIcon, TrashCanIcon,
} from "../../Components/Icons";
import {
    deleteUserData,
    getAdminUserData,
    getAllUsers,
    getTransaction, isTxnVoidable,
    redirectPayUserForAdminLookup, refundTxn
} from "../../Actions/authActions";
import Table from "./../AdminDashboard/ReactTable/ReactTableMain";
import {subDomain, amountFormatterWithZero} from "../../utils";
import sumBy from "lodash/sumBy";
import map from "lodash/map";
import CustomPopoverDelete from "../../Components/CustomPopoverDelete";
import CustomePopUp from "../../Components/customePopUp";
import {DELETE_USERDATA_INITIAL} from "../../constants";
import "./payersTable.css";
import moment from "moment";
import {withButtonLoader} from "../../helpers";
import CustomPopoverRefund from "../../Components/CustomPopoverRefund";


const TransactionsTable = (props) => {


    const dispatch = useDispatch();
    const {homePage, subdomain, themeColor1, userAdmin} = props;


    useEffect(() => {
        if (!Object.keys(userAdmin).length && homePage === "transactions") {
            dispatch(getAdminUserData(subdomain));
        }
    }, [!userAdmin]);

    const isTransactionLoading = useSelector(
        (state) => state.auth.isTransactionLoading
    );
    const transactionData = useSelector((state) => state.auth.transactionData);
    const [filteredData, setFilteredData] = useState({});
    const [clearSearch, setClearSearch] = useState("");
    const [transactionPage, setTransactionPage] = useState("All Transactions");

    useEffect(() => {
        if (homePage === "transactions") {
            dispatch(getTransaction(subdomain));
        }
    }, [homePage]);

    useEffect(() => {
        if (transactionData) {
            setFilteredData(transactionData);
        }
    }, [transactionData]);


    const [target, setTarget] = useState(null);
    const [transactionForRefund, setTransactionForRefund] = useState({});
    const [showPopUp, setShowPopUp] = useState(false);
    const [showSuccessPopUp, setSuccessPopUp] = useState(false);
    const [showErrorPopUp, setShowErrorPopUp] = useState(false);

    const transactionIsVoidableLoading = useSelector(
        (state) => state.auth.transactionIsVoidableLoading
    );
    const transactionIsVoidableTxnId = useSelector(
        (state) => state.auth.transactionIsVoidableTxnId
    );
    const transactionIsVoidable = useSelector(
        (state) => state.auth.transactionIsVoidable
    );
    const transactionIsVoidableSuccess = useSelector(
        (state) => state.auth.transactionIsVoidableSuccess
    );

    const transactionIdRefund = useSelector(
        (state) => state.auth.transactionIdRefund
    );

    const transactionRefundLoading = useSelector(
        (state) => state.auth.transactionRefundLoading
    );
    const transactionRefundSuccess = useSelector(
        (state) => state.auth.transactionRefundSuccess
    )

    const transactionRefundError = useSelector(
        (state) => state.auth.transactionRefundError
    )

    const transactionIsVoidableError = useSelector(
        (state) => state.auth.transactionIsVoidableError
    )

    //david fix this -- it needs to pop up a refund for only 1 row
    // useEffect(() => {
    //     if (transactionIsVoidableTxnId) {
    //         if (transactionIsVoidableSuccess && transactionIsVoidableTxnId === transaction.id) {
    //             setShowPopUp(true);
    //         }
    //     }
    // }, [transactionIsVoidableSuccess, transactionIsVoidableTxnId]);

    useEffect(() => {
        if (transactionIsVoidableTxnId && transactionIsVoidableSuccess) {
            setShowPopUp(true);
        }
    }, [transactionIsVoidableSuccess, transactionIsVoidableTxnId]);

    // useEffect(() => {
    //     if (transactionIdRefund === transaction.id && transactionRefundSuccess) {
    //         setShowPopUp(false);
    //         setSuccessPopUp(true);
    //     }
    // }, [transactionIdRefund, transactionRefundSuccess]);


    useEffect(() => {
        if (transactionRefundSuccess) {
            setShowPopUp(false);
            setSuccessPopUp(true);
        }
    }, [transactionIdRefund, transactionRefundSuccess]);


    //
    // useEffect(() => {
    //     if (transactionIdRefund === transaction.id && transactionRefundError) {
    //         setShowPopUp(false);
    //         setShowErrorPopUp(true);
    //     }
    // }, [transactionIdRefund, transactionRefundError]);


    useEffect(() => {
        if (transactionRefundError) {
            setShowPopUp(false);
            setShowErrorPopUp(true);
        }
    }, [transactionIdRefund, transactionRefundError]);

    //
    // useEffect(() => {
    //     if (transactionIsVoidableTxnId === transaction.id && transactionIsVoidableError) {
    //         setShowPopUp(false);
    //         setShowErrorPopUp(true);
    //     }
    // }, [transactionIsVoidableTxnId, transactionIsVoidableError]);

    useEffect(() => {
        if (transactionIsVoidableError) {
            setShowPopUp(false);
            setShowErrorPopUp(true);
        }
    }, [transactionIsVoidableTxnId, transactionIsVoidableError]);


    const startRefund = (event, transactionId, selectedTxn) => {
        setTarget(event.target);
        dispatch(isTxnVoidable(transactionId));
        setTransactionForRefund(selectedTxn);
    }

    const doRefund = (transactionId, patients) => {
        dispatch(refundTxn(transactionId, patients));
    }

    const handleClosePopUp = () => {
        setShowPopUp(false);
        setTransactionForRefund({});
    };

    const handleSuccessClosePopUp = () => {
        dispatch(getTransaction(subdomain));
        setSuccessPopUp(false);
        setTransactionForRefund({});
    }

    const handleErrorClosePopUp = () => {
        setShowErrorPopUp(false);
        setTransactionForRefund({});
    }

    const DisplayRefund = (transaction) => {
        const txntxn = transaction && transaction.transaction;
        if (txntxn && txntxn.patientPayerRefundAmts && txntxn.patientPayerRefundAmts.length && txntxn.refundedDate) {
            let totalRefunded = 0;
            for (const refund of txntxn.patientPayerRefundAmts) {
                totalRefunded += parseFloat(refund.amountRefunded);
            }
            return <>
                ${amountFormatterWithZero(totalRefunded)}<br/>
                {moment(txntxn && txntxn.refundedDate).format("MM/DD/YYYY hh:mm a")}
            </>;
        } else {
            return <></>
        }
    }


    const displayPatientPayers = (row, property) => {

        return (row.patientPayer.map((v, i) => {
            let displayText = "";
            if (property === "patientName") {
                displayText = `${v.patientFirst ? v.patientFirst : ''} ${v.patientMiddle ? v.patientMiddle : ''} ${v.patientLast ? v.patientLast : ''}`;
            } else if (property === "amountPaid") {
                displayText = `$${amountFormatterWithZero(v[property])}`;
            } else {
                displayText = v[property];
            }
            return (<table
                key={i}
                style={{
                    tableLayout: "fixed", width: "100%",
                }}
            >
                <tbody>

                <tr style={{width: "100%"}}>
                    <td style={{width: "100%"}}>{displayText}</td>
                </tr>
                </tbody>
            </table>)
        }))
    };


    const getLastFour = (txnRecord) => {
        if (txnRecord.transactionsData &&
            txnRecord.transactionsData.ssl_card_number &&
            txnRecord.transactionsData.ssl_card_number > "") {
            return txnRecord.transactionsData.ssl_card_number.slice(-4);

        } else if (txnRecord.transactionsData &&
            txnRecord.transactionsData.ssl_bank_account_number &&
            txnRecord.transactionsData.ssl_bank_account_number > "") {
            return txnRecord.transactionsData.ssl_bank_account_number.slice(-4);

        } else {
            return ""
        }
    }

    const getPaymentMethod = (txnRecord) => {
        if (txnRecord.transactionsData &&
            txnRecord.transactionsData.ssl_card_type &&
            txnRecord.transactionsData.ssl_card_type > "") {
            if (txnRecord.transactionsData.ssl_card_type === "ELECTRONICCHECK") {
                return "eCheck"
            } else if (txnRecord.transactionsData.ssl_card_type === "CREDITCARD") {
                return txnRecord?.transactionsData?.ssl_card_short_description;
            }
        } else {
            return ""
        }
    }

    const print = (transaction) => {

        const docDefinition = {
            content: [
                {text: `Receipt for ${userAdmin.officeName}`, style: 'header'},
                ' ',
                ...transaction.patientPayer.map(o => {
                    return {
                        columns: [
                            {text: 'Patient:', width: '20%'},
                            {text: o.patientFirst + ' ' + o.patientLast + '(#' + o.patientNumber + ')', width: '80%'}
                        ],
                        style: 'column'
                    }
                }),
                {
                    columns: [
                        {text: 'Date:', width: '20%'},
                        {text: moment(transaction.createdAt).format('MM/DD/YYYY hh:mm a'), width: '80%'}
                    ],
                    style: 'column'
                },
                {
                    columns: [
                        {text: transaction.transactionsData.ssl_card_short_description + ':', width: '20%'},
                        {text: "xxxx" + transaction.transactionsData.ssl_card_number.slice(-4), width: '80%'}
                    ],
                    style: 'column'
                },
                {
                    columns: [
                        {text: 'Amount Paid:', width: '20%'},
                        {text: '$' + transaction.total, width: '80%'}
                    ],
                    style: 'column'
                },
                ' ',
                {text: 'Transaction ID:', style: 'subheader'},
                transaction.transactionId
            ],
            styles: {
                header: {
                    fontSize: 20,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 0, 0, 10]
                },
                subheader: {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                column: {
                    fontSize: 14,
                    margin: [0, 5, 0, 0]
                }
            }
        };

        pdfMake.createPdf(docDefinition).print();
    }

    const columns = React.useMemo(() => [// {
        {
            Header: "Payer Name",
            accessor: (row) => {
                console.dir('row #asdfw2lkj34');
                console.dir(row);
                return `${
                    row.userId && row.userId.payerLast || row.transactionsData.ssl_last_name || ""}, ${
                    row.userId && row.userId.payerFirst || row.transactionsData.ssl_first_name || ""
                } ${row.userId && row.userId.payerMiddle || ''}`
            },
        },
        {
            id: "payerEmail",
            Header: "Payer Email",
            // accessor: (row) => row.userId && row.userId.payerEmail,
            accessor: (row) => row.userId && row.userId.payerEmail && !row.userId.payerEmail.startsWith("*fake*") && row.userId.payerEmail
                || row.userId && row.userId.payerEmail && row.userId.payerEmail.startsWith("*fake*") && "No email"
                || "",
            // Cell: (props) => props.row.original.payerEmail.startsWith("*fake*") ? "" : props.row.original.payerEmail,
        }, {
            id: "payerCell",
            Header: "Payer Cell",
            accessor: (row) => row.userId && row.userId.payerCell && !row.userId.payerCell.startsWith("*fake*") && row.userId.payerCell
                || row.userId && row.userId.payerCell && row.userId.payerCell.startsWith("*fake*") && "No cell"
                || "",
        }, {
            Header: "Total Amount",
            accessor: (row) => `$${amountFormatterWithZero(row.transactionsData && row.transactionsData.ssl_amount)}`,
        }, {
            Header: "Date",
            accessor: (row) => row && row.createdAt,
            Cell: (props) => moment(props.row.original && props.row.original.createdAt).format("MM/DD/YYYY hh:mm a"),
        },
        {
            Header: "Payment Method",
            accessor: (row) => `${getPaymentMethod(row)} ...${getLastFour(row)} __ ${row && row.transactionsData && row.transactionsData.ssl_txn_id}`,
            Cell: (props) => <div>
                <span
                    title={props.row.original && props.row.original.transactionsData && props.row.original.transactionsData.ssl_txn_id}>
                    {getPaymentMethod(props.row.original)} ...{getLastFour(props.row.original)}
                </span>
            </div>,
        },
        {
            Header: "Patient Number",
            disableSortBy: true,
            accessor: (row) => row.patientPayer.map((ptPayer) => ptPayer.patientNumber).join("_"),
            Cell: (props) => displayPatientPayers(props.row.original, "patientNumber"),
        }, {
            Header: "Patient Name",
            disableSortBy: true,
            accessor: (row) => row.patientPayer.map((ptPayer) => `${ptPayer.patientFirst} ${ptPayer.patientMiddle} ${ptPayer.patientLast}`).join("_"),
            Cell: (props) => displayPatientPayers(props.row.original, "patientName"),
        }, {
            Header: "Patient Amount",
            accessor: (row) => row.patientPayer.map((ptPayer) => ptPayer.amountPaid).join("_"),
            Cell: (props) => displayPatientPayers(props.row.original, "amountPaid"),
        }, {
            Header: "Refund",
            disableFilters: true,
            disableSortBy: true,
            accessor: row => row.id,
            Cell: (props, tableProps) => {
                if (!props.row.original.isRefunded) {
                    return (<>
                            <button
                                style={{
                                    width: "70%",
                                    height: "50%"
                                }}
                                className="sos-custom-btn  sos-custom-btn-css"
                                onClick={(event) => startRefund(event, props.row.original.id, props.row.original)}
                            >{withButtonLoader(
                                "Refund",
                                transactionIsVoidableTxnId === props.row.original.id && transactionIsVoidableLoading,
                                themeColor1)}
                            </button>
                        </>
                    )
                } else {
                    return (props.row.original &&
                        <DisplayRefund transaction={props.row.original}/> || "");
                }
            }
        },
        {
            Header: "Print",
            disableFilters: true,
            disableSortBy: true,
            accessor: row => row.id,
            Cell: (props) => <button
                className="sos-custom-btn  sos-custom-btn-css"
                onClick={(event) => {
                    print(props.row.original);
                }}
            >Print
            </button>,
        }
    ], [transactionData]);


    return (<>
        <div className=" container-fluid">

            {transactionData && <Table
                columns={columns}
                data={transactionData}
                colWidths={["11%", "18%", "9%", "7%", "9%", "10%", "6%", "12%", "8%", "12%", "8%"]}
                noDataMessage={"No data"}
            />}
            {(
                <CustomPopoverRefund
                    title={"Refund"}
                    show={showPopUp}
                    isLoading={transactionRefundLoading}
                    isVoidable={transactionIsVoidable === true}
                    doRefund={doRefund}
                    close={handleClosePopUp}
                    setShowPopUp={setShowPopUp}
                    transaction={transactionForRefund}
                    themeColor1={themeColor1}
                />)}
            {showSuccessPopUp && (<CustomePopUp
                message={"Refund successful"}
                openModal={showSuccessPopUp}
                onCloseModal={handleSuccessClosePopUp}
            />)}

            {showErrorPopUp ? (<CustomePopUp
                message={"Error when refunding the transaction"}
                openModal={showErrorPopUp}
                onCloseModal={handleErrorClosePopUp}
            />) : ""}
        </div>
    </>);
};
export default TransactionsTable;
