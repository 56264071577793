import React, { useState, useEffect } from "react";
import { ChromePicker } from "react-color";
import { Overlay, Popover } from "react-bootstrap";
import "./theme.css";
import map from "lodash/map";
const ThemeSetting = (props) => {
  const { userAdmin, themeOpts, handleColorSave } = props || {};
  const { theme } = userAdmin || {};
  const [target, setTarget] = useState(null);
  const [themeIndex, setThemeIndex] = useState(null);
  const [hexColor, setHexColor] = useState([]);
  const [popOverLabel, setPopOverLabel] = useState({});

  const handleOpen = (e, index, colorInput) => {
    const arrr = [...hexColor];
    arrr[index] = { ...hexColor[index], isOpen: !colorInput.isOpen };
    setHexColor(arrr);
    setTarget(e.target);
    setThemeIndex(index);
    setPopOverLabel(colorInput);
  };
  const handleHidePopUp = (e) => {
    if (!(e.target.id === "inputColor")) {
      const arrr = [...hexColor];
      arrr[themeIndex] = { ...hexColor[themeIndex], isOpen: false };
      setHexColor(arrr);
      setTarget("");
    }
  };

  const handleColor = (index, e, from, name, color) => {
    if (color) {
      const arrr = [...hexColor];
      arrr[index] = { ...hexColor[themeIndex], defaultValue: e };
      setHexColor(arrr);
    } else {
      const arr = [...hexColor];
      arr[index].defaultValue = e.target.value;
      setHexColor(arr);
    }

    handleColorSave(hexColor);
  };
  useEffect(() => {
    if (theme) {
      map(themeOpts, (i) => {
        i.defaultValue = theme[i.name];
      });
    }
  }, [theme]);

  useEffect(() => {
    if (themeOpts) {
      setHexColor(themeOpts);
    }
  }, [themeOpts]);

  return (
    <>
      <div className="theme documents-card office-card center-content">
        <div className=" card-hoiz-text d-flex justify-content-between">
          <div className="card-head-font mb-1">Branding Colors</div>
        </div>
        {map(hexColor, (colorInput, index) => {
          return (
            <div className="row mb-3" key={index}>
              <label
                htmlFor="exampleInputEmail1"
                className="card-head-logo-font col-lg-6 col-md-6 theme-input"
              >
                {colorInput.label}
              </label>
              <span className="col-lg-6 col-md-6 position-relative">
                <input
                  className=" custom-input-border-css input-placeholder office-label"
                  id="themeInput"
                  name={colorInput.name}
                  aria-describedby="selectTheme"
                  placeholder={
                    colorInput.defaultValue || (theme && theme[colorInput.name])
                  }
                  value={colorInput.defaultValue}
                  onChange={(e) =>
                    handleColor(
                      index,
                      e,
                      colorInput.from,
                      colorInput.name,
                      false
                    )
                  }
                />
                <span
                  className="color-pic"
                  id={"inputColor"}
                  onClick={(e) => handleOpen(e, index, colorInput)}
                  style={{
                    background:
                      colorInput.defaultValue ||
                      (theme && theme[colorInput.name]) ||
                      "#000",
                  }}
                />
              </span>
              <div>
                <Overlay
                  show={colorInput.isOpen}
                  target={target}
                  placement="bottom"
                  trigger="click"
                  rootClose
                  onHide={(e) => handleHidePopUp(e)}
                >
                  <Popover id="doc" className="mt-2 popOver-theme">
                    <Popover.Header className="bg-white border-bottom-0">
                      {popOverLabel.label}
                    </Popover.Header>
                    <Popover.Body className="popover-button">
                      <ChromePicker
                        color={
                          (hexColor &&
                            hexColor[popOverLabel.key]?.defaultValue) ||
                          "#ffffff"
                        }
                        onChange={(color) => {
                          handleColor(
                            popOverLabel.key,
                            color && color.hex,
                            popOverLabel.from,
                            popOverLabel.name,
                            true
                          );
                        }}
                      />
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ThemeSetting;
