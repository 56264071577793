import React, {useState, useEffect, useRef, useCallback} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import {useNavigate, useParams} from "react-router-dom";
import get from "lodash/get";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import filter from "lodash/filter";
import {

    getPayerUser,
    updateUserData,
    reduxSetPayerUser,
    getPayerUserForAdminLookup,
    getPaymentsToken,
    savePaymentsDataAdmin,
    deleteCard,
    transactionError,
    getAdminUserData,
    updatePayerUserData,
    onTransactionLoading,
    getPayerUserById,

} from "../../Actions/authActions";
import {
    subDomain, amountFormatterWithZero,
} from "../../utils";
import {
    formatCreditCardNumber, formatExpirationDate, formatCVC,
} from "../Payments/utils";
import PaymentSuccessPopup from "../Payments/paymentSuccessPopup";
import CustomCheckbox from "../../Components/CustomCheckBox";
import CustomRadio from "../../Components/CustomRadio";
import {withButtonLoader} from "../../helpers";
import CustomPopover from "../../Components/CustomPopover";
import CustomPopoverPayment from "../../Components/CustomPopoverPayment";

import "./AdminDashboard.css";
import CustomePopUp from "../../Components/customePopUp";
import AdminEditCardInfo from "../AdminPaymentEdit";
import AdminAddCardInfo from "../AdminPaymentAdd";
import AdminPaymentAddSwipe from "../AdminPaymentAddSwipe";
import {
    CreditCardIcon, DownArrowIcon, ECheckIcon, // HhaCardIcon,
    RefreshIcon, MousePointerIcon, AddCardIcon, SwipeCardIcon, CrossIcon,
} from "../../Components/Icons";
import "../AdminPay/Pay.css";
import {PaymentFactory} from "../../Payments/paymentFactory";
import {SAVE_TRANSACTION_LOADING, SAVE_TRANSACTION_ERROR, SAVE_TRANSACTION_SUCCESS} from "../../constants";
import CardExpiration from "../../Components/Forms/CardExpiration";
import {showPaymentError} from "../../Payments/errorHandler";

const AdminPayerUser = (props) => {

    const {
        userAdmin, payerEmail, payerCell, patientNumber, sendBy, themeColor1, currentPage,
    } = props;
    const payerData = useSelector((state) => state.auth.payerUser);
    const [payerUser, setPayerUser] = useState({});
    const adminUser = useSelector((state) => state.auth.adminUser);
    const params = useParams();
    const subdomain = params.subdomain || subDomain();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {patientPayer} = payerUser || {};
    const transactionLoading = useSelector((state) => state.auth.transactionLoading);
    const [showPopUpAutomatic, setShowPopUpAutomatic] = useState(false);
    const [title, setTitle] = useState("");
    const [showPopUp, setShowPopUp] = useState(false);
    const [target, setTarget] = useState(null);
    const [showDefaultChecked, setShowDefaultChecked] = useState("");
    const [selectedCardId, setSelectedCardId] = useState("");
    const [addEditSwipeMode, setAddEditSwipeMode] = useState("");
    const [paymentType, setPaymentType] = useState("");
    const [paymentMsg, setPaymentMsg] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    const [selectedPayment, setSelectedPayment] = useState("");

    const [cardId, setCardId] = useState("");
    const [isPaymentSaveDetails, setIsPaymentSaveDetails] = useState(true);
    const [isDefault, setIsDefault] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [amountPaidPopup, setAmountPaidPopup] = useState(false);
    const [patientPayerInfo, setPatientPayerInfo] = useState([]);
    const [paymentData, setPaymentData] = useState({
        name: "", amount: "", card: "", exp: "", cvv: "",
    });
    const [accountType, setAccountType] = useState("");

    const [lastFourNumbers, setLastFourNumbers] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [disguisedSwipeNumber, setDisguisedSwipeNumber] = useState("");
    const [swiper, setSwiper] = useState(null);
    const [manual, setManual] = useState(null);
    const [isMultipleProviders, setIsMultipleProviders] = useState(false);


    const handleChangePaymentType = function (paymentType) {
        setPaymentType(paymentType);
    }

    const initSwipePayment = () => {
        const factory = new PaymentFactory();
        const newSwiper = factory.create('SWIPE', {
            subdomain, adminUser, payerUser
        });
        newSwiper.init();
        setSwiper(newSwiper);
        document.addEventListener("cardSwiped", (e) => {
            setFirstName(e.detail.firstName);
            setLastName(e.detail.lastName);
            setLastFourNumbers(e.detail.lastFourNumbers);
            setDisguisedSwipeNumber(e.detail.disguisedSwipeNumber);
        });
    }

    const getNewPatientPayer = () => {
        const balancesPaid = [];
        map(patientPayerInfo, (i) => {
            balancesPaid.push({
                patientFirst: i.patientFirst,
                patientLast: i.patientLast,
                patientNumber: i.patientNumber,
                patientPayerId: i.patientPayerId,
                amountPaid: i.accountBalance < 0 ? 0 : i.accountBalance,
            });
        });
        return balancesPaid;
    }

    const postManualPayment = (data) => {
        //this postManualPayment handles at least the following
        // (1) eCheck if not isPaymentSaveDetails
        // (2) credit card if isPaymentSaveDetails
        // (3) credit card if !isPaymentSaveDetails
        // ?? maybe saved payment methods ??

        if (data.response.ssl_result === "0") {


            props.savePaymentsDataAdmin({
                ...data,
                paymentType,
                //should get rid of the following 4 lines in order to have a single source
                //of truth: transactionsData
                ssl_card_short_description: data.ssl_card_short_description,
                ssl_card_number: data.ssl_card_number,
                transactionId: data.transactionId, // dateTimeStampUTC: new Date().toISOString(),
                ssl_token: data.ssl_token,
                // userId: payerUser && payerUser.id || payerUser._id,
                userId: payerUser && payerUser.id,
                transactionsData: data.response,
                sosCustomersId: get(userAdmin, "_id") || "",
                patientPayer: getNewPatientPayer(),
                isAdmin: true,
                addedBy: {sosCustomersId: userAdmin.id},
                initiatedBy: {sosCustomersId: userAdmin.id},
                subdomain,
            }, dispatch).then((res) => {
                if (res) {
                    localStorage.setItem('PENDING_TRANSACTION', JSON.stringify({
                        payerEmail: payerUser.payerEmail,
                        patientPayer: getNewPatientPayer(),
                        createdAt: data.response.ssl_txn_time,
                        transactionsData: data.response,
                        total: data.response.ssl_amount,
                        transactionId: data.transactionId,
                    }));
                    const updatedPatientPayers = patientPayer.map(ptPayer => {
                        const matchingPatientPayer = patientPayerInfo.find(ppInfo => ppInfo.patientPayerId === ptPayer.patientPayerId);
                        const unpaidBalance = parseFloat(ptPayer.accountBalance) - parseFloat(matchingPatientPayer.accountBalance);
                        return {
                            ...ptPayer, accountBalance: unpaidBalance,
                        }
                    });
                    const updatedPayerUser = {
                        ...payerUser, patientPayer: updatedPatientPayers,
                    };
                    localStorage.setItem("payerUser", JSON.stringify(updatedPayerUser));
                    dispatch(reduxSetPayerUser(updatedPayerUser));
                    setPayerUser(updatedPayerUser);
                    setPatientPayerInfo(updatedPatientPayers);
                    if (data.ssl_get_token === "Y" && !data.ssl_token_response === "SUCCESS") {
                        showResult("txnApprovedButPmtMethodNotStored", data);
                    } else {
                        showResult("approval");
                    }
                    setTimeout(() => {
                        dispatch(getPayerUserById(payerUser));
                        setPaymentType("");
                    }, 1000);
                }
            });

            setPaymentData({
                name: "", cardnumber: "", expiry: "", cvv: "",
            });
        } else {
            showResult("error", data.response);
            console.error("Err #dhduw4eu");
            dispatch({type: SAVE_TRANSACTION_ERROR});
        }

    }

    const initManualPayment = () => {
        const factory = new PaymentFactory();
        const newManual = factory.create('MANUAL', {});
        setManual(newManual);
    }

    const destroySwiper = () => {
        if (swiper) {
            swiper.destroy();
        }
    }

    const updatePaymentType = function (paymentType) {
        setPaymentType(paymentType);
        if (paymentType === "swipeCard") {
            initSwipePayment();
        } else {
            destroySwiper();
            initManualPayment();
        }
    }

    useEffect(() => {
        window.scrollTo({top: 0, behavior: "instant"});
        initManualPayment();
    }, []);

    useEffect(() => {
        if (payerData?.length) {
            setPayerUser(payerData);
        } else {

            const localPayerUserObj = localStorage.getItem("payerUser");
            if (typeof localPayerUserObj !== undefined) {
                const payerLocalStorage = JSON.parse(localStorage.getItem("payerUser"));
                setPayerUser(payerLocalStorage);
            } else {
                alert("undefined err #hu78273g");
            }
        }
    }, [payerData]);

    useEffect(() => {
        if (payerUser && payerUser.paymentMethods) {
            const {paymentMethods} = payerUser || "";
            const filterData = filter(paymentMethods, (p) => p.isDefault)[0];
            if (filterData && filterData._id) {
                setSelectedCardId(filterData._id);
            }
            setSelectedPayment(filterData);
        }
    }, [payerUser]);

    useEffect(() => {
        if (!Object.keys(userAdmin).length && currentPage === "payments") {
            dispatch(getAdminUserData(subdomain));
        }
    }, [!userAdmin]);

    useEffect(() => {
        if (payerUser) {
            setShowDefaultChecked(payerUser.paymentMethods);
        }
    }, [payerUser]);

    useEffect(() => {
        const PayerUser = JSON.parse(localStorage.getItem("payerUser"));
        if (PayerUser && PayerUser.patientPayer) {
            setPatientPayerInfo(PayerUser.patientPayer);
        }

        // -----  -----  -----  -----  -----  -----
        if (patientPayer && patientPayer.length > 0) {
            setIsMultipleProviders((() => {
                for (const ptPayer of patientPayer) {
                    if (
                        ptPayer.providerNumber !== patientPayer[0].providerNumber
                        || ptPayer.providerFirst !== patientPayer[0].providerFirst
                        || ptPayer.providerLast !== patientPayer[0].providerLast
                    ) {
                        return true;
                    }
                }
                return false;
            })());
        }
    }, [patientPayer]);

    const totalAmounts = () => {
        const total = sumBy(map(patientPayer, (i) => ({
            ...i, accountBalance: i ? Math.max(parseFloat(i.accountBalance), 0) : 0,
        })), "accountBalance");
        return total;
    };

    const totalAmountsPaid = () => {
        let total = 0;
        for (let patient of patientPayerInfo) {
            if (patient && patient.accountBalance && !isNaN(patient.accountBalance)) {
                total += Math.max(parseFloat(patient.accountBalance), 0);
            }
        }
        return total;
    };

    const onRemoveDoc = () => {
        setShowPopUp(!showPopUp);
        if (cardId) {
            const data = {
                cardId, userId: payerUser.id,
            };
            props.deleteCard(subdomain, data).then((res) => {
                if (res.payload) {
                    dispatch(getPayerUserById(payerUser));
                    // dispatch(getPayerUserForAdminLookup(data1, navigate));
                }
            });
        }
    };

    const handleAmountBeingPaid = (type) => {
        if (totalAmountsPaid() === 0) {
            setAmountPaidPopup(!amountPaidPopup);
        } else {
            if (type === "swipeCard") {
                initSwipePayment();
            } else {
                destroySwiper();
                initManualPayment();
            }
            setPaymentType(type);
        }
    };

    const handleClosePopUp = () => {
        setShowPopUp(false);
        setCardId("");
        setAmountPaidPopup();
    };

    const handlePopUp = (event, i) => {
        setShowPopUp(!showPopUp);
        setTarget(event.target);
        setCardId(i._id);
    };


    const createError = function (err) {
        let error = [err.errorCode, err.errorName, err.errorMessage];
        let errorMessage = error.join(' ').trim();
        if (errorMessage.length > 0) {
            return errorMessage;
        }
        return err.ssl_result_message;
    }

    function showResult(status, err) {
        if (status === "approval") {
            setPaymentMsg("successful");
            setPaymentStatus("approval");
        } else if (status === "txnApprovedButPmtMethodNotStored") {
            setPaymentMsg("The payment was successful. Unable to store payment method at this time.");
            setPaymentStatus("approval");
        } else if (status === "failure" || status === "error") {
            setPaymentMsg(`${showPaymentError(err)}`);
            setPaymentStatus("error");
        }
        setOpenModal(true);
    }

    const handleEditInfo = () => {
        setAddEditSwipeMode("edit");
        setSelectedPayment(selectedPayment);
    };

    const handleAddCardInfo = () => {
        setAddEditSwipeMode("add");
        const newPaymentMethodObj = {
            "addedBy": {
                "sosCustomersId": userAdmin.id
            },
            "paymentType": "creditCard",
            "ssl_first_name": "",
            "ssl_last_name": "",
            "ssl_company": "",
            "ssl_bank_account_number": "",
            "ssl_bank_account_type": "",
            "ssl_card_number": "",
            "ssl_card_short_description": "",
            "ssl_token": "",
            "isDefault": false,
            "_id": ""
        }
        // setSelectedPayment(selectedPayment);
        setSelectedPayment(newPaymentMethodObj);
    };

    const handleECheck = () => {
        setAddEditSwipeMode("add");
        setSelectedPayment({
            "addedBy": {
                "sosCustomersId": userAdmin.id
            },
            "paymentType": "eCheck",
            "firstName": "",
            "lastName": "",
            "companyName": "",
            "bankAccountType": "",
            "bankName": "",
            "bankRoutingNumber": "",
            "bankAccountNumber": "",
            "ssl_recurring_id": "",
            "isDefault": false,
            "_id": ""
        });
    };
    const handleSwipeAddCardInfo = () => {

        setAddEditSwipeMode("swipe");

        const newPaymentMethodObj = {
            "addedBy": {
                "sosCustomersId": userAdmin.id
            },
            "paymentType": "creditCard",
            "ssl_first_name": "",
            "ssl_last_name": "",
            "ssl_company": "",
            "ssl_bank_account_number": "",
            "ssl_bank_account_type": "",
            "ssl_card_number": "",
            "ssl_card_short_description": "",
            "ssl_token": "",
            "isDefault": false,
            "_id": ""
        }
        // setSelectedPayment(selectedPayment);
        setSelectedPayment(newPaymentMethodObj);
    };

    const handleInputChange = ({target}) => {
        if (target.name === "cardnumber") {
            target.value = formatCreditCardNumber(target.value);
        } else if (target.name === "expiry") {
            target.value = formatExpirationDate(target.value);
        } else if (target.name === "cvc") {
            target.value = formatCVC(target.value);
        } else if (target.name === "accountType") {
            setAccountType(target.value)
        }
        setPaymentData({...paymentData, [target.name]: target.value});
    };

    const handleSubmit = async (e) => {
        dispatch({type: SAVE_TRANSACTION_LOADING});
        let formData;
        if (e.target.elements) {
            formData = [...e.target.elements]
                .filter((d) => d.name)
                .reduce((acc, d) => {
                    if (["isPaymentSaveDetails", "isDefault"].includes(d.name)) {
                        acc[d.name] = d.value === "on";
                    } else {
                        acc[d.name] = d.value;
                    }
                    return acc;
                }, {});
        } else {
            formData = paymentData;
        }

        const payerEmail = get(payerUser, "payerEmail") || "";
        let data = {
            amount: amountFormatterWithZero(totalAmountsPaid()), payerEmail, subdomain,
        };
        if (!paymentType) {
            if (!selectedPayment) {
                showResult("error", 'Please select a payment method.');
                dispatch({type: SAVE_TRANSACTION_ERROR});
                return;
            }
            data.isDefault = selectedPayment.isDefault;
            if (selectedPayment.ssl_recurring_id) {
                data = {
                    ...data,
                    isEcheck: true,
                    firstName: selectedPayment.ssl_first_name,
                    lastName: selectedPayment.ssl_last_name,
                    companyName: selectedPayment.ssl_company,
                    bankAccountType: selectedPayment.ssl_bank_account_type,
                    ssl_recurring_id: selectedPayment.ssl_recurring_id
                }
            } else {
                data = {
                    ...data,
                    isEcheck: false,
                    ssl_token: selectedPayment.ssl_token
                }
            }
        } else {
            data.isPaymentSaveDetails = isPaymentSaveDetails;
            data.isDefault = isDefault;
            if (paymentType === "eCheck") {
                const isPersonalAccount = formData.accountType === "0";
                const isBusinessAccount = formData.accountType === "1";
                data = {
                    ...data,
                    isEcheck: true,
                    firstName: isPersonalAccount ? formData.accountFirstName : "",
                    lastName: isPersonalAccount ? formData.accountLastName : "",
                    companyName: isBusinessAccount ? formData.companyName : "",
                    bankAccountType: formData.accountType,
                    bankName: formData.bankName,
                    bankRoutingNumber: formData.bankRoutingNumber,
                    bankAccountNumber: formData.bankAccountNumber
                }
            } else {
                data = {
                    ...data,
                    isEcheck: false,
                    firstName: formData.cardFirstName || "",
                    lastName: formData.cardLastName || "",
                    expirationDate: formData && formData.expiry ? formData.expiry.replace(/\//g, "") : "",
                    cardNumber: formData && formData.cardnumber,
                    cvv: formData && formData.cvc ? formData.cvc : ""
                };
            }
        }


        manual.data = {...manual.data, subdomain, adminUser, payerUser, ...data};
        manual.data.patientPayer = getNewPatientPayer();
        manual.pay(e).then((res) => {
            if (res.data) {
                res = {...res, ...res.data};
            }
            if (!res.response) {
                res.response = {...res};
            }
            if (data.isEcheck && data.isPaymentSaveDetails || data.isEcheck && selectedPayment.ssl_recurring_id) {
                if (res.response.ssl_result === "0") {
                    //ssl_result==="0" if it's a successful transaction
                    let delay;
                    if (res.response.ssl_recurring_id !== "") {
                        //ssl_recurring_id !=="" if eCheck payment method is successfully stored
                        showResult("approval");
                        delay = 1000;
                    } else {
                        showResult("txnApprovedButPmtMethodNotStored", res.response);
                        delay = 2000;
                    }
                    setTimeout(() => {
                        dispatch(getPayerUserById(payerUser));
                        dispatch({type: SAVE_TRANSACTION_SUCCESS});
                        setPaymentType("");
                        setOpenModal(false);
                    }, delay);
                } else {
                    showResult("failure", res.response);
                    dispatch({type: SAVE_TRANSACTION_ERROR});
                }
            } else {
                //this else block handles at least the following
                // (1) eCheck if not isPaymentSaveDetails
                // (2) credit card if isPaymentSaveDetails
                // (3) credit card if !isPaymentSaveDetails
                // ?? maybe saved payment methods ??
                res.isDefault = data.isDefault;
                res.isPaymentSaveDetails = data.isPaymentSaveDetails;
                postManualPayment(res);
                dispatch({type: SAVE_TRANSACTION_SUCCESS});
            }
        }).catch((err) => {
            console.dir("err line 500");
            console.dir(err);
            showResult("error", err);
            dispatch({type: SAVE_TRANSACTION_ERROR});
        });
    };

    const onCloseModal = () => {
        setOpenModal(false);
    };

    const onDefaultChecked = (e, c, i) => {
        let payerUserchecked = [...payerUser.paymentMethods];
        let updateDefaultCard = [];
        const updatedPayerUser = payerUser;
        const selectedPayerId = e.target.checked ? payerUserchecked[i]._id : "";
        setShowDefaultChecked(payerUserchecked[i]);
        setSelectedCardId(selectedPayerId);

        map(payerUserchecked, (p) => {
            const defaultCard = p;
            if (p._id === c._id) {
                defaultCard.isDefault = e.target.checked;
            } else {
                defaultCard.isDefault = false;
            }
            updateDefaultCard.push(defaultCard);
        });
        setShowDefaultChecked(updateDefaultCard);

        updatedPayerUser.paymentMethods = showDefaultChecked;
        dispatch(updateUserData(updatedPayerUser));
        localStorage.setItem("payerUser", JSON.stringify(updatedPayerUser));
    };

    const onAmountChangeHandler = (e, index) => {
        const inputValues = [...patientPayerInfo];
        const value = e.target.value
            .replace(/[^0-9.]/g, "")
            .replace(".", "x")
            .replace(/\./g, "")
            .replace("x", ".");
        if (inputValues && inputValues[index]) {
            inputValues[index].accountBalance = value;
        }
        setPatientPayerInfo(inputValues);
    };

    const handleClick = (e) => {
        e.target.select();
    };

    const handlePay = async (e) => {
        swiper.data.isPaymentSaveDetails = isPaymentSaveDetails;
        swiper.data.isDefault = isDefault;
        swiper.data.patientPayer = getNewPatientPayer();
        swiper.data.amount = amountFormatterWithZero(totalAmountsPaid());
        let res = await swiper.pay(e);
        // if (res.data.message === 'Transaction successful') {//david
        if (res.data.message === 'Transaction successful') {
            showResult("approval");
            setTimeout(() => {
                dispatch(getPayerUserById(payerUser));
                dispatch({type: "PAYUSER_SUCCESS"});
                setPaymentType("");
                setOpenModal(false);
                swiper.destroy();
            }, 1000);
        } else {
            showResult("failure", res.data);
        }
    }

    const displayName = (patient) => {
        if (patient.patientFirst.length > 10 || patient.patientLast.length > 10) {
            return <p
                className="card-inner-text-font-payer payer-name-font">{patient && `${patient.patientFirst || ""} ${patient.patientLast}`}</p>;
        } else {
            return <p
                className="payer-name-font">{patient && `${patient.patientFirst || ""} ${patient.patientLast}`}</p>
        }
    }

    const onClickPaymentMethod = (c) => {
        setSelectedCardId(c._id);
        setSelectedPayment(c);
    };

    const getPayerAmount = (index) => {
        if (!patientPayerInfo) {
            return null;
        }
        if (patientPayerInfo.length === 0) {
            return null;
        }
        return patientPayerInfo[index].accountBalance;
    }

    const formatValue = (e) => {
        if (e && e.target && e.target.value) {
            e.target.value = amountFormatterWithZero(e.target.value);
        }
    }


    return (<>

        <div className="intake-content-admin">
            <div id="parentForSwipeInput"></div>
            <div className="mb-4 payerinfo">
                <div className="patientId col-md-5">
                    {payerUser && payerUser.payerFirst}{` `}{payerUser && payerUser.payerLast}
                </div>
                <div className="confirmation-email col-md-7 ">
                    {payerUser?.payerEmail?.startsWith("*fake*") ? `This payer has no email in the system for receipts` : `Receipts will be sent to ${payerUser && payerUser.payerEmail}`}
                </div>
            </div>
            <div className=" intake-content-inner">
                <div
                    className={classNames(" homeexisting-patient-box pay-card position-relative intake-content-box", patientPayer ? "" : "empty-amount-due")}
                >
                    <div className="justify-content-between">
                        <div className="d-flex hommeexisting-hoiz-text">
                            <div className="pay-left">
                                Account Balance
                            </div>
                            <div className="pay-center">
                                Payment Amount
                            </div>
                        </div>
                        {/* <div className="hommeexisting-large-text hommeexisting-hoiz-text">
                ID
              </div> */}
                    </div>

                    <div>

                        <div className="homeexisting-inner-card">

                            {map(patientPayer, (a, index) => {
                                return (<span key={index + a.patientPayerId + parseInt(a.accountBalance)}>
                      <div className="d-flex align-items-center pay-large-inner justify-content-between">
                        <p
                            className="pay-account-balance"
                            style={{
                                paddingLeft: 0, fontWeight: "normal"
                            }}
                        >{a && `$${amountFormatterWithZero(Math.max(a.accountBalance, 0))}`}</p>
                        <input
                            type="text"
                            className="pay-control custom-input-border-css"
                            id={`accountbalance-${index}`}
                            aria-describedby="emailHelp"
                            placeholder="$0.00"
                            onBlur={(e) => formatValue(e)}
                            // value={`${getPayerAmount(index) || a.accountBalance || ""}`}
                            defaultValue={`${amountFormatterWithZero(Math.max(a.accountBalance, 0))}`}
                            onChange={(e) => onAmountChangeHandler(e, index)}
                            onClick={(e) => handleClick(e)}
                        />
                          {displayName(a)}

                      </div>
                      <div className="mt-1 provider-style credit-balance">
                        {a.accountBalance < 0 && `$${amountFormatterWithZero(-1 * a.accountBalance) || ""} CREDIT BALANCE`}
                      </div>
                     <div className="mt-1 provider-style">
                          {a && isMultipleProviders && (a.providerFirst > "" || a.providerLast > "") &&
                              `Provider: ${a.providerFirst || ""} ${a.providerLast || ""}`
                          }
                    </div>
                      <div className="divider"/>
                    </span>);
                            })}
                            <div className="mt-1 provider-style">
                                {patientPayer && patientPayer.length > 0 && !isMultipleProviders &&
                                    (patientPayer[0].providerFirst > "" || patientPayer[0].providerLast > "") &&
                                    `Provider: ${patientPayer[0].providerFirst || ""} ${patientPayer[0].providerLast || ""}`
                                }
                            </div>
                            <div className="d-flex hommeexisting-large-inner-text">
                                <p>Total</p>
                                <p>${amountFormatterWithZero(totalAmountsPaid())}</p>
                            </div>
                            {/* <div className="divider" />
                                    <div className="d-flex justify-content-end">
                                    <button type="submit" className="pay-custom-btn">
                                        Add to amount being paid
                                    </button>
                                    </div> */}
                        </div>

                    </div>
                </div>

                <div className="payment-patient-inner position-relative min-height-100-vh">
                    {addEditSwipeMode === "" && <div>
                        <div
                            className="hommeexisting-large-text hommeexisting-hoiz-text d-flex  justify-content-between">
                            <p>Amount Being Paid</p>
                            <p>Total Balance</p>

                        </div>
                        <div className="pay-inner-text container">
                            <div className="payment-amount d-flex  justify-content-between">
                                <p className="ml-5 payment-amount-style">
                                    ${amountFormatterWithZero(totalAmountsPaid())}
                                </p>
                                <h6>${amountFormatterWithZero(totalAmounts())}</h6>
                            </div>
                            {paymentType === "creditCard" || paymentType === "eCheck" || paymentType === "swipeCard" ? (
                                <div>
                                    <div style={{display: "flex"}}>
                                        <div className="payment-patient-style">
                                            Select your payment method

                                        </div>
                                        <div className="cross-btn"
                                            // style={{marginTop: "-10px"}}
                                             onClick={() => {
                                                 updatePaymentType("");
                                             }}>
                                            <CrossIcon/>
                                        </div>
                                    </div>
                                    <div className=" payment-toggleable">
                                        <ul
                                            className="justify-content-center mb-3 nav nav-pills"
                                            id="pills-tab"
                                            role="tablist"
                                        >
                                            {adminUser.displayECheck && (<li
                                                className={classNames("nav-item chekbox-custom mr-2 me-2 rectangle nav-item-margin", paymentType === "eCheck" ? "active" : "")}
                                                role="presentation"
                                                onClick={() => updatePaymentType("eCheck")}
                                            >
                                                <a
                                                    className={`nav-link text-center`}
                                                    id="pills-home-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-home"
                                                    role="tab"
                                                    aria-controls="pills-home"
                                                    aria-selected="true"
                                                >
                                                    <CustomRadio
                                                        // defaultChecked={paymentType === "eCheck"}
                                                        checked={paymentType === "eCheck"}
                                                        onChange={(e) => handleChangePaymentType("eCheck")}
                                                    />
                                                    <div className="check-btn">
                                                        <ECheckIcon/>
                                                    </div>
                                                    <div className="payment-checkbox-inner">eCheck</div>
                                                </a>
                                            </li>)}
                                            <li
                                                className={classNames("nav-item chekbox-custom mr-2 me-2 rectangle nav-item-margin", paymentType === "creditCard" ? "active" : "")}
                                                role="presentation"
                                                onClick={() => updatePaymentType("creditCard")}
                                            >
                                                <a
                                                    className={`nav-link text-center`}
                                                    id="pills-profile-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-profile"
                                                    role="tab"
                                                    aria-controls="pills-profile"
                                                    aria-selected="false"
                                                >
                                                    <CustomRadio
                                                        // defaultChecked={paymentType === "creditCard"}
                                                        checked={paymentType === "creditCard"}
                                                        onChange={(e) => handleChangePaymentType("creditCard")}
                                                    />
                                                    <div className="check-btn">
                                                        <CreditCardIcon/>
                                                    </div>
                                                    <div className="payment-checkbox-inner">
                                                        Credit/Debit Card
                                                    </div>
                                                </a>
                                            </li>
                                            <li
                                                className={classNames("nav-item chekbox-custom rectangle me-2 nav-item-margin", paymentType === "swipeCard" ? "active" : "")}
                                                role="presentation"
                                                onClick={() => updatePaymentType("swipeCard")}
                                            >
                                                <a
                                                    className={`nav-link text-center`}
                                                    id="pills-contact-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-contact"
                                                    role="tab"
                                                    aria-controls="pills-contact"
                                                    aria-selected="false"
                                                >
                                                    <CustomRadio
                                                        // defaultChecked={paymentType === "swipeCard"}
                                                        checked={paymentType === "swipeCard"}
                                                        onChange={(e) => handleChangePaymentType("swipeCard")}
                                                    />
                                                    <div className="check-btn">
                                                        {/* <HhaCardIcon /> */}
                                                        <SwipeCardIcon
                                                            style={{width: "31px", height: "21px"}}
                                                        />
                                                    </div>
                                                    <div className="payment-checkbox-inner">
                                                        {/* HHA/HHS card */}
                                                        Swipe Credit/Debit
                                                    </div>
                                                </a>
                                            </li>

                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div
                                                className={`tab-pane ${paymentType === "eCheck" ? "fade show active" : ""}`}
                                                id="pills-home"
                                                role="tabpanel"
                                                aria-labelledby="pills-home-tab"
                                            >
                                                <div className="sos-content payment-inner-toggleable">


                                                    <form onSubmit={(e) => handleSubmit(e)}>
                                                        <div className="dropdown custom-dropdown mb-3">
                                                            <select
                                                                name="accountType"
                                                                className="form-control custom-input-border-css"
                                                                required
                                                                onChange={(e) => handleInputChange(e)}
                                                            >
                                                                <option value="">Account Type</option>
                                                                <option value="0">Personal</option>
                                                                <option value="1">Business</option>
                                                            </select>
                                                            <div className="down-arrow">
                                                                <DownArrowIcon/>
                                                            </div>
                                                        </div>
                                                        {accountType == "0" ? (<div className="form-group mb-3">
                                                            <label
                                                                htmlFor="exampleInputBankName1"
                                                                className="form-label payment-form-label"
                                                            >
                                                                Name On Account
                                                            </label>
                                                            <div className="row g-2">
                                                                <div className="col-md">
                                                                    <div
                                                                        className="payment-controlpanl-style">
                                                                        <input
                                                                            type="text"
                                                                            name="accountFirstName"
                                                                            className="form-control custom-input-border-css"
                                                                            id="exampleInputName3"
                                                                            aria-describedby="nameHelp"
                                                                            placeholder="First Name"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            required
                                                                        />

                                                                    </div>
                                                                </div>
                                                                <div className="col-md">
                                                                    <div>
                                                                        <input
                                                                            type="text"
                                                                            name="accountLastName"
                                                                            className="form-control custom-input-border-css"
                                                                            id="exampleInputName3"
                                                                            aria-describedby="nameHelp"
                                                                            placeholder="Last Name"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>) : (<div className="form-group mb-3">
                                                            <label
                                                                htmlFor="exampleInputBankName1"
                                                                className="form-label payment-form-label"
                                                            >
                                                                Name On Account
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="companyName"
                                                                required
                                                                className="form-control custom-input-border-css"
                                                                id="exampleInputName1"
                                                                aria-describedby="nameHelp"
                                                                placeholder="Enter Name on the Account"
                                                            />

                                                        </div>)}

                                                        <div className="form-group mb-3">
                                                            <label
                                                                htmlFor="exampleInputEmail1"
                                                                className="form-label payment-form-label"
                                                            >
                                                                Bank Name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control custom-input-border-css"
                                                                id="exampleInputBankName"
                                                                placeholder="Enter Bank Name"
                                                                aria-describedby="bankNameHelp"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label
                                                                htmlFor="exampleInputPassword1"
                                                                className="form-label payment-form-label"
                                                            >
                                                                Bank Routing Number
                                                            </label>
                                                            <input
                                                                type="text"
                                                                id="exampleInputBankNumber1"
                                                                name="bankRoutingNumber"
                                                                className="form-control custom-input-border-css"
                                                                placeholder="Enter Bank Routing Number"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label
                                                                htmlFor="exampleInputPassword1"
                                                                className="form-label payment-form-label"
                                                            >
                                                                Bank Account Number
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="bankAccountNumber"
                                                                className="form-control custom-input-border-css"
                                                                id="exampleInputAccountNumber1"
                                                                placeholder="Enter Bank Account Number"
                                                                required
                                                            />
                                                        </div>


                                                        <div className="checkbox-list mt-3">
                                                            <label
                                                                className="label-check font-weight-light float-end">
                                                                <CustomCheckbox
                                                                    name="isPaymentSaveDetails"
                                                                    checked={isPaymentSaveDetails}
                                                                    onChange={(e) => setIsPaymentSaveDetails(!isPaymentSaveDetails)}
                                                                />
                                                                <span>
                                      Save these payment details for future payments
                                    </span>
                                                            </label>
                                                            <div className="payment-checbox">
                                                                <label
                                                                    className="font-weight-light label-check ml-xl-5">
                                                                    <CustomCheckbox
                                                                        name="isDefault"
                                                                        checked={isDefault}
                                                                        onChange={(e) => setIsDefault(!isDefault)}
                                                                    />
                                                                    Set as default payment method
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex justify-content-center">
                                                            <button
                                                                type="submit"
                                                                className="sos-mack-btn sos-custom-btn-css"
                                                                disabled={transactionLoading}
                                                            >
                                                                {withButtonLoader("Make a Payment", transactionLoading)}
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div
                                                className={`tab-pane ${paymentType === "creditCard" ? "fade show active" : ""}`}
                                                id="pills-profile"
                                                role="tabpanel"
                                                aria-labelledby="pills-profile-tab"
                                            >
                                                <div className="sos-content payment-inner-toggleable">
                                                    <form onSubmit={(e) => handleSubmit(e)}>
                                                        <div className="form-group mb-3">
                                                            <label
                                                                htmlFor="exampleInputEmail1"
                                                                className="form-label payment-form-label"
                                                            >
                                                                Cardholder Name
                                                            </label>


                                                            <div className="row g-2">
                                                                <div className="col-md">
                                                                    <div className="payment-controlpanl-style">
                                                                        <input
                                                                            type="text"
                                                                            name="cardFirstName"
                                                                            className="form-control custom-input-border-css"
                                                                            id="exampleInputName3"
                                                                            aria-describedby="nameHelp"
                                                                            placeholder="First Name"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            required
                                                                        />

                                                                    </div>
                                                                </div>
                                                                <div className="col-md">
                                                                    <div>
                                                                        <input
                                                                            type="text"
                                                                            name="cardLastName"
                                                                            className="form-control custom-input-border-css"
                                                                            id="exampleInputName3"
                                                                            aria-describedby="nameHelp"
                                                                            placeholder="Last Name"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label
                                                                htmlFor="exampleInputPassword1"
                                                                className="form-label payment-form-label"
                                                            >
                                                                Credit Card Number
                                                            </label>
                                                            <input
                                                                type="tel"
                                                                name="cardnumber"
                                                                className="form-control custom-input-border-css"
                                                                placeholder="Enter Credit Card Number"
                                                                pattern="[\d| ]{15,22}"
                                                                required
                                                                onChange={(e) => handleInputChange(e)}
                                                            />
                                                        </div>
                                                        <div className="row g-2">
                                                            <div className="col-md">
                                                                <div className="payment-controlpanl-style">
                                                                    <div>
                                                                        <label className="payment-form-label">
                                                                            Expiry Date
                                                                        </label>
                                                                    </div>
                                                                    <CardExpiration
                                                                        onChange={(e) => handleInputChange(e)}
                                                                    ></CardExpiration>
                                                                    {/*<input*/}
                                                                    {/*    type="tel"*/}
                                                                    {/*    className="form-control custom-input-border-css"*/}
                                                                    {/*    id="creidtExpiryDate"*/}
                                                                    {/*    placeholder="mm/yy"*/}
                                                                    {/*    name="expiry"*/}
                                                                    {/*    pattern="\d\d/\d\d"*/}
                                                                    {/*    required*/}
                                                                    {/*    onChange={(e) => handleInputChange(e)}*/}
                                                                    {/*/>*/}
                                                                </div>
                                                            </div>
                                                            <div className="col-md">
                                                                <div>
                                                                    <div>
                                                                        <label className="payment-form-label">
                                                                            Security Code
                                                                        </label>
                                                                    </div>
                                                                    <input
                                                                        type="tel"
                                                                        className="form-control custom-input-border-css"
                                                                        placeholder="Enter CVC"
                                                                        name="cvc"
                                                                        pattern="\d{3,4}"
                                                                        required
                                                                        onChange={(e) => handleInputChange(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="checkbox-list mt-3">
                                                            <label
                                                                className="label-check font-weight-light float-end ">
                                                                <CustomCheckbox
                                                                    name="isPaymentSaveDetails"
                                                                    checked={isPaymentSaveDetails}
                                                                    onChange={(e) => setIsPaymentSaveDetails(!isPaymentSaveDetails)}
                                                                />
                                                                Save these payment details for future payments
                                                            </label>
                                                            <div className="payment-checbox">
                                                                <label
                                                                    className="font-weight-light label-check ml-xl-5">
                                                                    <CustomCheckbox
                                                                        name="isDefault"
                                                                        checked={isDefault}
                                                                        onChange={(e) => setIsDefault(!isDefault)}
                                                                    />
                                                                    Set as default payment method
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-center">
                                                            <button
                                                                type="submit"
                                                                className="sos-mack-btn sos-custom-btn-css"
                                                                disabled={transactionLoading}
                                                            >
                                                                {withButtonLoader("Make a Payment", transactionLoading)}
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div
                                                className={`tab-pane fade ${paymentType === "debitCard" ? "show active" : ""}`}
                                                id="pills-contact"
                                                role="tabpanel"
                                                aria-labelledby="pills-contact-tab"
                                            >
                                                <div className="sos-content payment-inner-toggleable">
                                                    <form>
                                                        <div className="form-group mb-3">
                                                            <label
                                                                htmlFor="exampleInputEmail1"
                                                                className="form-label payment-form-label"
                                                            >
                                                                Cardholder Name
                                                            </label>
                                                            <div className="row g-2">
                                                                <div className="col-md">
                                                                    <div className="payment-controlpanl-style">
                                                                        <input
                                                                            type="text"
                                                                            name="cardFirstName"
                                                                            className="form-control custom-input-border-css"
                                                                            id="exampleInputName3"
                                                                            aria-describedby="nameHelp"
                                                                            placeholder="First Name"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            required
                                                                        />

                                                                    </div>
                                                                </div>
                                                                <div className="col-md">
                                                                    <div>
                                                                        <input
                                                                            type="text"
                                                                            name="cardLastName"
                                                                            className="form-control custom-input-border-css"
                                                                            id="exampleInputName3"
                                                                            aria-describedby="nameHelp"
                                                                            placeholder="Last Name"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label
                                                                htmlFor="exampleInputPassword1"
                                                                className="form-label payment-form-label"
                                                            >
                                                                Debit Card Number
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control custom-input-border-css"
                                                                id="debitcardNumber"
                                                                placeholder="Enter Debit Card Number"
                                                            />
                                                        </div>
                                                        <div className="row g-2">
                                                            <div className="col-md">
                                                                <div className="payment-controlpanl-style">
                                                                    <div>
                                                                        <label className="payment-form-label">
                                                                            Expiry Date
                                                                        </label>
                                                                    </div>
                                                                    <CardExpiration
                                                                        onChange={(e) => handleInputChange(e)}
                                                                    ></CardExpiration>
                                                                    {/*<input*/}
                                                                    {/*    type="date"*/}
                                                                    {/*    className="form-control custom-input-border-css"*/}
                                                                    {/*    id="debitExpiryDate"*/}
                                                                    {/*    placeholder="mm/yy"*/}
                                                                    {/*/>*/}
                                                                </div>
                                                            </div>
                                                            <div className="col-md">
                                                                <div>
                                                                    <div>
                                                                        <label className="payment-form-label">
                                                                            Security Code
                                                                        </label>
                                                                    </div>
                                                                    <input
                                                                        type="tel"
                                                                        className="form-control custom-input-border-css"
                                                                        id="floatingInputGrid"
                                                                        placeholder="Enter CVC"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="checkbox-list mt-3">
                                                            <label
                                                                className="label-check font-weight-light float-end">
                                                                <CustomCheckbox
                                                                    name="isPaymentSaveDetails"
                                                                    checked={isPaymentSaveDetails}
                                                                    onChange={(e) => setIsPaymentSaveDetails(!isPaymentSaveDetails)}
                                                                />
                                                                Save these payment details for future payments
                                                            </label>
                                                            <div className="payment-checbox">
                                                                <label
                                                                    className="font-weight-light label-check ml-xl-5">
                                                                    <CustomCheckbox
                                                                        name="isDefault"
                                                                        checked={isDefault}
                                                                        onChange={(e) => setIsDefault(!isDefault)}
                                                                    />
                                                                    Set as default payment method
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-center">
                                                            <button
                                                                type="submit"
                                                                className="sos-mack-btn sos-custom-btn-css"
                                                                disabled={transactionLoading}
                                                            >
                                                                {withButtonLoader("Make a Payment", transactionLoading)}
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div
                                                className={`tab-pane fade ${paymentType === "swipeCard" ? "show active" : ""}`}
                                                id="pills-contact"
                                                role="tabpanel"
                                                aria-labelledby="pills-contact-tab"
                                            >
                                                <div className="sos-content payment-inner-toggleable">
                                                    <form onSubmit={(e) => handlePay(e)}>
                                                        <div className="form-group mb-3">
                                                            <label
                                                                htmlFor="exampleInputName4"
                                                                className="form-label payment-form-label"
                                                            >
                                                                Cardholder Name
                                                            </label>
                                                            <div className="row g-2">
                                                                <div className="col-md">
                                                                    <div className="payment-controlpanl-style">
                                                                        <input
                                                                            type="text"
                                                                            name="cardFirstName"
                                                                            className="form-control custom-input-border-css"
                                                                            id="exampleInputName3"
                                                                            aria-describedby="nameHelp"
                                                                            placeholder="First Name"
                                                                            // onChange={(e) => handleInputChange(e)}
                                                                            // required
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={firstName || ""}
                                                                        />

                                                                    </div>
                                                                </div>
                                                                <div className="col-md">
                                                                    <div>
                                                                        <input
                                                                            type="text"
                                                                            name="cardLastName"
                                                                            className="form-control custom-input-border-css"
                                                                            id="exampleInputName3"
                                                                            aria-describedby="nameHelp"
                                                                            placeholder="Last Name"
                                                                            // onChange={(e) => handleInputChange(e)}
                                                                            // required
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={lastName || ""}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-3">
                                                            <label
                                                                htmlFor="exampleInputPassword1"
                                                                className="form-label payment-form-label"
                                                            >
                                                                Card Number
                                                            </label>
                                                            <input
                                                                type="tel"
                                                                name="cardnumber"
                                                                className="form-control custom-input-border-css"
                                                                placeholder="Enter Credit Card Number"
                                                                // pattern="[\d| ]{15,22}"
                                                                required
                                                                // onChange={(e) => handleInputChange(e)}
                                                                onChange={(e) => handleInputChange(e)}
                                                                value={lastFourNumbers ? disguisedSwipeNumber : ""}
                                                            />
                                                        </div>
                                                        <div className="row g-2">
                                                            {/* <div className="col-md">
                                    <div className="payment-controlpanl-style">
                                      <div>
                                        <label className="payment-form-label">
                                          Expiry Date
                                        </label>
                                      </div>
                                      <input
                                        type="tel"
                                        className="form-control custom-input-border-css"
                                        id="expiry"
                                        placeholder="mm/yy"
                                        name="expiry"
                                        pattern="\d\d/\d\d"
                                        required
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                    </div>
                                  </div> */}
                                                            {/* <div className="col-md">
                                    <div >
                                      <div>
                                        <label className="payment-form-label">
                                          Security Code
                                        </label>
                                      </div>
                                      <input
                                        type="tel"
                                        className="form-control custom-input-border-css"
                                        placeholder="Enter CVC"
                                        name="cvc"
                                        pattern="\d{3,4}"
                                        required
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                    </div>
                                  </div> */}
                                                        </div>
                                                        <div className="checkbox-list mt-3">
                                                            <label
                                                                className="label-check font-weight-light float-end">
                                                                <CustomCheckbox
                                                                    name="isPaymentSaveDetails"
                                                                    checked={isPaymentSaveDetails}
                                                                    onChange={(e) => setIsPaymentSaveDetails(!isPaymentSaveDetails)}
                                                                />
                                                                Save these payment details for future payments
                                                            </label>
                                                            <div className="payment-checbox">
                                                                <label
                                                                    className="font-weight-light label-check ml-xl-5">
                                                                    <CustomCheckbox
                                                                        name="isDefault"
                                                                        checked={isDefault}
                                                                        onChange={(e) => setIsDefault(!isDefault)}
                                                                    />
                                                                    Set as default payment method
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-center">
                                                            <button
                                                                type="submit"
                                                                className="sos-mack-btn sos-custom-btn-css"
                                                                disabled={transactionLoading}
                                                            >
                                                                {withButtonLoader("Make a Payment", transactionLoading)}
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>) : (<>
                                {(<>
                                    <div className="pay-saved-method">
                                        <div className="pay-patient-style">
                                            {payerUser?.paymentMethods?.length > 0 && `Saved Payment Methods`}
                                        </div>
                                        <div className="pay-toggleable d-flex row">

                                            {userAdmin.displayAddPaymentMethod && (<ul
                                                className="col-lg-5 col-md-4 col-xl-4 mb-3 nav nav-pills justify-content-center"
                                                // onClick={() => handleAddCardInfo()}
                                            >
                                                <li
                                                    className="nav-item custom-tab border-1 ml-8 custom-tab "

                                                    role="presentation"
                                                >
                                                    <a
                                                        className="nav-link text-center "
                                                        id="pills-home-tab"
                                                        data-bs-toggle="pill"
                                                        data-bs-target="#pills-home"
                                                        role="tab"
                                                        aria-controls="pills-home"
                                                        aria-selected="true"
                                                    >
                                                        <div
                                                            className="check-btn mb-0 add-card-text">

                                                            <AddCardIcon
                                                                color="#fff"
                                                                fill="#6e86c4"
                                                                style={{
                                                                    marginRight: "6px", marginBottom: "2px"
                                                                }}
                                                            />
                                                            Add Payment Method
                                                        </div>
                                                        <button
                                                            type="button"
                                                            onClick={(e) => handleSwipeAddCardInfo(e)}
                                                            className="add-card-btn"
                                                        >
                                                            Swipe Credit/Debit
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={(e) => handleAddCardInfo(e)}
                                                            className="add-card-btn"
                                                        >
                                                            Credit/Debit Card
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={(e) => handleECheck(e)}
                                                            className="add-card-btn"
                                                        >
                                                            eCheck
                                                        </button>
                                                    </a>
                                                </li>
                                            </ul>)}
                                            {payerUser?.paymentMethods?.length > 0 &&

                                                map(payerUser.paymentMethods, (c, i) => {
                                                    let paymentMethodDisplay;
                                                    if (c.ssl_card_short_description) {
                                                        paymentMethodDisplay = `${c.ssl_card_short_description} ${c.ssl_card_number.slice(-4)}`;
                                                    } else {
                                                        paymentMethodDisplay = `eCheck ${c.ssl_bank_account_number.slice(-4)}`;
                                                    }

                                                    return (<ul
                                                        key={i}
                                                        className="col-lg-5 col-md-4 col-xl-4 mb-3 nav nav-pills justify-content-center"
                                                        onClick={() => {
                                                            onClickPaymentMethod(c);
                                                        }}
                                                    >
                                                        <li
                                                            className={("nav-item custom-tab border-1 ml-8", selectedCardId === c._id ? " nav-item active select-card" : " nav-item custom-tab")}
                                                            role="presentation"
                                                        >
                                                            <a
                                                                className="nav-link text-center "
                                                                id="pills-home-tab"
                                                                data-bs-toggle="pill"
                                                                data-bs-target="#pills-home"
                                                                role="tab"
                                                                aria-controls="pills-home"
                                                                aria-selected="true"
                                                            >
                                                                <div
                                                                    className="check-btn d-flex mb-0">
                                                                    <CreditCardIcon/>
                                                                    <span
                                                                        className="text-dark payment-method-text">
                                                                    {paymentMethodDisplay}
                                              </span>
                                                                </div>
                                                                <div
                                                                    className="mb-0 ml-8 text-start text-dark mt-1">
                                                                    <label
                                                                        className="font-weight-light label-check sos-checkbox">
                                                                        <CustomCheckbox
                                                                            checked={c.isDefault}
                                                                            onChange={(e) => onDefaultChecked(e, c, i)}
                                                                        />

                                                                        <span
                                                                            className="payment-style payment-method-text">
                                                  Default Method
                                                </span>
                                                                    </label>
                                                                </div>
                                                                <div
                                                                    className="check-btn d-flex auto-payment text-dark">

                                                                </div>
                                                                <div className="divider"/>
                                                                <div
                                                                    className="d-flex cursor-pointer">
                                              <span
                                                  className="text-cerulean text-decoration-underline ml-8"
                                                  onClick={() => handleEditInfo()}
                                              >
                                                Edit
                                              </span>
                                                                    <span
                                                                        className="text-cerulean text-decoration-underline custom-popup-modal"
                                                                        onClick={(e) => handlePopUp(e, c)}
                                                                    >
                                                Remove
                                              </span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    </ul>);
                                                })}
                                        </div>
                                    </div>
                                    {payerUser?.paymentMethods?.length > 0 &&
                                        <div className="d-flex justify-content-center">
                                            <button
                                                type="button"
                                                onClick={(e) => handleSubmit(e)}
                                                className="sos-mack-btn sos-custom-btn-css"
                                                disabled={transactionLoading}
                                            >
                                                {withButtonLoader("Process Payment", transactionLoading)}
                                            </button>
                                        </div>}
                                    {(adminUser.displayAddPaymentMethod || payerUser?.paymentMethods?.length > 0) && <div className="pay-saved-inner-block">
                                        <div className="or-line"></div>
                                        <div className="payment-raound">
                                            <span>OR</span>
                                        </div>
                                    </div>}
                                </>)
                                    // : (
                                    //   ""
                                    // )
                                }
                                <div className="select-pay-method">
                                    <div className="pay-patient-style container">
                                        Pay With a New Payment Method
                                    </div>
                                    <div className=" pay-toggleable row">
                                        <ul
                                            className="mb-3 nav nav-pills p-0"
                                            id="pills-tab"
                                            role="tablist"
                                        >
                                            {adminUser.displayECheck && (<li
                                                className="me-5 nav-item chekbox-custom rectangle nav-item-margin"
                                                role="presentation"
                                                onClick={() => handleAmountBeingPaid("eCheck")}
                                            >
                                                <a
                                                    className={`nav-link text-center ${paymentType === "eCheck" ? "active" : ""}`}
                                                    id="pills-home-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-home"
                                                    role="tab"
                                                    aria-controls="pills-home"
                                                    aria-selected="true"
                                                >
                                                    <div className="check-btn">
                                                        <ECheckIcon/>
                                                    </div>
                                                    <div className="payment-checkbox-inner">eCheck</div>
                                                </a>
                                            </li>)}
                                            <li
                                                className="me-5 nav-item chekbox-custom  rectangle nav-item-margin"
                                                role="presentation"
                                                onClick={() => handleAmountBeingPaid("creditCard")}
                                            >
                                                <a
                                                    className={`nav-link text-center ${paymentType === "creditCard" ? "active" : ""}`}
                                                    id="pills-profile-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-profile"
                                                    role="tab"
                                                    aria-controls="pills-profile"
                                                    aria-selected="false"
                                                >
                                                    <div className="check-btn">
                                                        <CreditCardIcon/>
                                                    </div>
                                                    <div className="payment-checkbox-inner">
                                                        Credit/Debit Card
                                                    </div>
                                                </a>
                                            </li>
                                            <li
                                                className="nav-item chekbox-custom rectangle nav-item-margin"
                                                role="presentation"
                                                onClick={() => handleAmountBeingPaid("swipeCard")}
                                            >
                                                <a
                                                    className={`nav-link text-center ${paymentType === "swipeCard" ? "active" : ""}`}
                                                    id="pills-contact-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-contact"
                                                    role="tab"
                                                    aria-controls="pills-contact"
                                                    aria-selected="false"
                                                >
                                                    <div className="check-btn">
                                                        {/* <HhaCardIcon /> */}
                                                        <div className="swipe-credit-card-icon">

                                                            <SwipeCardIcon
                                                                style={{width: "31px", height: "21px"}}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="payment-checkbox-inner">
                                                        {/* HHA/HHS card */}
                                                        Swipe Credit/Debit
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </>)}
                        </div>
                        <div className="hommeexisting-img">
                            <img src="/images/payment-icon.png" alt="homeExisting"/>
                        </div>
                    </div>}
                    {addEditSwipeMode === "edit" && <AdminEditCardInfo
                        payerUser={payerUser}
                        userAdmin={userAdmin}
                        setPayerUser={setPayerUser}
                        subdomain={subdomain}
                        paymentMethodData={selectedPayment}
                        setAddEditSwipeMode={setAddEditSwipeMode}
                    />}
                    {addEditSwipeMode === "add" && <AdminAddCardInfo
                        userAdmin={userAdmin}
                        payerUser={payerUser}
                        setPayerUser={setPayerUser}
                        subdomain={subdomain}
                        paymentMethodData={selectedPayment}
                        setAddEditSwipeMode={setAddEditSwipeMode}
                        handleSwipeAddCardInfo={handleSwipeAddCardInfo}
                    />}
                    {addEditSwipeMode === "swipe" && <AdminPaymentAddSwipe
                        userAdmin={userAdmin}
                        payerUser={payerUser}
                        setPayerUser={setPayerUser}
                        subdomain={subdomain}
                        paymentMethodData={selectedPayment}
                        setAddEditSwipeMode={setAddEditSwipeMode}
                        handleAddCardInfo={handleAddCardInfo}
                    />}
                </div>
            </div>
        </div>

        <PaymentSuccessPopup
            openModal={openModal}
            paymentMsg={paymentMsg}
            onCloseModal={onCloseModal}
        />
        <CustomPopover
            flag={true}
            title={"Are you sure you want to remove this payment method?"}
            show={showPopUp}
            target={target}
            onRemove={onRemoveDoc}
            close={handleClosePopUp}
            setShowPopUp={setShowPopUp}
        />
        <CustomePopUp
            openModal={amountPaidPopup}
            onCloseModal={handleClosePopUp}
            message={"Please enter an amount."}
        />
    </>);
};

const mapDispatchToProps = {
    getPayerUserForAdminLookup, savePaymentsDataAdmin, deleteCard,
};

export default connect(null, mapDispatchToProps)(AdminPayerUser);
