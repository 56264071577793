import config from "../../config";
import React from "react";
import HelloSign from "hellosign-embedded";

const client = new HelloSign({
    clientId: config.HELLOSIGN_CLIENT_ID,
    allowCancel: true,
    skipDomainVerification: window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('.dev') > -1,
    testMode: window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('.dev') > -1,
    debug: window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('.dev') > -1,
    // container: document.getElementById("sign-here"),
});

const HelloSignAdmin = ({
                            editHelloSignUrl,
                            onCreateHelloSignTemplate,
                            userAdmin,
                            formIndex,
                            fileToEdit,
                            updateAdminUser,
                            showHelloSign,
                            setShowHelloSign,
                            setIsOpenEditIcon,
                            setPopUpMessage,
                            // setHelloSignLoader,
                            setIntakePopUp,
                        }) => {
    const subdomain = userAdmin.subdomain;
    const sosCustomerId = userAdmin.id;

    localStorage.debug = "hellosign-embedded:*";

    client.open(editHelloSignUrl || "");

    client.on("open", (data) => {
    });

    client.on('error', (data) => {
        console.log(data);
        console.log(`Something went wrong! Please try again. Error code: ${data.code}`);
    });

    client.on("ready",
        (data) => {
        });

    client.on('error', (data) => {
        console.log(data);
        console.log(`Something went wrong! Please try again. Error code: ${data.code}`);
    });


    client.once("createTemplate", (data) => {
        const {templateId: template_id} = data;
        const intakeformId = fileToEdit._id.toString();
        onCreateHelloSignTemplate(
            subdomain,
            sosCustomerId,
            intakeformId,
            template_id
        );
        setShowHelloSign(!showHelloSign);
    });


    client.on("close", () => {
        // The signature request window was closed
        setShowHelloSign(false);
        // setHelloSignLoader(false);
    });

    client.once("cancel", () => {
        // updateAdminUser();
        setIsOpenEditIcon(false);
        setShowHelloSign(!showHelloSign);
        // setHelloSignLoader(false);
        client.close();
    });
    client.on("finish", () => {
        // The signature request was finished
        setShowHelloSign(!showHelloSign);
        setIntakePopUp(true);
        setPopUpMessage("Intake form updated successfully");
        setIsOpenEditIcon(false);
    });
    return <></>
};
export default HelloSignAdmin;
